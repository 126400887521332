// Implemntiert Tracking wie hier definiert: https://wiki.hotelplan.com/display/TSICHDDWEBCC/01+-+Enhanced+Ecommerce
type Type = 'A' | 'B' | 'G' | 'H'
type DetailType = 'A' | 'B' | 'C' | 'D' | 'F' | 'H' | 'R' | 'S' | 'V' | 'Y'

export default {
  productImpression,
  productClick,
  productDetailImpression,
  addToCart,
  removeFromCart,
}

export function getDiscount(basePrice: number, reduction: number) {
  if (!basePrice || !reduction) {
    return undefined
  }
  const value = Math.floor((reduction / basePrice) * 100)
  return `${value}%`
}

function createCartObject(accom: ResultDocument) {
  if (!accom.trip?.price || !accom.trip.reduction) {
    return
  }
  const effectivePrice = accom.trip.price
  const basePrice = accom.trip.reduction ? effectivePrice + accom.trip.reduction : effectivePrice
  return {
    name: accom.name,
    id: accom.code,
    price: (effectivePrice / accom.trip.duration).toFixed(2),
    brand: brand(accom.type as Type),
    // TODO: Benötigt englische GEO-Daten
    // category,
    pax: accom.pax,
    quality: accom.stars,
    // TODO: Benötigt Attributsinfos
    // pool,
    discountType: accom.trip.special ? { LM: 'last minute', EB: 'early booker' }[accom.trip.special.substring(0, 2)] || 'other' : undefined,
    discountAmount: getDiscount(basePrice, accom.trip.reduction),
    regularPrice: (basePrice / accom.trip.duration).toFixed(2),
    quantity: accom.trip.duration,
    start_date: accom.trip.checkin,
    end_date: accom.trip.checkout,
  }
}

function addToCart(accom: ResultDocument) {
  const pushElem = {
    event: 'eec.addToCart',
    ecommerce: {
      currencyCode: useConfdata().currency,
      add: createCartObject(accom),
    },
  }
  useTracking().send(pushElem)
}

function removeFromCart(accom: ResultDocument) {
  const pushElem = {
    event: 'eec.removeFromCart',
    ecommerce: {
      currencyCode: useConfdata().currency,
      remove: createCartObject(accom),
    },
  }
  useTracking().send(pushElem)
}

function createImpressions(accoms: ResultDocument[], pagetype: string) {
  const result: {
    name: string
    id: string
    price: string
    brand: string
    list: string | undefined
    position: number
    pax: number
    quality: number
    discountType: string | undefined
    discountAmount: string | undefined
    regularPrice: string
    start_date: string
    end_date: string | undefined
  }[] = []
  accoms.forEach((accom) => {
    if (!accom.trip?.price) {
      return
    }
    const effectivePrice = accom.trip.price
    const basePrice = accom.trip.reduction ? effectivePrice + accom.trip.reduction : effectivePrice
    result.push({
      name: accom.name,
      id: accom.code,
      price: (effectivePrice / accom.trip.duration).toFixed(2),
      brand: brand(accom.type as Type),
      // TODO: Benötigt englische GEO-Daten
      // category,
      list: { search: 'Searchresults', bookmarks: 'Watchlist' }[pagetype as 'search' | 'bookmarks'],
      position: accom.position || NaN,
      pax: accom.pax,
      quality: accom.stars,
      // TODO: Benötigt Attributsinfos
      // pool,
      discountType: accom.trip.special ? { LM: 'last minute', EB: 'early booker' }[accom.trip.special.substring(0, 2)] || 'other' : undefined,
      discountAmount: accom.trip.reduction ? getDiscount(basePrice, accom.trip.reduction) : undefined,
      regularPrice: (basePrice / accom.trip.duration).toFixed(2),
      start_date: accom.trip.checkin,
      end_date: accom.trip.checkout,
    })
  })
  return result
}

function productImpression(accoms: ResultDocument[]) {
  const pushElem = {
    event: 'eec.productImpression',
    ecommerce: {
      currencyCode: useConfdata().currency,
      impressions: createImpressions(accoms, useConfdata().pagetype!),
    },
  }
  useTracking().send(pushElem)
}

function productDetailImpression(
  price: { price?: {}; special?: string; regularPrice?: number; reduction?: number; checkin?: Date | string; checkout?: Date | string } = {},
) {
  const seoify = (input: string) => input.toLowerCase().replace('/', '-').replace(' ', '-')
  const accommodation = useConfdata().baseData
  if (!accommodation) {
    return
  }

  const type = typeMapping[accommodation.type.code as Type]
  const detailType = detailTypeMapping[accommodation.type.detail as DetailType]
  const country = seoify(accommodation.country.label)
  const region = seoify(accommodation.region.label)
  const place = seoify(accommodation.place.label)
  const category = `${country}/${region}/${place}/${type}/${detailType}`

  useTracking().send({
    event: 'eec.productDetailImpression',
    ecommerce: {
      currencyCode: useConfdata().currency,
      detail: {
        products: [
          {
            name: accommodation.name,
            id: accommodation.code,
            price: price.price,
            brand: `${type}/${detailType}`,
            category,
            pax: accommodation.pax.capacity,
            quality: accommodation.evaluation.stars,
            pool: !!accommodation.attributes.pool,
            discountType: price.special ? { LM: 'last minute', EB: 'early booker' }[price.special.substring(0, 2)] || 'other' : undefined,
            discountAmount: price.regularPrice && price.reduction ? getDiscount(price.regularPrice, price.reduction) : undefined,
            regularPrice: price.regularPrice || undefined,
            start_date: price.checkin && toServerDate(price.checkin),
            end_date: price.checkout && toServerDate(price.checkout),
          },
        ],
      },
    },
  })
}

const detailTypeMapping: Record<DetailType, string> = {
  A: 'apart-hotel',
  B: 'bungalow',
  C: 'chalet',
  D: 'divers',
  F: 'farmhouse',
  H: 'holiday-village',
  R: 'residence',
  S: 'castle-mansion',
  V: 'villa',
  Y: 'yacht',
}

const typeMapping: Record<Type, string> = {
  A: 'apartment',
  B: 'boat',
  G: 'guestroom',
  H: 'holiday-house',
}

function brand(type: Type, detailType?: DetailType) {
  if (detailType) {
    return `${typeMapping[type]}/${detailTypeMapping[detailType]}`
  }
  return `${typeMapping[type]}`
}

function createProduct(accom: ResultDocument, pagetype: string) {
  if (!accom.trip?.price || !accom.trip.reduction) {
    return
  }
  const effectivePrice = accom.trip.price
  const basePrice = accom.trip.reduction ? effectivePrice + accom.trip.reduction : effectivePrice
  return {
    actionField: {
      list: { search: 'Searchresults', bookmarks: 'Watchlist' }[pagetype],
    },
    name: accom.name,
    id: accom.code,
    price: (effectivePrice / accom.trip.duration).toFixed(2),
    brand: brand(accom.type as Type),
    // TODO: Benötigt englische GEO-Daten
    // category,
    position: accom.position,
    pax: accom.pax,
    quality: accom.stars,
    // TODO: Benötigt Attributsinfos
    // pool,
    discountType: accom.trip.special ? { LM: 'last minute', EB: 'early booker' }[accom.trip.special.substring(0, 2)] || 'other' : undefined,
    discountAmount: getDiscount(basePrice, accom.trip.reduction),
    regularPrice: (basePrice / accom.trip.duration).toFixed(2),
    start_date: accom.trip.checkin,
    end_date: accom.trip.checkout,
  }
}

function productClick(accom: ResultDocument) {
  const pushElem = {
    event: 'eec.productClick',
    ecommerce: {
      currencyCode: useConfdata().currency,
      click: createProduct(accom, useConfdata().pagetype!),
    },
  }
  useTracking().send(pushElem)
}
