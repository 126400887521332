export function hex2hsl(hex: string) {
  // Convert hex to RGB first
  let r = 0
  let g = 0
  let b = 0
  if (hex.length === 3) {
    r = parseInt(`0x${hex[0]}${hex[0]}`)
    g = parseInt(`0x${hex[1]}${hex[1]}`)
    b = parseInt(`0x${hex[2]}${hex[2]}`)
  } else if (hex.length === 6) {
    r = parseInt(`0x${hex[0]}${hex[1]}`)
    g = parseInt(`0x${hex[2]}${hex[3]}`)
    b = parseInt(`0x${hex[4]}${hex[5]}`)
  }
  // Then to HSL
  r /= 255
  g /= 255
  b /= 255
  const cmin = Math.min(r, g, b)
  const cmax = Math.max(r, g, b)
  const delta = cmax - cmin
  let h = 0
  let s = 0
  let l = 0

  if (delta === 0) {
    h = 0
  } else if (cmax === r) {
    h = ((g - b) / delta) % 6
  } else if (cmax === g) {
    h = (b - r) / delta + 2
  } else {
    h = (r - g) / delta + 4
  }

  h = Math.round(h * 60)

  if (h < 0) {
    h += 360
  }

  l = (cmax + cmin) / 2
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
  s = +(s * 100).toFixed(1)
  l = +(l * 100).toFixed(1)

  return { h, s, l }
}

export function hsl2hex(h: number, s: number, l: number) {
  l = l < 50 ? Math.max(0, l) : Math.min(100, l)
  l /= 100
  const a = (s * Math.min(l, 1 - l)) / 100
  const f = (n: number) => {
    const k = (n + h / 30) % 12
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0') // convert to Hex and prefix "0" if needed
  }
  return `#${f(0)}${f(8)}${f(4)}`
}
