/*
 * 13-content-assets
 *
 * Spezifikation: https://jira.migros.net/browse/IHGWEBCC-1158, https://jira.migros.net/browse/IHGWEBCC-1159
 */

export default {
  secretSpotsDisplayed,
  secretSpotsReadMore,
  threeDaySummaryDisplayed,
  threeDaySummaryReadMore,
  touristicActivitiesDisplayed,
  touristicActivitiesReadMore,
}

function secretSpotsDisplayed(): void {
  useTracking().send({
    event: 'content_assets',
    action: 'displayed',
    label: 'Secret Spots',
    category: undefined,
    component: 'seo-content-assets',
    position: undefined,
    language: useL10N().language,
    _clear: true,
  })
}

function secretSpotsReadMore(): void {
  useTracking().send({
    event: 'content_assets',
    action: 'read more',
    label: 'Secret Spots',
    category: undefined,
    component: 'seo-content-assets',
    position: undefined,
    language: useL10N().language,
    _clear: true,
  })
}

function threeDaySummaryDisplayed(): void {
  useTracking().send({
    event: 'content_assets',
    action: 'displayed',
    label: '3 Day Summary',
    category: undefined,
    component: 'seo-content-assets',
    position: undefined,
    language: useL10N().language,
    _clear: true,
  })
}

function threeDaySummaryReadMore(): void {
  useTracking().send({
    event: 'content_assets',
    action: 'read more',
    label: '3 Day Summary',
    category: undefined,
    component: 'seo-content-assets',
    position: undefined,
    language: useL10N().language,
    _clear: true,
  })
}

function touristicActivitiesDisplayed(): void {
  useTracking().send({
    event: 'content_assets',
    action: 'displayed',
    label: 'Touristic Activities',
    category: undefined,
    component: 'seo-content-assets',
    position: undefined,
    language: useL10N().language,
    _clear: true,
  })
}

function touristicActivitiesReadMore(): void {
  useTracking().send({
    event: 'content_assets',
    action: 'read more',
    label: 'Touristic Activities',
    category: undefined,
    component: 'seo-content-assets',
    position: undefined,
    language: useL10N().language,
    _clear: true,
  })
}
