<template>
  <section v-if="id && url" class="mt-8 flex flex-col overflow-hidden rounded shadow">
    <h5 class="bg-thm px-4 py-2 text-lg font-medium text-thm-contrast">{{ $t(`${trPrefix}userRating`) }}</h5>

    <main class="flex max-w-xl items-center justify-center space-x-2 self-center py-8">
      <WebccLink anchor target="_blank" :to="url">
        <img :src="`https://emailsignature.trustpilot.com/newsletter/${resource.locale}/1/${id}/text1@2x.png`" alt="Human score" />
      </WebccLink>

      <WebccLink anchor target="_blank" :to="url">
        <img :src="`https://emailsignature.trustpilot.com/newsletter/${resource.locale}/1/${id}/stars@2x.png`" alt="Trustpilot Stars" />
      </WebccLink>

      <WebccLink anchor target="_blank" :to="url">
        <img :src="`https://emailsignature.trustpilot.com/newsletter/${resource.locale}/1/${id}/text2@2x.png`" alt="number of reviews" />
      </WebccLink>

      <WebccLink anchor target="_blank" :to="url">
        <img src="https://emailsignature.trustpilot.com/brand/n/1/logo.png" alt="Trustpilot Logo" class="pb-1" />
      </WebccLink>
    </main>
  </section>
</template>

<script setup lang="ts">
const linkMap: { [key: string]: { [key: string]: string } } = {
  'www.interchalet.de': { default: 'https://de.trustpilot.com/review/www.interchalet.de' },
  'www.interhome.ch': {
    default: 'https://ch.trustpilot.com/review/www.interhome.ch',
    fr: 'https://fr.trustpilot.com/review/www.interhome.ch',
    it: 'https://it.trustpilot.com/review/www.interhome.ch',
    en: 'https://www.trustpilot.com/review/www.interhome.ch',
  },
  'www.interhome.at': { default: 'https://at.trustpilot.com/review/www.interhome.at' },
  'www.interhome.de': { default: 'https://de.trustpilot.com/review/www.interhome.de' },
  'www.interhome.fr': { default: 'https://fr.trustpilot.com/review/www.interhome.fr' },
  'www.interhome.it': { default: 'https://it.trustpilot.com/review/www.interhome.it' },
  'www.interhome.pl': { default: 'https://pl.trustpilot.com/review/www.interhome.pl' },
  'www.interhome.nl': { default: 'https://nl.trustpilot.com/review/www.interhome.nl' },
  'www.interhome.be': {
    default: 'https://nl-be.trustpilot.com/review/www.interhome.be',
    fr: 'https://fr-be.trustpilot.com/review/www.interhome.be',
  },
  'www.interhome.cz': { default: 'https://cz.trustpilot.com/review/www.interhome.cz' },
  'www.interhome.es': { default: 'https://es.trustpilot.com/review/www.interhome.es' },
}
// TODO: Move to ConfService
const tpIDs: { [key: string]: string } = {
  'www.interchalet.de': '4bf2ac4100006400050ce159',
  'www.interhome.de': '530720200000640005784347',
  'www.interhome.ch': '4bdd5f610000640005061b46',
  'www.interhome.it': '530720540000640005784349',
  'www.interhome.cz': '530721880000640005784350',
  'www.interhome.be': '53072083000064000578434c',
  'www.interhome.nl': '53071f370000640005784343',
  'www.interhome.at': '505f75f500006400051be1ad',
  'www.interhome.fr': '4be696b6000064000509791d',
  'www.interhome.pl': '5112366c000064000521f2b3',
  'www.interhome.es': '530720fd000064000578434e',
}

const trPrefix = 'www.components.views.details.TrustPilot.'

const props = defineProps<{ domain: string; resource: Site }>()

const url = computed(() => {
  const urls = linkMap[props.domain] || {}
  if (Object.keys(urls).includes(props.resource.language)) {
    return urls[props.resource.language]
  }
  return urls.default
})

const id = computed(() => {
  return tpIDs[props.domain]
})
</script>
