<template>
  <section class="w-full rounded bg-bgr px-1 py-1.5 shadow">
    <FilterCheckbox v-for="f in filters" :key="f" :filter="f" :count="counts[f]" />
  </section>
</template>

<script setup lang="ts">
const props = defineProps<{
  filter: string[]
  counts: { [key: string]: number }
}>()

const filters = computed(() => {
  if (props.counts) {
    return props.filter.filter((f) => !!props.counts[f])
  }

  return props.filter
})
</script>
