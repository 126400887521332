/*
 * Spezifikation: https://wiki.hotelplan.com/display/TSICHDDWEBCC/07+-+Header
 */

export default {
  logoClick,
  sitenavigationClick,
  languageSwitch,
  currencySwitch,
  uspClosed,
  phoneClick,
  historyOpened,
  historyItemClick,
  headerItemClick,
  enterGuestWorld,
}

function enterGuestWorld() {
  useTracking().send({
    event: 'enter_guestworld',
    action: 'navigate',
    label: 'Sign in',
    category: undefined,
    component: 'header-link',
    position: 'header',
    language: useL10N().language.toLowerCase(),
    _clear: true,
  })
}

/* helper to get pagetype from virtPath */
function pageType(page: string) {
  return (
    {
      start: 'homesite',
    }[page] || page
  )
}

/* 1: Logo */
function logoClick() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Header',
    eventAction: 'Logo',
    eventLabel: pageType(useConfdata().pagetype || ''),
    eventNonInteraction: false,
  })
}

/* 2: Owner, 3: Partner, 5: lso-pages, 8: newsletter */
function sitenavigationClick(id: 'partner' | 'lso' | 'topcountries' | 'inspirations' | 'homeowner') {
  const action = {
    partner: ['Partner', `${useConfdata().domain.split('.').pop()}+${useL10N().language.toLowerCase()}`],
    lso: ['lso-pages', 'not set'],
    topcountries: ['Request popular destinations', 'MainNavigation'],
    inspirations: ['Request travel ideas', 'MainNavigation'],
    homeowner: ['Request homeowner', 'MainNavigation'],
  }[id] || ['newsletter', 'not set']

  useTracking().send({
    event: 'event',
    eventCategory: 'SiteNavigation',
    eventAction: action[0],
    eventLabel: action[1],
    eventNonInteraction: false,
  })
}

// 4: language
function languageSwitch(lang: string) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Header',
    eventAction: 'Language',
    eventLabel: lang.toLowerCase(),
    eventNonInteraction: false,
  })
}

/* 6: Faq  - not required */

/* 7: currency */
function currencySwitch(currencyCode: string) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Header',
    eventAction: 'Currency',
    eventLabel: currencyCode,
    eventNonInteraction: false,
  })
}

/* 9 usp - gibt es nicht mehr */
function uspClosed({ uspIndex, millisecondsDisplayed }: { uspIndex: string; millisecondsDisplayed: number }) {
  const dispayTimeRoundedToHalfSeconds = Math.round((millisecondsDisplayed / 1000) * 2) / 2
  const pagetype = pageType(useConfdata().pagetype || '')

  useTracking().send({
    event: 'event',
    eventCategory: 'Header',
    eventAction: 'USP Layer',
    eventLabel: `${uspIndex} + ${dispayTimeRoundedToHalfSeconds} + ${pagetype}`,
    eventNonInteraction: true,
  })
}

/* 10 : contact phone number */
function phoneClick() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Header',
    eventAction: 'Outbound',
    eventLabel: `Phone Header + ${pageType(useConfdata().pagetype || '')}`,
    eventNonInteraction: false,
  })
}

/* 11 : last visited menu opened */
function historyOpened() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Header',
    eventAction: 'Recent Object',
    eventLabel: `opened + ${pageType(useConfdata().pagetype || '')}`,
    eventNonInteraction: false,
  })
}

/* 12: click to accommodation in last visited menu */
function historyItemClick(accommodationID: string) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Header',
    eventAction: 'Recent Object',
    eventLabel: `clicked + ${accommodationID}`,
    eventNonInteraction: false,
  })
}

/* 13: Header elements homepage link, favourites, contact us */

function headerItemClick(action: 'homepage' | 'favourites' | 'contactus') {
  const items = {
    homepage: {
      eventAction: 'Request homepage',
      eventLabel: 'Back to homepage',
    },
    favourites: {
      eventAction: 'Request favourites',
      eventLabel: 'My favourites',
    },
    contactus: {
      eventAction: 'Request contact us',
      eventLabel: 'Contact us',
    },
  }
  useTracking().send({
    event: 'event',
    eventCategory: 'Header',
    eventAction: items[action].eventAction,
    eventLabel: items[action].eventLabel,
    eventNonInteraction: false,
  })
}
