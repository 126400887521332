<template>
  <main>
    <div class="container-fluid-lg flex flex-col gap-x-4">
      <div>
        <template v-if="!iframe">
          <ReviewTeaserTop :count="1" :destination="additionalBody.destination" :search-url="additionalBody.searchurl" />
          <section class="mb-10 bg-thm px-6">
            <SearchBox />
          </section>
        </template>
        <div v-else class="flex flex-row items-center justify-between py-4">
          <SearchBreadcrumb :geo="geo" />
          <BookmarkLink />
        </div>
      </div>
      <div class="flex flex-col w-full md:flex-row gap-x-4">
        <section class="w-full md:w-3/5 grow shrink relative">
          <WebccLoaderOverlay v-if="loading" :opacity="10" absolute />
          <div ref="scrollpoint">
            <ReviewSummary
              v-if="rating"
              :rating="rating.overall"
              :condition="rating.property"
              :cleanliness="rating.cleanliness"
              :service="rating.service"
              :review-count="reviewCount"
            />
            <!-- TODO: Aktuell nicht verwendbar, da clientseitig immer nur eine Seite an reviews bekannst ist -->
            <!-- <ReviewFilter :filter-travel-with="filterTravelWith" :reviews="reviews" @update="onFilterChange" /> -->
            <ReviewsList :reviewpage-prefix="prefixReviewspage || ''" :locale="locale || ''" :company="company" :reviews="reviews" />
          </div>
          <!-- Key is needed due to nuxt having issues with properly reinitializing identical components on subsequent pages  -->
          <WebccPagination :key="useRequestURL().pathname" class="mb-16" :active="page" :total="totalPages" :visible="7" @select-page="loadReviewPage" />
        </section>
        <aside class="w-full md:w-80 lg:w-96 shrink-0 justify-self-center">
          <div class="relative border-2 border-solid p-6 md:mt-4 mb-4">
            <div class="absolute left-1/2 top-0 w-3/4 rounded bg-thm py-1 [transform:translate(-50%,-50%)]">
              <p class="text-center text-txt-contrast">{{ $t(`${trPrefix}userReviews`) }}</p>
            </div>
            <h2 class="mb-2 text-2xl font-semibold">{{ $t(`${trPrefix}guaranteedGenuine`) }}</h2>
            <p>{{ $t(`${trPrefix}guaranteedGenuineText`, { company: getCompanyName(company) }) }}</p>
          </div>
        </aside>
      </div>
    </div>
    <BlocksUSPCorona v-if="additionalBody.USPBar" boxed :icons="additionalBody.USPBar" />
    <USPCards :why="additionalBody.USPCards.cards" />
    <USPWhy :why="[additionalBody.USPColumns.left, additionalBody.USPColumns.right]" />
    <FooterNewsletterSignup />
  </main>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.PageReviews.'

const params = useParams()
const confData = useConfdata()
const { reviews, loading, rating, reviewCount, totalPages } = toRefs(useReviews())

loading.value = true
const { company } = confData
const { prefixReviewspage } = confData
const { locale } = confData
const page = ref(parseInt(params.all.page as string) || 1)
const additionalBody = computed(() => confData.additionalBody as ReviewspageBody)
const { iframe } = params
const scrollpoint = ref<HTMLElement | null>(null)

const geo = computed((): { country?: TrDest; region?: TrDest; place?: TrDest } => {
  const { destination } = confData
  const reviewDestination = reviews.value[0]?.dest
  if (!destination || !reviewDestination) {
    return {}
  }
  const slug = reviewDestination.slug.split('/')
  const countrySlug = `/${slug.slice(0, 1)}`
  const regionSlug = slug.slice(1, 2).toString()
  const placeSlug = slug.slice(2, 3).toString()
  return {
    country: destination.country
      ? {
          slug: countrySlug,
          name: destination.country,
        }
      : undefined,
    region: destination.region
      ? {
          slug: regionSlug,
          name: destination.region,
        }
      : undefined,
    place: destination.place
      ? {
          slug: placeSlug,
          name: destination.place,
        }
      : undefined,
  }
})

async function loadReviewPage(inpage: number) {
  const { countryCode, regionCode, placeCode } = confData.destination

  await useReviews().loadReviews(inpage, { country: countryCode, region: regionCode, place: placeCode })

  navigateTo({ path: useRequestURL().pathname, query: { ...params.all, page: inpage } })

  page.value = inpage
  scrollpoint?.value?.scrollIntoView({ behavior: 'smooth' })
}

onMounted(async () => {
  await loadReviewPage(page.value)
})
</script>
