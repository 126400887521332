export function getDestinationName({ place, region, country }: Destination): string {
  return place || region || country || ''
}

export function getDestinationCode({ placeCode, regionCode, countryCode }: Destination): string {
  return (countryCode || '') + (placeCode || regionCode || '')
}

export function geoByCode(code?: string) {
  return (item: { code: string }) => item.code === code
}

export function getLegacyGeoCode(code: string): string {
  return code.replace(/^[a-zA-Z]{2}/, '')
}

export function splitDestinationPath(path: string): { country?: string; region?: string; place?: string } {
  const [country, region, place] = path
    .replace(/^\/|\/$/g, '') // removing surrounding `/` if any
    .replace(/^\w{2}\//, '') // removing language prefix if any
    .split('/')
    .map((value) => value || undefined)

  return { country, region, place }
}
