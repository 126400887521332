import type * as Sentry from '@sentry/vue'

/**
 * Most of the client errors are not important to track in Sentry
 * It could be helpful only in rare cases, so it is better to ignore most of them
 */
export const SENTRY_ERROR_CODES_TO_IGNORE = new Set([
  401, // Unauthorized
  403, // Forbidden
  404, // Not found
  422, // Unprocessable Content
])

/**
 * Sentry callback to skip sending certain types of errors to Sentry
 * @param event
 * @param hint
 * @returns
 */
export function beforeSend(event: Sentry.ErrorEvent, hint: Sentry.EventHint) {
  const error = hint && hint.originalException
  if (error && (error as any).response) {
    const { response } = error as any
    const statusCode = response.status
    if (SENTRY_ERROR_CODES_TO_IGNORE.has(statusCode)) {
      // Discard event
      return null
    }
  }
  return event
}
