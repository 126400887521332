<template>
  <div class="flex items-center justify-between text-txt-strong">
    <h1 class="flex flex-wrap items-center">
      <span class="mr-2 flex items-center"
        ><WebccIcon v-for="s in accom?.evaluation.stars" :key="s" name="content/evaluation-star" class="mr-1 h-4 w-4"
      /></span>
      <span class="mr-2 text-xl font-semibold text-txt-strong md:text-2xl">{{ accom?.type.translation }} {{ accom?.name }}</span>
    </h1>
    <span v-if="showTopRated" class="whitespace-nowrap rounded bg-suc px-2 text-sm font-semibold uppercase text-suc-contrast">{{
      $t('www.components.views.details.Title.labels.toprated')
    }}</span>
  </div>
</template>

<script setup lang="ts">
const TOP_RATED_THRESHOLD = 4.5

const props = defineProps<{
  accom: TranslatedAccommodation
}>()

const showTopRated = computed(() => {
  return props.accom.rating.overall >= TOP_RATED_THRESHOLD
})
</script>
