export default {
  searchTeaser,
  recommendation,
}

function searchTeaser() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Search Results',
    eventAction: 'Scroll Depth',
    eventLabel: 'Header viewed',
    eventNonInteraction: true,
  })
}

// data is the list of recommended accommodations given by webcc-api recommendations endpoint
function recommendation({ params }: { params: { data: PreparedItem[]; target: string } }) {
  useTracking().send({
    event: 'recommender',
    items: params.data.map((r) => r.accom),
    target: params.target,
    _clear: true,
  })
}
