/*
 * Es gibt hierfür keine zentrale Tracking-Spezifikation
 *
 */

export default {
  editableTextblockImpression,
  showedTeaserToBooking,
  clickedTeaserToBooking,
  closedTeaserToBooking,
}

function editableTextblockImpression() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Scroll Depth',
    eventAction: 'Content View',
    eventLabel: 'Editable Block Viewed',
    eventNonInteraction: true,
  })
}

function showedTeaserToBooking() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Dialog',
    eventAction: 'Show dialog',
    eventLabel: 'Continue interrupted booking',
    eventNonInteraction: true,
  })
}

function clickedTeaserToBooking() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Dialog',
    eventAction: 'Open last checkout step',
    eventLabel: 'Continue interrupted booking',
    eventNonInteraction: false,
  })
}

function closedTeaserToBooking() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Dialog',
    eventAction: 'Close dialog',
    eventLabel: 'Continue interrupted booking',
    eventNonInteraction: false,
  })
}
