const COMPANY_NAMES: Record<Company, CompanyName> = {
  ic: 'Interchalet',
  ih: 'Interhome',
}

export function getCompanyName(company: Company): CompanyName {
  return COMPANY_NAMES[company]
}

export function getCompanySlug(company: Company): string {
  return getCompanyName(company).toLowerCase()
}

export function getCompanyIcon(company: Company): string {
  const slug = getCompanySlug(company)
  return `${slug}/${slug}.svg`
}
