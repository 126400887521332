export default defineNuxtPlugin({
  name: 'app:colors',
  parallel: true,
  dependsOn: ['app:params', 'stores'],
  setup(nuxtApp) {
    // here reactivity is lost, but it was probably intended, so the only first render defines iframe params @TODO is it true?
    const { iframe } = useParams()
    const confData = useConfdata()
    const colors = computed(() => applyIframeParamsColors(confData.company === 'ic' ? iccolors : ihcolors, iframe))
    nuxtApp.provide('colors', reactive({ colors }))
  },
})

declare module '#app' {
  interface NuxtApp {
    $colors: Colors
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $colors: Colors
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $colors: Colors
  }
}

function applyIframeParamsColors(colors: Colors, iframe?: IFrameParams | null) {
  if (!iframe) return colors
  const c = { ...colors }
  if (iframe['color-thm']) {
    c.theme = `#${iframe['color-thm']}`
    const { h, s, l } = hex2hsl(iframe['color-thm'])
    c.themeHover = iframe['color-thm-hover'] ? `#${iframe['color-thm-hover']}` : hsl2hex(h, s, l + 8)
    c.themeLight = iframe['color-thm-light'] ? `#${iframe['color-thm-light']}` : hsl2hex(h, s, l + 25)
    c.themeContrast = iframe['color-thm-contrast'] ? `#${iframe['color-thm-contrast']}` : hsl2hex(h, s, l + 50)
  }
  if (iframe['color-cta']) {
    c.cta = `#${iframe['color-cta']}`
    const { h, s, l } = hex2hsl(iframe['color-cta'])
    c.ctaHover = iframe['color-cta-hover'] ? `#${iframe['color-cta-hover']}` : hsl2hex(h, s, l + 10)
    c.ctaContrast = iframe['color-cta-contrast'] ? `#${iframe['color-cta-contrast']}` : hsl2hex(h, s, l + 50)
  }
  if (iframe['color-hlt']) {
    c.highlight = `#${iframe['color-hlt']}`
    const { h, s, l } = hex2hsl(iframe['color-hlt'])
    c.highlightHover = iframe['color-hlt-hover'] ? `#${iframe['color-hlt-hover']}` : hsl2hex(h, s, l + 8)
    c.highlightLight = iframe['color-hlt-light'] ? `#${iframe['color-hlt-light']}` : hsl2hex(h, s, l + 25)
    c.highlightContrast = iframe['color-hlt-contrast'] ? `#${iframe['color-hlt-contrast']}` : hsl2hex(h, s, l + 50)
  }
  if (iframe['color-err']) {
    c.error = iframe['color-err']
  }
  return c
}

const commoncolors = {
  error: '#b83d4e',
  errorHover: '#822b37',
  errorReduced: '#faf0f1',
  errorContrast: '#ffffff',

  warning: '#ffa600',
  warningReduced: '#fff8eb',
  warningContrast: '#131516',

  success: '#36bf78',
  successHover: '#268755',
  successReduced: '#edfdf3',
  successReducedContrast: '#131516',
  successContrast: '#ffffff',

  textStrongest: '#000000',
  textStronger: '#111927', // tailwind text-900
  textStrong: '#1f2937', // tailwind text-800
  text: '#374151', // tailwind text-700
  textWeak: '#6b7280', // tailwind text-500
  textWeaker: '#d1d5db', // tailwind text-300
  textWeakest: '#f3f4f6', // tailwind text-100
  textContrast: '#ffffff',

  background: '#ffffff',
  background50: '#f9fafb',
  background100: '#f3f4f6',
  background200: '#e5e7eb',
  background300: '#d1d5db',
  background400: '#9ca3af',
  background500: '#6b7280',
  background600: '#4b5563',
  background700: '#374151',
  background800: '#1f2937',
  background900: '#111927',
}

const ihcolors = {
  ...commoncolors,

  theme: '#3c91fc',
  themeHover: '#0468e6',
  themeActive: '#02459a',
  themeLight: '#e2efff',
  themeContrast: '#ffffff',

  highlight: '#ff385f',
  highlightHover: '#e9002e',
  highlightActive: '#9c001e',
  highlightLight: '#ffe1e7',
  highlightContrast: '#ffffff',

  cta: '#ff385f',
  ctaHover: '#e9002e',
  ctaActive: '#9c001e',
  ctaLight: '#ffe1e7',
  ctaContrast: '#ffffff',
} as Colors // @TODO There are no `successActive`, `successLight`, `warningHover`, `warningActive` - fix it

const iccolors = {
  ...commoncolors,

  theme: '#19a5cd',
  themeHover: '#47b7b7', // 'rgba(25,165,205,.8)',
  themeLight: '#e8f6fa', // 'rgba(25,165,205,.1)',
  themeContrast: '#e0f6ff',

  highlight: '#F05519',
  highlightHover: '#F07343',
  highlightContrast: '#ffffff',
  highlightLight: '#f3a5b2',

  cta: '#8cb937',
  ctaHover: '#95c850',
  ctaContrast: '#ffffff',
} as Colors // @TODO There are no `successActive`, `successLight`, `warningHover`, `warningActive` - fix it
