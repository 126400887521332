<template>
  <div>
    <div
      ref="scroller"
      :class="{ 'overflow-x-scroll': !(atStart && atEnd) }"
      class="hide-scrollbar relative flex w-full snap-x snap-proximity scroll-px-2 gap-2 scroll-smooth"
      @scroll="scroll"
    >
      <slot />
    </div>
    <!-- Tailwind does not allow the usage of two gradient-stops, so we need to use two separate gradients if we want a large transparent area in the centre -->
    <div v-if="fade && !atStart" class="pointer-events-none absolute left-0 top-8 h-full w-1/2 bg-gradient-to-l from-transparent from-90% to-bgr"></div>
    <div v-if="fade && !atEnd" class="pointer-events-none absolute right-0 top-8 h-full w-1/2 bg-gradient-to-r from-transparent from-90% to-bgr"></div>
    <div v-if="buttons" class="absolute top-1/2 w-full">
      <div v-if="!atStart" class="absolute left-0 md:left-4">
        <WebccButton variant="white" icon="searchpage/chevron-left-large" round @click="scrollTo('left')" />
      </div>
      <div v-if="!atEnd" class="absolute right-0 md:right-4">
        <WebccButton variant="white" icon="searchpage/chevron-right-large" round @click="scrollTo('right')" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    step: number
    fade?: boolean
    buttons?: boolean
  }>(),
  {
    fade: false,
    buttons: true,
  },
)

const emit = defineEmits<{
  scrollClick: [direction: ScrollDirectionHorizontal]
}>()

const scroller = ref<HTMLDivElement>()
const atStart = ref(true)
const atEnd = ref(false)

onMounted(() => {
  scroll()
})

function scroll() {
  if (!scroller.value) return

  const { scrollLeft, offsetWidth, scrollWidth } = scroller.value
  atStart.value = scrollLeft === 0
  atEnd.value = scrollLeft + offsetWidth === scrollWidth
}

function scrollTo(direction: ScrollDirectionHorizontal) {
  emit('scrollClick', direction)
  if (scroller.value) scroller.value.scrollLeft += (direction === 'left' ? -1 : 1) * props.step
}
</script>
