<template>
  <section v-impression:contentAssets:secretSpotsDisplayed.100 class="container-fluid-lg mb-14">
    <SearchAssetsHeader :title="title" :link="link.url" :on-read-more-click="onReadMoreClick">
      <template #default>{{ link.title }}</template>
    </SearchAssetsHeader>
    <ul class="list-disc ml-4 px-4">
      <li v-for="(item, index) in content" :key="index" class="mb-2">
        {{ item }}
      </li>
    </ul>
  </section>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue: SecretSpot
}>()

const { title, link, content } = props.modelValue

function onReadMoreClick() {
  useTracking().handlers?.contentAssets.secretSpotsReadMore()
}
</script>
