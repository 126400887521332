<template>
  <div>
    <div v-if="vacancyOpen" class="fixed inset-0 z-1 bg-gray-900 bg-opacity-90 transition-opacity" aria-hidden="true" @click="toggleVacancyPopover"></div>
    <VDropdown :aria-id="areaId" :delay="0" placement="left" :triggers="[]" :shown="vacancyOpen" :auto-hide="false" :distance="10" @click.stop>
      <section class="relative mb-4 mt-8 rounded bg-bgr p-4 shadow" :class="{ 'z-1': vacancyOpen }">
        <!-- no PriceData -->
        <!-- TODO: Bei fehlgeschlagenem CheckPrice führt das dazu, dass über die sidebar kein neues Datum ausgewählt werden kann -->
        <div v-if="state === CheckPriceState.NotAvailable">
          <!-- Titel -->
          <p class="mb-8 border-b-2 pb-2 text-sm" :class="titleClass">{{ $t(`${trPrefix}messages.${props.state}`) }}</p>
          <WebccButton class="w-full" variant="cta" size="lg">
            <span>{{ $t(`${trPrefix}button.labels.NA`) }}</span>
          </WebccButton>
        </div>
        <div v-else-if="!vacancies">
          <WebccButton class="w-full" variant="cta" size="lg">
            <span>{{ $t(`${trPrefix}button.labels.NA`) }}</span>
          </WebccButton>
        </div>
        <div v-else>
          <!-- Titel -->
          <p class="border-b-2 pb-2 text-sm" :class="titleClass">{{ $t(`${trPrefix}messages.${props.state}`) }}</p>
          <!-- Datumsfelder -->
          <div class="mt-6 flex h-12 flex-nowrap">
            <label class="mr-4 flex-shrink text-sm tracking-wider text-txt-weak">
              <!-- Hier haben wir auch Probleme bei der Datumsdarstellung -->
              <WebccInput
                v-cloak
                v-model="checkin"
                name="checkin"
                :label="$t(`${trPrefix}labels.checkin`)"
                icon="site/calendar"
                :placeholder="$t(`${trPrefix}placeholder`)"
                readonly
                @click="handleDateClick(true)"
              />
            </label>
            <label class="text-sm tracking-wider text-txt-weak">
              <WebccInput
                v-cloak
                v-model="checkout"
                name="checkout"
                :label="$t(`${trPrefix}labels.checkout`)"
                icon="site/calendar"
                :placeholder="$t(`${trPrefix}placeholder`)"
                readonly
                @click="handleDateClick(false)"
              />
            </label>
          </div>
          <!-- Pax-Select -->
          <div class="mt-4">
            <label class="text-sm tracking-wider text-txt-weak">
              <DetailsPaxSelector
                v-model="pax"
                v-tooltip="{ content: !trip.checkin ? tooltips.paxSelect : '', html: true }"
                :max="paxUpTo"
                :prefix="trPrefix"
                :label="$t(`${trPrefix}labels.pax`)"
                :disabled="!trip.checkin"
              />
            </label>
          </div>

          <!-- Beispielzeitraum MinPrice -->
          <div class="mt-6 min-h-[6rem]">
            <div v-if="!trip.checkin && price.checkin" class="text-left text-sm">
              <span>{{ $t(`${trPrefix}eg`) }}</span> {{ formatDateRange(price.checkin!, price.checkout!) }}
            </div>
            <!-- PricePerNight -->
            <dl v-if="pricePerNight" v-cloak class="mt-1 flex flex-wrap justify-between text-sm">
              <dt class="mr-2">{{ $t(`${trPrefix}price.base`) }}</dt>
              <dd class="grow text-right text-sm font-normal">
                {{ formatCurrency(pricePerNight, { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: false }, currency) }}
              </dd>
            </dl>
            <!-- Price -->
            <div v-if="price.price" class="flex flex-wrap items-end justify-between align-baseline text-lg font-semibold text-[#676767]">
              <span class="mr-2 text-sm leading-6">{{ $t(`${trPrefix}price.total`, { duration: duration.toString() }) }}</span>
              <span class="grow text-right text-[#676767]">
                <span v-if="!trip.checkin" class="text-sm">{{ $t(`${trPrefix}priceFrom`) }}</span>
                {{ formatCurrency(price.price, undefined, currency) }}
              </span>
            </div>
            <!-- SpecialPriceReduction -->
            <div v-if="price.reduction" class="mt-1 divide-x divide-double text-right text-sm text-hlt-hover">
              <span class="pr-2 line-through">{{ formatCurrency(price.regularPrice!, undefined, currency) }}</span>
              <span class="pl-2">
                {{ formatCurrency(price.reduction, undefined, currency) }}
                <span>{{ $t(`${trPrefix}saved`) }}</span>
              </span>
            </div>

            <!-- Nebenkosten-Link und Belegungshinweis -->
            <div v-if="state === CheckPriceState.Available" class="mt-4">
              <p class="text-sm">
                <span>{{ $t(`${trPrefix}priceOccupancy`) }}</span
                >:
                <span>{{ $t(`${trPrefix}person_s`, pax) }}</span>
              </p>

              <p class="mt-1 flex cursor-pointer text-sm" @click="emit('scrollto', 'costsRef')">
                <span>{{ $t(`${trPrefix}toOverview`) }}</span>
                <span>&nbsp;</span>
                <span>{{ $t(`${trPrefix}additionalCosts`) }}</span>
                <span><WebccIcon class="!block h-7 w-7 text-[#676767]" name="site/chevron-right" filled /></span>
              </p>
            </div>
            <WebccFreeCancellationInfo
              v-if="trip.checkin"
              v-track:impression="{ event: 'search:freeCancellationImpression' }"
              :checkin-date="new Date(trip.checkin)"
              :free-cancellation-range="freeCancellationRange"
              :full-parent-width="true"
              class="mt-2"
              :locale="useL10N().locale"
            />
          </div>
          <!-- buttons -->
          <div class="m-auto mt-6 w-full text-center text-sm lg:w-5/6">
            <!-- booking button -->
            <WebccButton class="w-full" variant="cta" size="lg" :disabled="!price.price" @click="onBookingClick(false)">
              <span>{{ $t(`${trPrefix}button.labels.${state}`) }}</span>
            </WebccButton>
            <!-- option button -->
            <WebccButton v-if="optionPossible" class="mt-4 w-full" variant="cta" size="lg" @click="onBookingClick(true)">
              <span>{{ $t(`${trPrefix}button.labels.OP`) }}</span>
            </WebccButton>
            <small class="block pt-2 leading-tight text-[#676767]">{{ $t(`${trPrefix}summary`) }}</small>
          </div>
          <WebccLoaderOverlay v-if="state === CheckPriceState.Loading" absolute type="blocks" :message="$t(`${trPrefix}messages.LOADING`)" />
        </div>
      </section>
      <template #popper>
        <div v-if="vacancies" class="relative">
          <DetailsVacancy v-model="trip" class="top-6 min-w-[22rem] rounded p-2" :vacancies="vacancies" :pax-up-to="paxUpTo" />
          <button class="absolute right-4 top-4 cursor-pointer rounded-full bg-black/40 text-white hover:bg-black/60" @click="toggleVacancyPopover">
            <WebccIcon name="site/x" class="h-6 w-6 stroke-2 p-0.5" />
          </button>
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.details.Sidebar.'

const { t } = useI18n()

const vacancyOpen: Ref<boolean> = ref(false)

interface Props {
  modelValue?: TripUpdate
  state: CheckPriceState
  price: AccomPrice
  vacancies?: Trips | null
  paxUpTo?: number
  optionPossible?: boolean
  accommodation: TranslatedAccommodation
  freeCancellationRange: number
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => ({}) as TripUpdate,
  price: () => ({}) as AccomPrice,
  vacancies: () => ({}) as Trips,
  paxUpTo: 1,
  optionPossible: false,
  freeCancellationRange: 0,
})

const emit = defineEmits<{
  'update:modelValue': [TripUpdate]
  book: [BookingOptions]
  scrollto: [string]
}>()

const areaId = useId()

const titleClasses: Record<string, string> = {
  [CheckPriceState.Available]: 'border-suc',
  [CheckPriceState.OnRequest]: 'border-wrn',
  [CheckPriceState.NotAvailable]: 'border-err',
  [CheckPriceState.Example]: 'border-bgr-300',
  [CheckPriceState.Loading]: 'border-bgr-300',
}

const vacancyPopover = computed(() => useAB().isActive('vacancypopover'))
const titleClass = computed(() => titleClasses[props.state])
const currency = computed(() => useConfdata().currency)
const checkin = computed(() => (props.modelValue.checkin ? formatDatetime(toDate(props.modelValue.checkin)) : ''))
const checkout = computed(() => (props.modelValue.checkout ? formatDatetime(toDate(props.modelValue.checkout)) : ''))
const duration = computed(() => getTripDuration(props.modelValue) ?? props.price.duration ?? DURATION_DEFAULT)

const pax = computed({
  get() {
    return props.modelValue.pax || props.paxUpTo
  },
  set(value) {
    emit('update:modelValue', { pax: parseInt(value.toString()) })
  },
})

const trip = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
    toggleVacancyPopover()
  },
})

const pricePerNight = computed(() => {
  if (props.price.price && pax.value && duration.value) {
    return props.price.price / pax.value / duration.value
  }
  return 0
})

const tooltips = computed(() => {
  return {
    costOverview: false,
    paxSelect: `${t(`${trPrefix}paxNoDuration`)}<br />${t(`${trPrefix}paxAfterDuration`)}`,
    currency: 'fake',
  }
})

function handleDateClick(trackDateClick: boolean) {
  if (trackDateClick) {
    useTracking().handlers?.detail.sidebarDateClick()
    useTracking().handlers?.detail.sidebarArrivalDate(props.modelValue.checkin)
  }
  if (vacancyPopover.value) {
    toggleVacancyPopover()
  } else {
    emit('scrollto', 'vacanciesRef')
  }
}

function onBookingClick(isOption: boolean) {
  if (props.state === CheckPriceState.Available || props.state === CheckPriceState.OnRequest) {
    useTracking().handlers?.ecommerce.addToCart(Object.assign(props.accommodation, props.price, props.modelValue))
    emit('book', { isOption })
  } else if (!vacancyOpen.value && vacancyPopover.value) {
    toggleVacancyPopover()
  } else {
    emit('scrollto', 'vacanciesRef')
  }
}

function toggleVacancyPopover() {
  if (vacancyOpen.value) {
    document.body.classList.remove('overflow-hidden', 'pr-[15px]')
  } else {
    document.body.classList.add('overflow-hidden', 'pr-[15px]')
  }
  vacancyOpen.value = !vacancyOpen.value
}
</script>
