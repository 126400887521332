<template>
  <label class="-mx-1 flex cursor-pointer items-center justify-between p-1 text-xs hover:bg-bgr-300">
    <!-- <input v-model="model" type="checkbox" v-bind="$attrs" class="mx-1" /> -->
    <WebccCheckbox v-model="model" v-bind="$attrs" class="mx-1" />
    <span class="ml-1 mr-auto">{{ $t(`www.components.views.search.filter.${filter}` as TranslationKey) }}</span>
    <span class="rounded-full bg-bgr-200 px-1 py-0.5 text-xs font-medium text-txt-strong" v-text="count"></span>
  </label>
</template>

<script setup lang="ts">
const props = defineProps<{
  filter: string
  count?: number
}>()

const emit = defineEmits<{ (e: 'update:modelValue', value: boolean | string | number): void }>()

const model = computed({
  get(): boolean | string | number {
    return (useSearch().filters[props.filter as keyof Params] || false) as boolean | string | number
  },
  set(value: boolean | string | number) {
    useSearch().setFilter({ [props.filter]: value })
    emit('update:modelValue', value)
  },
})
</script>
