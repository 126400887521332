<template>
  <div
    class="md:container-fluid-lg fixed bottom-0 flex h-full w-full max-w-full justify-center overflow-y-auto px-6 sm:h-auto sm:-translate-y-9 sm:bg-transparent z-[9]"
    :class="{ 'bg-bgr-800 bg-opacity-80': !configIsOpen }"
  >
    <div class="consent-banner my-8 max-h-[85vh] overflow-y-auto rounded bg-bgr p-6" tabindex="-1" role="dialog" aria-hidden="true">
      <p class="mb-4 text-lg font-medium sm:text-xl">{{ $t(`${trPrefix}cookiesTitle`) }}</p>
      <span>{{ $t(`${trPrefix}cookiesInfotextBefore`) }}</span
      >&nbsp;<span class="inline-block cursor-pointer font-medium underline hover:no-underline" @click="openConfig()">{{
        $t(`${trPrefix}cookiesConfigureHere`)
      }}</span
      ><span>{{ $t(`${trPrefix}cookiesInfotextAfter`) }}</span
      >&nbsp;<span>{{ $t(`${trPrefix}cookiesMoreInfoBefore`) }}</span
      >&nbsp;<span class="inline-block cursor-pointer font-medium underline hover:no-underline" @click="openPrivacypolicy()">{{
        $t(`${trPrefix}cookiesMoreInfoHere`)
      }}</span
      >&nbsp;<span>{{ $t(`${trPrefix}cookiesMoreInfoAfter`) }}</span>
      <div class="mt-6 flex flex-col items-center justify-start gap-4 sm:flex-row">
        <WebccButton class="h-11 w-48" variant="theme" @click="sendChoice({ statistics: true, other: true })">
          <span>{{ $t(`${trPrefix}cookiesAccept`) }}</span>
        </WebccButton>
        <span class="cursor-pointer underline transition-all hover:no-underline" @click="sendChoice({ statistics: false, other: false })">{{
          $t(`${trPrefix}cookiesDecline`)
        }}</span>
        <span class="cursor-pointer underline transition-all hover:no-underline" @click="openConfig()">{{ $t(`${trPrefix}cookiesConfigureLink`) }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'components.cookieConsent.'

defineProps<{
  configIsOpen: boolean
  openConfig: () => void
  openPrivacypolicy: () => void
}>()

const emit = defineEmits<{
  'save-choice': [choice: Choice]
  close: []
}>()

// body scroll lock
const windowScrollLock = useScrollLock(window)
const screen = useScreen()
const xs = screen.breakpoints.smaller('sm')
watch(xs, (isScreenSmall) => (windowScrollLock.value = isScreenSmall))

function sendChoice(values: Choice) {
  emit('save-choice', { statistics: values.statistics, other: values.other })
}
</script>

<style scoped>
.consent-banner {
  /* TODO: How to do this in tailwind? */
  box-shadow: 0px 0px 16px 8px rgba(0, 0, 0, 0.12);
}
</style>
