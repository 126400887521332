<template>
  <section class="w-full">
    <!--- Hinweis-Box zur ungenauen Lagedarstellung--->
    <div v-if="accom.coordinates.accuracy !== 'EXACT' && !placeholder" class="flex justify-between text-base font-light">
      <p class="pb-2">{{ $t(`${trPrefix}accuracyHint`) }}</p>
    </div>
    <div class="h-96 overflow-hidden rounded shadow">
      <div v-if="placeholder" class="placeholder h-full">
        <div class="mx-auto my-0 flex h-full w-full max-w-md flex-col items-center justify-center p-4 text-center">
          <p class="mb-4 w-64 text-xl font-light text-txt">{{ $t(`${trPrefix}activation`) }}</p>
          <WebccButton size="md" variant="theme" @click="placeholder = false">
            <span>{{ $t(`${trPrefix}activate`) }}</span>
          </WebccButton>
        </div>
      </div>
      <GoogleMaps
        v-else
        :locale="useL10N().locale"
        :domain="useConfdata().domain"
        class="relative h-full"
        :marker="accom.name"
        :center="{ lat: accom.coordinates.lat!, lng: accom.coordinates.lng! }"
      />
      <!--   -->
    </div>

    <p v-if="geoLinks" class="inline-flex flex-wrap items-center space-x-1 py-2 text-base font-light text-txt">
      <WebccIcon name="detailpage/pin" class="mr-1 h-4 w-4 fill-current stroke-current" filled />
      <WebccLink :to="accom.country.slug">{{ accom.country.name }} </WebccLink>,

      <WebccLink v-if="accom.region.main" :to="accom.region.main.slug">{{ accom.region.main.name }}, </WebccLink>
      <WebccLink :to="accom.region.slug">{{ accom.region.name }} </WebccLink>,

      <WebccLink v-if="accom.place.main" :to="accom.place.main.slug">{{ accom.place.main.name }}, </WebccLink>
      <WebccLink :to="accom.place.slug">{{ accom.place.name }}</WebccLink>
    </p>
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.details.Map.'

interface Props {
  accom: TranslatedAccommodation
  geoLinks?: boolean
}

withDefaults(defineProps<Props>(), {
  geoLinks: true,
})

const placeholder: Ref<boolean> = ref(true)
</script>

<style lang="css" scoped>
.placeholder {
  background: white url('/map/map-placeholder.svg') center/cover no-repeat;
}
</style>
