import type { Pinia } from 'pinia'

const storesOrder = [
  // no deps
  ['autosuggest', useAutosuggestStore] as const,
  ['useragent', useUseragentStore] as const,
  ['pagehead', usePageHeadStore] as const,
  ['settings', useSettingsStore] as const,
  ['ids', useIDsStore] as const,
  ['toggler', useTogglerStore] as const,
  ['consent', useConsentStore] as const, // has `useTracking` dep, but only during `update()` fn call

  ['user', useUserStore] as const,
  ['partner', usePartnerStore] as const, // params, cookies
  ['tracking', useTrackingStore] as const, // consent, params, cookies, gtag, ids
  ['confdata', useConfdataStore] as const, // params, useragent, partner
  ['ab', useABStore] as const, // params, confdata, ids
  ['auth', useAuthStore] as const, // ab
  ['usersurvey', useUserSurveyStore] as const, // ids, tracking
  ['bookmarks', useBookmarksStore] as const, // partner, confdata
  ['navigation', useNavigationStore] as const, // params, ab
  ['reviews', useReviewsStore] as const, // confdata, params
  ['flextrip', useFlexTripStore] as const, // params, ab
  ['search', useSearchStore] as const, // tracking, confdata
  ['popups', usePopupsStore] as const,
  // ['recommendations', useRecommendationsStore] as const,
] as const

function mutateStores(stores: Record<string, unknown>, pinia: Pinia) {
  for (const [name, storeFn] of storesOrder) {
    Object.assign(stores, { [name]: storeFn(pinia) })
  }
  return stores
}

const piniaStorePlugin = defineNuxtPlugin({
  name: 'stores',
  enforce: 'pre',
  dependsOn: ['pinia', 'app:i18n', 'app:params'],
  setup(nuxtApp) {
    const $pinia = nuxtApp.$pinia as Pinia
    let $stores = {}
    Object.defineProperty(nuxtApp.vueApp, '$stores', { get: () => $stores })
    nuxtApp.provide('stores', $stores)
    mutateStores($stores, $pinia)
    $stores = Object.freeze($stores)
  },
})

export default piniaStorePlugin

interface StoresMap {
  useragent: ReturnType<typeof useUseragentStore>
  ab: ReturnType<typeof useABStore>
  auth: ReturnType<typeof useAuthStore>
  autosuggest: ReturnType<typeof useAutosuggestStore>
  bookmarks: ReturnType<typeof useBookmarksStore>
  confdata: ReturnType<typeof useConfdataStore>
  consent: ReturnType<typeof useConsentStore>
  popups: ReturnType<typeof usePopupsStore>
  flextrip: ReturnType<typeof useFlexTripStore>
  navigation: ReturnType<typeof useNavigationStore>
  pagehead: ReturnType<typeof usePageHeadStore>
  partner: ReturnType<typeof usePartnerStore>
  // recommendations: ReturnType<typeof useRecommendationsStore>
  reviews: ReturnType<typeof useReviewsStore>
  ids: ReturnType<typeof useIDsStore>
  search: ReturnType<typeof useSearchStore>
  settings: ReturnType<typeof useSettingsStore>
  toggler: ReturnType<typeof useTogglerStore>
  tracking: ReturnType<typeof useTrackingStore>
  user: ReturnType<typeof useUserStore>
  usersurvey: ReturnType<typeof useUserSurveyStore>
}

declare module '#app' {
  interface NuxtApp {
    $stores: StoresMap
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $stores: StoresMap
  }
}
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $stores: StoresMap
  }
}
