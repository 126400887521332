export function getQueryCoordinatesParams(query: Record<string, unknown>): Params {
  const [lat, lon] = [query.lat, query.lon].map((value) => tryParseFloat(value?.toString()))
  const radius = query.radius && /^\d+km/.test(query.radius.toString()) ? query.radius.toString() : '20km'
  const name = query.name?.toString()
  const heading = query.heading?.toString()

  if (!lat || !lon) return { lat: undefined, lon: undefined, radius: undefined, name, heading }

  return { lat, lon, radius, name, heading }
}
