<template>
  <div v-if="price">
    <div class="text-thm">
      <!-- new -->
      <div v-if="useFlag('SRP-display-price-per-night').value && pricePerNight">
        <span class="mr-1">{{ $t(`${trPrefix}from`) }}</span>
        <span class="text-lg font-semibold">{{ formatCurrency(pricePerNight, undefined, currency) }}</span>
        <span class="mr-1"> / {{ $t(`${trPrefix}night`) }}</span>
        <span class="text-black">
          <span>| </span>
          <span class="font-medium">{{ formatCurrency(price, undefined, currency) }}</span>
          <span>&nbsp;{{ $t(`${trPrefix}total`) }}</span>
        </span>
        <span
          v-if="discount"
          v-tooltip="$t(`${trPrefix}saved`, { amount: formatCurrency(discount, undefined, currency) })"
          class="ml-2 text-sm text-hlt-hover line-through"
        >
          {{ formatCurrency(origPrice, undefined, currency) }}
        </span>
      </div>
      <!-- normal -->
      <div v-else>
        <span class="mr-1">{{ $t(`${trPrefix}from`) }}</span>
        <span class="text-lg font-semibold">{{ formatCurrency(price, undefined, currency) }}</span>
        <span
          v-if="discount"
          v-tooltip="$t(`${trPrefix}saved`, { amount: formatCurrency(discount, undefined, currency) })"
          class="ml-2 text-sm text-hlt-hover line-through"
        >
          {{ formatCurrency(origPrice, undefined, currency) }}
        </span>
      </div>
    </div>
    <!-- pax -->
    <div class="flex flex-row gap-1 text-xs">
      <span v-if="pax.length">{{ $t(`${trPrefix}upToPax`, Math.max(...pax)) }}</span>
      <span v-else>Missing Pax Information</span> |
      <span>{{ formatDateRange(checkin, checkout) }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.hitbox.Price.'

const props = defineProps<{
  trip: Trip
  currency: string
}>()

const { checkin, checkout, pax, price, reduction, pricePerNight } = toRefs(props.trip)
const origPrice = computed(() => price.value / ((100 - (reduction?.value ?? 0)) / 100))
const discount = computed(() => origPrice.value - price.value)
</script>
