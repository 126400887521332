<template>
  <main class="container-fluid-md my-8">
    <WebccHeading :level="1" title="Email-Verwaltung" space="mb-4" class="text-center" />
    <section class="relative min-h-1/4-screen rounded bg-bgr p-4 shadow">
      <p>Auf dieser Seite kann die Emailadresse mit folgendem Hash verwaltet werden.</p>
      <p>Hash: {{ hash }}</p>
      <ul class="mt-8 list-inside list-disc space-y-4">
        <li v-for="[action, { label, url }] in Object.entries(actions)" :key="action">
          <WebccButton class="mr-1" :label="label" @click="navigateTo(url)" />
          <WebccLink anchor :to="url">{{ url }}</WebccLink>
        </li>
      </ul>
      <ul v-if="useConfdata().signupEnabled" class="mt-16 list-inside list-disc">
        <li>
          <WebccLink :to="`/newsletter`">Zur Newsletter-Anmeldung</WebccLink>
        </li>
        <li>
          <WebccLink anchor :to="actions.unsubscribe.url">Diese Email vom Newsletter abmelden</WebccLink>
        </li>
      </ul>
    </section>
  </main>
</template>

<script setup lang="ts">
const actions = computed(() => ({
  confirm: { label: 'Confirm', url: getActionUrl('confirm') },
  unsubscribe: { label: 'Unsubscribe', url: getActionUrl('unsubscribe') },
  optin: { label: 'Opt-in', url: getActionUrl('optin') },
}))

const { hash, getActionUrl } = useEmails()
</script>
