<template>
  <div class="bg-bgr-50">
    <main class="container-fluid-md min-h-2/3-screen py-4">
      <WebccHeading :level="1" space="mt-8 mb-4" class="text-center">
        <span>{{ $t(`${trPrefix}title`) }}</span>
      </WebccHeading>

      <section class="relative min-h-1/4-screen rounded bg-bgr p-4 shadow">
        <WebccLoaderOverlay v-if="loading" absolute rounded="md" />

        <template v-if="success">
          <article>
            <WebccHeading :level="2">
              <span>{{ $t(`${trPrefix}success.heading`) }}</span>
            </WebccHeading>
            <main class="flex flex-col gap-2">
              <span>{{ $t(`${trPrefix}success.transmission`) }}</span>
              <span>{{ $t(`${trPrefix}success.continue`) }}</span>
              <WebccButton class="mt-4 w-fit" @click="toStartpage">
                <span>{{ $t(`${trPrefix}success.inspiration`) }}</span>
              </WebccButton>
            </main>
          </article>
        </template>

        <WebccForm v-else v-model="formdata" class="md:ml-32" @submit="submit">
          <WebccHeading :level="3" space="my-4">
            <span>{{ $t(`${trPrefix}sub`) }}</span>
          </WebccHeading>

          <p class="mb-8">
            <span>{{ $t(`${trPrefix}orderText`) }}</span> <span>{{ $t(`${trPrefix}orderUnsubscribePossible`) }}</span>
          </p>

          <p class="my-2 text-txt-weak">{{ $t('plugins.form.Formfield.requiredInfo') }}</p>
          <WebccFormfield
            component="WebccSelect"
            class="mb-2 max-w-xs"
            :label="$t(`${trPrefix}salutation`)"
            name="salutation"
            type="radio"
            :options="[{ '1': $t(`${trPrefix}mr`) }, { '2': $t(`${trPrefix}ms`) }]"
          />

          <WebccFormfield component="WebccInput" label-position="left" class="mb-2 max-w-lg" :label="$t(`${trPrefix}firstname`)" name="firstname" type="text" />

          <WebccFormfield component="WebccInput" label-position="left" class="mb-2 max-w-lg" :label="$t(`${trPrefix}lastname`)" name="lastname" type="text" />

          <WebccFormfield
            ref="email"
            component="WebccInput"
            label-position="left"
            class="mb-2 max-w-lg"
            :label="$t(`${trPrefix}email`)"
            name="email"
            required
            type="email"
            @input="emailError = false"
          />

          <WebccCaptcha v-model="captcha" class="mt-6 mb-8" :locale="useConfdata().locale!" />

          <TextHTML path="gdpr" tag="p" class="mb-6 max-w-3xl text-sm text-txt" :prefix="trPrefix" placeholder-pattern="dse">
            <template #dse>
              <WebccLink anchor target="_blank" class="text-thm" :to="useConfdata().links?.privacyPolicy?.href">
                <span>{{ $t(`${trPrefix}privacyPolicy`) }}</span>
              </WebccLink>
            </template>
          </TextHTML>

          <div v-if="emailError" class="my-4 rounded border border-err p-4 text-err">{{ $t(`${trPrefix}emailError`) }}</div>
          <div v-else-if="success === false" class="my-4 rounded border border-err p-4 text-err">{{ $t(`${trPrefix}error`) }}</div>

          <WebccButton variant="cta" size="lg" :disabled="!captcha" class="">
            <span>{{ $t(`${trPrefix}submit`) }}</span>
          </WebccButton>
        </WebccForm>
      </section>
    </main>
    <FooterNewsletterSignup />
  </div>
</template>

<script setup lang="ts">
import type { Formdata } from '~/components/@ihgroup/form/InjectionKeys'

const trPrefix = 'www.pages.newsletter.'

const formdata = ref({
  salutation: '5', // wenn der User nichts auswählt, soll eine "5" an V5 / FOCUS gesendet werden
  firstname: '',
  lastname: '',
  email: useState('email').value as string,
})

const success: Ref<boolean | null> = ref(null)
const loading: Ref<boolean | null> = ref(false)
const captcha: Ref<string> = ref('')
const emailError: Ref<boolean | null> = ref(false)
const registrationType: Ref<string> = ref(useRoute().query.type?.toString() || '')

if (import.meta.server) {
  try {
    useState('email').value = useRequestEvent()!.context.email
  } catch (error) {
    console.error(error)
  }
}

async function submit(form: Formdata) {
  formdata.value = form as {
    salutation: string
    firstname: string
    lastname: string
    email: string
  }
  loading.value = true

  let heid = ''

  try {
    // validation API
    const emailValidationResult = await useValidation().email(formdata.value.email)
    heid = emailValidationResult!.hashPrefix!
    if (emailValidationResult!.score < 3) {
      emailError.value = true
      loading.value = false
      return
    }
  } catch (error) {
    console.error(error)
    emailError.value = true
    loading.value = false
    return
  }

  const { error, pending } = await useEmails().subscribe({ ...formdata.value, captcha: captcha.value })

  loading.value = pending.value
  success.value = !error.value

  if (success.value) {
    useTracking().handlers?.newsletter.registration(registrationType.value)
    useTracking().handlers?.newsletter.registrationHeID({ type: registrationType.value, heid })
  } else {
    useTracking().handlers?.newsletter.registrationFailed()
    useTracking().handlers?.newsletter.registrationFailedAdditional()
  }
}

onMounted(() => {
  scrollTo(0, 0)
})

async function toStartpage() {
  await navigateTo('/')
}
</script>
