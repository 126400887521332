<template>
  <div class="bg-bgr">
    <section class="flex min-h-2/3-screen items-stretch bg-bgr-50 py-4">
      <EmailsConfirm v-if="action === 'confirm'" />
      <EmailsOptin v-else-if="action === 'optin'" />
      <EmailsUnsubscribe v-else-if="action === 'unsubscribe'" />
      <EmailsActions v-else />
    </section>
  </div>
</template>

<script setup lang="ts">
const { action, queryAction, getActionUrl } = useEmails()

if (queryAction.value) {
  await navigateTo(getActionUrl(queryAction.value, { canonical: true }))
}
</script>
