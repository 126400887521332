<template>
  <section ref="hitboxGallery" :key="key" class="group relative w-full overflow-hidden">
    <ImageSlider
      class="overflow-hidden rounded-tl rounded-tr md:rounded-tr-none"
      :images="images"
      :cover-full="coverFull"
      @slide-change-intent.once="onSlideChangeIntent"
      @slide-button-click="onSlideButtonClick"
      @click="onClick"
    />
    <slot />
    <div class="absolute flex h-full w-full items-center justify-center">
      <NuxtImg v-if="!$params.iframe" :src="companyLogo" class="w-1/2" />
    </div>
  </section>
</template>

<script setup lang="ts">
import type { SliderImage } from '../info/ImageSlider.vue'

const trPrefix = 'www.components.hitbox.Gallery.'

const props = withDefaults(
  defineProps<{
    accom: ResultDocument
    checkin?: Date
    coverFull?: boolean
    transformations?: Record<string, string>
  }>(),
  {
    checkin: undefined,
    transformations: () => {
      return { xl: 'tr:n-srp_hitbox_sm', lg: 'tr:n-srp_hitbox_sm', md: 'tr:n-srp_hitbox_l' }
    },
  },
)

const emit = defineEmits<{
  open: []
}>()

const { t } = useI18n()
const { buildImageUrl } = useURLs()
const screen = useScreen()
const tracking = useTracking()
const confData = useConfdata()

const hitboxGallery = ref<HTMLElement>()

const mobile = ref(useUseragent().useragent?.device === 'mobile')
const loadedImages = ref<SliderImage[]>([])
const loading = ref(false)

const key = computed(() => new Date().toISOString() + screen.current)
const companyLogo = computed(() => `logos/${getCompanySlug(confData.company)}.svg`)
const transformation = computed(() => props.transformations[screen.current] || 'tr:n-srp_hitbox_xl')

const images = computed(() => {
  if (loadedImages.value.length) return loadedImages.value

  const firstImage = props.accom.image?.id && toSliderImage(props.accom.image.id)
  const nextImagePlaceholder = { id: '', src: '', alt: '', title: '' } // to always allow the first try of sliding
  return firstImage ? [firstImage, nextImagePlaceholder] : []
})

onMounted(() => {
  if (!mobile.value || !hitboxGallery.value) return

  // On mobile, loading images as soon as hitbox appears on the screen
  const observer = new IntersectionObserver(
    (entries) => {
      entries
        .filter(({ isIntersecting }) => isIntersecting)
        .forEach((entry) => {
          loadImageKeys()
          observer.unobserve(entry.target)
        })
    },
    { root: null, rootMargin: '200px 0px', threshold: 0.0 },
  )

  observer.observe(hitboxGallery.value)
})

async function loadImageKeys() {
  if (loadedImages.value.length || loading.value) return

  loading.value = true
  loadedImages.value = (await useAccommodation(props.accom.code).getImages(useSeason(props.checkin))).map(toSliderImage)
  loading.value = false
}

function toSliderImage(id: string): SliderImage {
  const { image, slug, name, region, place, code } = props.accom
  const title = image?.type ? t(`${trPrefix}${image.type}`) : ''
  const alt = slug ? `${title}|${name}|${region}|${place}` : code
  const src = buildImageUrl(id, transformation.value)
  return { id, alt, title, src }
}

function onSlideChangeIntent() {
  loadImageKeys()
}

function onSlideButtonClick() {
  tracking.handlers?.detail.galleryNavigation()
}

function onClick() {
  emit('open')
}
</script>
