import { skipHydrate } from 'pinia'

const STORAGE_KEY = 'CONSENT'

export const useConsentStore = defineStore('consent', () => {
  const consent = useLocalStorage<Consent>(STORAGE_KEY, () => ({}))
  const configOpen = ref(false)

  const open = computed(() => !consent.value.timestamp)

  function update(value: Consent) {
    consent.value = value
    useTracking().trackConsentUpdate(value)
  }

  return { consent: skipHydrate(consent), open, configOpen, update }
})
