<template>
  <div v-if="usePartner().jwt && !$params.iframe" class="bg-hlt-light">
    <div class="container-fluid-xl text-txt flex justify-between py-3.5 text-sm">
      <div>
        <span>{{ $t(`${trPrefix}id`) }}</span
        >: <strong>{{ usePartner().id }}</strong>
      </div>
      <div>
        <WebccLink anchor class="mr-10 underline hover:no-underline" to="https://partners.interhome.group/portal">
          <span>{{ $t(`${trPrefix}portal`) }}</span>
        </WebccLink>
        <WebccLink anchor class="mr-10 underline hover:no-underline" to="/booking/logout/">
          <span>{{ $t(`${trPrefix}logout`) }}</span>
        </WebccLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'plugins.components.B2BInfobar.'
</script>
