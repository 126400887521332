import pick from 'lodash-es/pick'
import pickBy from 'lodash-es/pickBy'

export function objectsEqual(a: object, b: object) {
  return JSON.stringify(a) === JSON.stringify(b)
}

export function getAnyOf<Obj extends Record<string, unknown>, Keys extends (keyof Obj)[] | string[] | readonly string[]>(
  obj: Obj,
  keys: Keys,
): Keys extends (keyof Obj)[] ? Obj[Keys[number]] : unknown {
  return Object.values(pick(obj, keys))[0]
}

export function removeFalsy<T extends object>(source: T): Partial<T> {
  return pickBy(source, Boolean) as T
}
