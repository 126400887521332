<template>
  <WebccBreadcrumb :lead="lead" :items="items" class="py-3" />
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.favorites.Breadcrumbs.'

const { t } = useI18n()

const lead = computed<BreadcrumbItem>(() => {
  return {
    target: '/',
    title: 'Home',
    tr: `${trPrefix}home`,
    content: 'Home',
  }
})

const items = computed<BreadcrumbItem[]>(() => {
  return [
    {
      target: '',
      title: t(`${trPrefix}favorites`),
      tr: `${trPrefix}favorites`,
      content: t(`${trPrefix}favorites`),
    },
  ]
})
</script>
