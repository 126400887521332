<template>
  <div class="pr-2">
    <div v-if="reviewCount" itemscope itemtype="http://schema.org/Product">
      <span itemprop="name" :content="code"></span>
      <div itemprop="aggregateRating" itemscope itemtype="http://schema.org/AggregateRating">
        <span itemprop="worstRating" content="1"></span>
        <span itemprop="bestRating" content="5"></span>
        <span itemprop="ratingCount" :content="reviewCount"></span>
        <span itemprop="ratingValue" :content="formatted"></span>
      </div>
    </div>
    <button class="w-full" :disabled="!value" @click="onClick">
      <div class="flex flex-wrap items-center justify-between min-h-6">
        <RatingHearts :rating="value" :color="value ? 'theme' : 'grey'" size="5" />
        <span v-if="rounded" class="text-thm">{{ $t(`${trPrefix}labels.${rounded}`) }}</span>
      </div>
      <div class="mt-2 flex flex-wrap items-center justify-between">
        <span class="text-start">{{ $t(`${trPrefix}review_s`, reviewCount) }}</span>
        <span v-if="formatted" class="flex-shrink-0">
          <span class="text-thm">{{ formatted }}</span> <span class="text-weak">{{ delim }} {{ max }}</span>
        </span>
      </div>
    </button>
  </div>
</template>

<script lang="ts" setup>
const trPrefix = 'www.components.views.details.Rating.'

const props = defineProps<{
  code: string
  overall: number
  reviewCount: number
}>()

const emit = defineEmits<{
  'rating-click': []
}>()

const tracking = useTracking()

const { value, rounded, formatted, delim, max } = useRating(() => props.overall)

function onClick() {
  tracking.handlers?.detail.ratingHeartsClick(props.overall)
  emit('rating-click')
}
</script>
