<template>
  <div class="h-full">
    <Colors :colors="useColors().colors" />

    <Header ref="header" :show-menu="false" :show-contact-us="false" />

    <main ref="iframewrapper" class="h-full">
      <iframe class="h-full w-full" :src="catalogueURL"></iframe>
    </main>

    <WebccModal v-if="modalOpen" @close="closeModal">
      <div
        class="my-2 inline-block transform overflow-hidden rounded-lg bg-bgr p-4 text-left align-bottom shadow-xl transition-all"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="mb-2 flex justify-end">
          <div class="flex h-10 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-bgr-200" @click="closeModal">
            <svg class="h-6 w-6 text-txt-stronger" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </div>
        <div v-for="(alert, i) in alerts" :key="i" class="mb-4 flex flex-col rounded border-2 border-thm shadow-lg last:mb-0">
          <div class="flex flex-row bg-thm py-2 pl-2">
            <WebccIcon name="site/info" class="h-6 w-6 text-bgr" />
          </div>
          <div class="flex flex-col bg-bgr p-2">
            <span class="text-thm">{{ alert.title }}</span>
            <span>{{ alert.message }}</span>
          </div>
          <div class="flex flex-row justify-center bg-bgr p-2">
            <WebccButton @click="removeAlert(i)">
              <span>{{ $t(`${trPrefix}ok`) }}</span>
            </WebccButton>
          </div>
        </div>
        <HitboxOrig
          v-for="item in accoms"
          :key="item.code"
          :language-prefix="conf.languagePrefix"
          class="mb-4 last:mb-0 xl:w-[50rem]"
          :accom="item"
          :currency="conf.currency"
        />
      </div>
    </WebccModal>
  </div>
</template>

<script setup lang="ts">
import type Header from '~/components/common/header/Header.vue'

const trPrefix = 'www.pages.catalogues.'

const { t } = useI18n()

const accoms: Ref<ResultDocument[]> = ref([])
const alerts: Ref<{ title: string; message: string }[]> = ref([])
const loading = ref(false)
const modalOpen = ref(false)
const conf = useConfdata()

const header: Ref<null | typeof Header> = ref(null)
const iframewrapper: Ref<null | HTMLElement> = ref(null)

const id = computed(() => {
  return useRoute().path.split('/')[2] as string
})
const catalogueURL = computed(() => {
  return `https://interhome.myflippingbook.ch/desktop.php?param=${id.value?.toUpperCase()}`
})

onMounted(async () => {
  window.addEventListener('message', await accommSelect)
  nextTick(function () {
    if (window.ResizeObserver) {
      let lastWidth: number | null = null
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.contentRect.width !== lastWidth) {
            lastWidth = entry.contentRect.width
            resizeIFrame()
          }
        }
      })
      resizeObserver.observe(document.body)
    }
  })
  resizeIFrame()
})

function openModal() {
  document.body.classList.add('overflow-hidden', 'lg:pr-[15px]')
  loading.value = false
  modalOpen.value = true
}

function closeModal() {
  alerts.value = []
  accoms.value = []
  document.body.classList.remove('overflow-hidden', 'lg:pr-[15px]')
  modalOpen.value = false
}

function resizeIFrame() {
  const wrapper = iframewrapper
  if (header.value && wrapper.value) {
    wrapper.value.style.height = `${window.innerHeight - header.value.$el.getBoundingClientRect().height}px`
  } else if (wrapper.value) {
    wrapper.value.style.height = `${window.innerHeight}px`
  }
}

function setAlert(message: string) {
  const alert = { title: t(`${trPrefix}alert`), message }
  alerts.value.push(alert)
}

function removeAlert(index: number) {
  alerts.value.splice(index, 1)
  if (alerts.value.length === 0 && accoms.value.length === 0) {
    closeModal()
  }
}

async function getAccommInfo(codes: string[]) {
  const params: { code: string[]; pagesize: number } = {
    code: [],
    pagesize: 0,
  }
  codes.forEach((code) => {
    if (code.match(/\w{2}\d{4}\.\d{1,4}\.\d{1,4}/)) {
      params.code.push(code)
    } else {
      setAlert(t(`${trPrefix}errors.wrongFormat`, { code }))
    }
  })
  params.pagesize = codes.length
  try {
    await useSearch().search()
    if (!useSearch().docs) {
      for (const code of codes) {
        setAlert(t(`${trPrefix}errors.notFound`, { code }))
      }
    } else {
      for (const code of codes) {
        let found = false
        for (const accom of useSearch().docs || []) {
          if (accom.code === code) {
            accoms.value.push(accom)
            found = true
            break
          }
        }
        if (!found) {
          setAlert(t(`${trPrefix}errors.notFound`, { code }))
        }
      }
    }
  } catch (error) {
    console.error(error)
    setAlert(t(`${trPrefix}errors.general`))
  } finally {
    openModal()
  }
}

async function accommSelect(event: MessageEvent) {
  loading.value = true
  if (event.origin !== 'https://interhome.myflippingbook.ch') {
    loading.value = false
    return
  }
  if (typeof event.data !== 'string') {
    loading.value = false
    return
  }

  let accommodationCodesArray
  try {
    accommodationCodesArray = JSON.parse(event.data)
  } catch (error: any) {
    setAlert(t(`${trPrefix}errors.parseError`, { error }))
    openModal()
    return
  }
  if (!Array.isArray(accommodationCodesArray)) {
    setAlert(t(`${trPrefix}errors.convertError`, { json: JSON.parse(event.data) }))
    openModal()
    return
  }
  await getAccommInfo(accommodationCodesArray)
}
</script>
