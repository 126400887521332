<template>
  <div>
    <div class="absolute left-0 top-0 z-10 h-2 w-2" @click.self.stop="useToggler().toggle('debug', { state: true })"></div>
    <div v-if="useToggler().toggles('debug').value">
      <aside class="fixed top-0 z-10 w-full bg-black/50">
        <nav class="container-fluid-lg flex">
          <button
            v-for="[id, title] in Object.entries(TABS)"
            :key="id"
            class="px-4 py-1.5 text-base font-light text-txt-weaker hover:bg-black/60 hover:text-txt-weakest"
            @click.stop="openTab(id)"
          >
            {{ title }}
          </button>

          <button
            class="ml-auto cursor-pointer self-center rounded-full bg-black/40 text-txt-weakest hover:bg-black/60"
            @click.stop="useToggler().toggle('debug', { state: false })"
          >
            <WebccIcon name="site/x" class="h-6 w-6 stroke-2 p-0.5" />
          </button>
        </nav>

        <!-- A/B -->
        <div v-if="useToggler().toggles('ab').value" class="container-fluid-lg relative">
          <div class="absolute w-full rounded-b bg-black/50 p-8 pt-12 text-thm-contrast shadow backdrop-blur-lg">
            <button
              class="absolute right-4 top-4 cursor-pointer rounded-full bg-black/40 text-txt-weakest hover:bg-black/60"
              @click.stop="useToggler().toggle('ab', { state: false, closeOthers: false })"
            >
              <WebccIcon name="site/x" class="h-6 w-6 stroke-2 p-0.5" />
            </button>
            <div class="flex flex-col lg:flex-row lg:justify-between">
              <AB />
            </div>
          </div>
        </div>

        <!-- CONF -->
        <div v-if="useToggler().toggles('conf').value" class="container-fluid-lg relative z-10">
          <div class="absolute w-full rounded-b bg-black/50 p-5 shadow backdrop-blur-lg">
            <button
              class="absolute right-4 top-4 cursor-pointer rounded-full bg-black/40 text-txt-weakest hover:bg-black/60"
              @click="useToggler().toggle('conf')"
            >
              <WebccIcon name="site/x" class="h-6 w-6 stroke-2 p-0.5" />
            </button>

            <div class="flex w-full flex-col items-center pb-5">
              <p class="text-base text-txt-weaker">domain:</p>
              <p class="text-3xl font-semibold text-txt-weakest">{{ useConfdata().domain }}</p>
            </div>
            <div class="grid w-full grid-cols-2 gap-6 pb-5 text-txt-weakest md:flex md:justify-between">
              <div
                class="flex grow basis-0 flex-col rounded-xl bg-black/5 p-4 shadow-md backdrop-blur-sm transition-all duration-300 hover:-translate-y-1 hover:shadow-xl md:flex-row md:items-center"
              >
                <div class="flex h-full w-full flex-row items-center justify-start gap-2">
                  <div class="flex h-1/2 items-center justify-center">
                    <div class="fill-text-txt-weakest flex h-8 w-8"><WebccIcon name="site/chrome" /></div>
                  </div>
                  <p class="text-sm text-txt-weaker">Browser</p>
                  <p>{{ useUseragent().useragent?.browser.name }}</p>
                </div>
              </div>
              <div
                class="flex grow basis-0 flex-col rounded-xl bg-black/5 p-4 shadow-md backdrop-blur-sm transition-all duration-300 hover:-translate-y-1 hover:shadow-xl md:flex-row md:items-center"
              >
                <div class="flex h-full w-full flex-row items-center justify-start gap-2">
                  <div class="flex h-1/2 items-center justify-center">
                    <div class="fill-text-txt-weakest flex h-8 w-8"><WebccIcon name="site/version" /></div>
                  </div>
                  <p class="text-sm text-txt-weaker">Version</p>
                  <p>{{ useUseragent().useragent?.browser.version }}</p>
                </div>
              </div>
              <div
                class="flex grow basis-0 cursor-pointer flex-col rounded-xl bg-black/5 p-4 shadow-md backdrop-blur-sm transition-all duration-300 hover:-translate-y-1 hover:shadow-xl md:flex-row md:items-center"
              >
                <div class="flex h-full w-full flex-row items-center justify-start gap-2">
                  <div class="flex h-1/2 items-center justify-center">
                    <div class="fill-text-txt-weakest flex h-8 w-8"><WebccIcon :name="useUseragent().useragent?.supported ? 'tick' : 'cross'" /></div>
                  </div>
                  <p class="text-sm text-txt-weaker">Supported</p>
                  <p></p>
                </div>
              </div>
              <div
                class="flex grow basis-0 flex-col rounded-xl bg-black/5 p-4 shadow-md backdrop-blur-sm transition-all duration-300 hover:-translate-y-1 hover:shadow-xl md:flex-row md:items-center"
              >
                <div class="flex h-full w-full flex-row items-center justify-start gap-2">
                  <div class="flex h-1/2 items-center justify-center">
                    <div class="fill-text-txt-weakest flex h-8 w-8">
                      <WebccIcon :name="useUseragent().useragent?.device ?? ''" />
                    </div>
                  </div>
                  <p class="text-sm text-txt-weaker">Device</p>
                  <p>{{ useUseragent().useragent?.device }}</p>
                </div>
              </div>
            </div>
            <div class="grid w-full grid-cols-2 gap-6 pb-5 text-txt-weakest md:flex md:justify-between">
              <div
                class="flex grow basis-0 flex-col rounded-xl bg-black/5 p-4 shadow-md backdrop-blur-sm transition-all duration-300 hover:-translate-y-1 hover:shadow-xl md:flex-row md:items-center"
              >
                <div class="flex h-full w-full flex-row items-center justify-start gap-2">
                  <pre
                    class="h-screen-50 overflow-scroll whitespace-pre-wrap break-words rounded-xl border-txt bg-black/5 p-4 text-xs text-txt-weakest shadow backdrop-blur-sm"
                    >{{ useConfdata()?.raw?.conf }}</pre
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- BODY -->
        <div v-if="useToggler().toggles('body').value" class="container-fluid-lg relative z-10">
          <div class="absolute w-full rounded-b bg-black/50 p-5 shadow backdrop-blur-lg">
            <button
              class="absolute right-4 top-4 cursor-pointer rounded-full bg-black/40 text-txt-weakest hover:bg-black/60"
              @click="useToggler().toggle('body')"
            >
              <WebccIcon name="site/x" class="h-6 w-6 stroke-2 p-0.5" />
            </button>
            <div class="grid w-full grid-cols-2 gap-6 pb-5 text-txt-weakest md:flex md:justify-between mt-8">
              <div
                class="flex grow basis-0 flex-col rounded-xl bg-black/5 p-4 shadow-md backdrop-blur-sm transition-all duration-300 hover:-translate-y-1 hover:shadow-xl md:flex-row md:items-center"
              >
                <div class="flex h-full w-full flex-row items-center justify-start gap-2">
                  <pre
                    class="h-screen-50 overflow-scroll whitespace-pre-wrap break-words rounded-xl border-txt bg-black/5 p-4 text-xs text-txt-weakest shadow backdrop-blur-sm"
                    >{{ useConfdata()?.body }}</pre
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- SETTINGS -->
        <div v-if="useToggler().toggles('settings').value" class="container-fluid-lg relative z-10">
          <div class="absolute w-full rounded-b bg-black/50 p-5 shadow backdrop-blur-lg">
            <button
              class="absolute right-4 top-4 cursor-pointer rounded-full bg-black/40 text-txt-weakest hover:bg-black/60"
              @click="useToggler().toggle('settings')"
            >
              <WebccIcon name="site/x" class="h-6 w-6 stroke-2 p-0.5" />
            </button>
            <div class="grid w-full grid-cols-2 gap-6 pb-5 text-txt-weakest md:flex md:justify-between mt-8">
              <div
                class="flex grow flex-wrap gap-y-2 basis-0 flex-col rounded-xl bg-black/5 p-4 shadow-md backdrop-blur-sm transition-all duration-300 hover:-translate-y-1 hover:shadow-xl md:flex-row md:items-center"
              >
                <span v-for="[key, value] of Object.entries(useSettings()).filter(([, value]) => typeof value === 'boolean')" :key="key">
                  <label
                    class="flex items-center justify-start gap-2 rounded-xl bg-black/5 px-3 py-2 shadow-md backdrop-blur-sm hover:shadow-xl hover:backdrop-blur-lg"
                  >
                    <WebccSwitch :model-value="value" :name="key" @update:model-value="useSettings().set(key, !value)" />
                    <div>
                      <p class="text-sm font-semibold text-white">{{ key }}</p>
                    </div>
                  </label>
                </span>
              </div>
            </div>

            <div class="grid w-full grid-cols-2 gap-6 pb-5 text-txt-weakest md:flex md:justify-between">
              <div
                class="flex grow basis-0 flex-col rounded-xl bg-black/5 p-4 shadow-md backdrop-blur-sm transition-all duration-300 hover:-translate-y-1 hover:shadow-xl md:flex-row md:items-center"
              >
                <div class="flex h-full flex-row items-center justify-start gap-2">
                  <pre
                    class="h-screen-50 overflow-scroll whitespace-pre-wrap break-words rounded-xl border-txt bg-black/5 p-4 text-xs text-txt-weakest shadow backdrop-blur-sm"
                    >{{ useRuntimeConfig() }}</pre
                  >
                </div>
              </div>
              <div
                class="flex grow basis-0 flex-col rounded-xl bg-black/5 p-4 shadow-md backdrop-blur-sm transition-all duration-300 hover:-translate-y-1 hover:shadow-xl md:flex-row md:items-center"
              >
                <div class="flex h-full flex-row items-center justify-start gap-2">
                  <pre
                    class="h-screen-50 whitespace-pre-wrap break-words rounded-xl border-txt bg-black/5 p-4 text-xs text-txt-weakest shadow backdrop-blur-sm"
                    >{{ useSettings().all }}</pre
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- COLORS -->
        <div v-if="useToggler().toggles('colors').value" class="container-fluid-lg relative top-1 z-10">
          <div
            class="absolute left-0 right-0 flex max-h-[90vh] flex-row justify-between gap-12 overflow-auto rounded-b bg-white/50 p-4 py-12 shadow backdrop-blur-lg"
          >
            <div class="flex flex-col gap-2">
              <div v-for="([key, value], i) in Object.entries(colors.text)" :key="i" class="flex w-min flex-row gap-2">
                <span class="w-32">{{ key }}</span>
                <input type="color" :value="value" class="w-16 cursor-pointer border border-black" />
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <div v-for="([key, value], i) in Object.entries(colors.background)" :key="i" class="flex w-min flex-row gap-2">
                <span class="w-32">{{ key }}</span>
                <input type="color" :value="value" class="w-16 cursor-pointer border border-black" />
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <div v-for="([key, value], i) in Object.entries(colors.theme)" :key="i" class="flex w-min flex-row gap-2">
                <span class="w-32">{{ key }}</span>
                <input type="color" :value="value" class="w-16 cursor-pointer border border-black" />
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <div v-for="([key, value], i) in Object.entries(colors.cta)" :key="i" class="flex w-min flex-row gap-2">
                <span class="w-32">{{ key }}</span>
                <input type="color" :value="value" class="w-16 cursor-pointer border border-black" />
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <div v-for="([key, value], i) in Object.entries(colors.highlight)" :key="i" class="flex w-min flex-row gap-2">
                <span class="w-32">{{ key }}</span>
                <input type="color" :value="value" class="w-16 cursor-pointer border border-black" />
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <div v-for="([key, value], i) in Object.entries(colors.misc)" :key="i" class="flex w-min flex-row gap-2">
                <span class="w-32">{{ key }}</span>
                <input type="color" :value="value" class="w-16 cursor-pointer border border-black" />
              </div>
            </div>
          </div>
          <button class="absolute right-4 top-4 cursor-pointer rounded-full bg-black/40 text-white hover:bg-black/60" @click="useToggler().toggle('colors')">
            <WebccIcon name="site/x" class="h-6 w-6 stroke-2 p-0.5" />
          </button>
        </div>

        <!-- SEARCH -->
        <section v-if="useToggler().toggles('search').value" class="container-fluid-lg relative z-10">
          <div class="absolute left-0 right-0 rounded-b bg-black/50 p-4 shadow backdrop-blur-lg">
            <section>
              <button
                class="absolute right-4 top-4 cursor-pointer rounded-full bg-black/40 text-txt-weakest hover:bg-black/60"
                @click.stop="useToggler().toggle('search', { state: false, closeOthers: false })"
              >
                <WebccIcon name="site/x" class="h-6 w-6 stroke-2 p-0.5" />
              </button>
              <span class="block font-semibold text-lg text-txt-weakest mt-2 mb-2">Autosuggest</span>
              <div class="bg-white p-4">
                Input Threshold
                <WebccRadio
                  :value="useSettings().autosuggestThreshold"
                  :options="[
                    { value: 2, label: 'minimum 2 characters' },
                    { value: 3, label: 'minimum 3 characters' },
                  ]"
                  size="sm"
                  name="autosuggestThreshold"
                  class="[&>label]:p-2"
                  @change="useSettings().autosuggestThreshold = $event as number"
                />
                Search Type
                <WebccRadio
                  :value="useSettings().autosuggestSearchtype"
                  :options="[
                    { value: 'search_as_you_type', label: 'Search as you type' },
                    { value: 'completion', label: 'Completion' },
                  ]"
                  size="sm"
                  name="autosuggestSearchtype"
                  @change="useSettings().autosuggestSearchtype = $event.toString()"
                />
                Size
                <WebccRadio
                  :value="useSettings().autosuggestSize"
                  :options="[
                    { value: 10, label: '10 suggestions' },
                    { value: 20, label: '20 suggestions' },
                    { value: 50, label: '50 suggestions' },
                    { value: 100, label: '100 suggestions' },
                  ]"
                  size="sm"
                  name="autosuggestSize"
                  @change="useSettings().autosuggestSize = $event as number"
                />
                Source (wirkt sich nur bei "Search as you type" aus)
                <WebccRadio
                  :value="useSettings().autosuggestSource"
                  :options="[
                    { value: '', label: 'Keine Einschränkung' },
                    { value: 'focus', label: 'Focus' },
                  ]"
                  size="sm"
                  name="autosuggestSource"
                  @change="useSettings().autosuggestSource = $event.toString()"
                />
              </div>
            </section>
            <section>
              <span class="block font-semibold text-lg text-txt-weakest mt-2 mb-2">Mapsearch</span>
              <div class="bg-white p-4">
                <WebccCheckbox
                  :value="useSettings().mapShowMapInfo"
                  label="Zeige Mapinfo"
                  class="mr-2"
                  name="mapShowMapInfo"
                  @update:model-value="useSettings().mapShowMapInfo = $event"
                />
                <WebccCheckbox
                  :value="useSettings().mapShowBounds"
                  label="Anfragebounds / map (gelb) und Ergebnisbounds / viewport (rot)"
                  class="mr-2"
                  name="mapShowBounds"
                  @update:model-value="useSettings().mapShowBounds = $event"
                />
                <WebccCheckbox
                  :value="useSettings().mapShowProposal"
                  label="Zeige Destination-Proposal"
                  class="mr-2"
                  name="mapShowProposal"
                  @update:model-value="useSettings().mapShowProposal = $event"
                />

                De-Clustering-Method
                <WebccRadio
                  :value="useSettings().mapDeClustering"
                  :options="[
                    { value: 'accosum', label: 'Summe Accos in Buckets <= 20 und Mapzoom > 12' },
                    { value: 'none', label: 'Coords-Cardinality in Buckets' },
                  ]"
                  size="sm"
                  name="mapShowProposal"
                  @change="useSettings().mapDeClustering = $event.toString()"
                />
                Additional Bucket-Zoom
                <WebccRadio
                  :value="useSettings().mapAdditionalBucketZoom"
                  :options="[
                    { value: 0, label: 'Display-Zoom der Karte' },
                    { value: 1, label: 'Display-Zoom + 1' },
                    { value: 2, label: 'Display-Zoom + 2' },
                  ]"
                  size="sm"
                  name="mapAdditionalBucketZoom"
                  @change="useSettings().mapAdditionalBucketZoom = $event as number"
                />
              </div>
            </section>
          </div>
        </section>
      </aside>
    </div>
  </div>
</template>

<script setup lang="ts">
const TABS = {
  ab: 'AB',
  conf: 'Conf',
  body: 'Body',
  settings: 'Settings',
  colors: 'Colors',
  search: 'New Search Settings',
}

onBeforeMount(() => {
  useToggler().register('debug', { state: useSettings().debug, ignoreDocumentClick: true })
  Object.keys(TABS).forEach((id) => useToggler().register(id, { ignoreDocumentClick: true }))
})

function openTab(id: string) {
  useToggler().toggle(id, { closeOthers: true })
  useToggler().toggle('debug', { closeOthers: false })
}

const colors = computed(() => {
  const colors = {
    text: Object.fromEntries(Object.entries(useColors().colors).filter(([color, _]) => color.startsWith('text'))),
    background: Object.fromEntries(Object.entries(useColors().colors).filter(([color, _]) => color.startsWith('background'))),
    theme: Object.fromEntries(Object.entries(useColors().colors).filter(([color, _]) => color.startsWith('theme'))),
    cta: Object.fromEntries(Object.entries(useColors().colors).filter(([color, _]) => color.startsWith('cta'))),
    highlight: Object.fromEntries(Object.entries(useColors().colors).filter(([color, _]) => color.startsWith('highlight'))),
    misc: Object.fromEntries(
      Object.entries(useColors().colors).filter(
        ([color, _]) =>
          !color.startsWith('text') &&
          !color.startsWith('background') &&
          !color.startsWith('theme') &&
          !color.startsWith('cta') &&
          !color.startsWith('highlight'),
      ),
    ),
  }
  return colors
})
</script>
