<template>
  <div id="ab" class="w-full grid gap-4 sm:grid-cols-2 md:grid-cols-3 max-h-[90vH] overflow-y-auto">
    <label
      v-for="object in useAB().all"
      :key="object.key"
      class="flex items-center justify-start gap-2 rounded-xl bg-black/5 px-3 py-2 shadow-md backdrop-blur-sm hover:shadow-xl hover:backdrop-blur-lg"
    >
      <WebccSwitch :model-value="object.active || false" :name="object.name" @update:model-value="useAB().toggle(object.key, $event)" />
      <!-- TODO: activate select variant
             check {{ typeof object.value }} ???
      -->

      <!--
      <Select
        v-else
        size="sm"
        class="min-w-1/4 w-fit text-black"
        :value="object.value"
        :options="Object.keys(object.variants)"
        @update="$ab(object.key, $event)"
      />
      -->
      <div>
        <p class="text-sm font-semibold text-white">{{ object.name }}</p>
        <p class="text-gray-300 text-xs">key: {{ object.key }} - {{ object.description }}</p>
      </div>
    </label>
  </div>
</template>

<script setup lang="ts"></script>
