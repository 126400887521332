<template>
  <main class="flex w-full flex-col items-center bg-bgr-100">
    <h1 class="mb-4 mt-4 text-center text-3xl font-normal leading-normal text-thm">{{ $t('errors.title') }}</h1>
    <article class="mx-2 my-4 w-full max-w-screen-md rounded bg-bgr p-4 text-txt shadow-md md:w-full">
      <h4 class="text-xl text-err">{{ $t('errors.messages.notFound') }}</h4>
    </article>
    <FooterNewsletterSignup />
  </main>
</template>

<script setup lang="ts"></script>
