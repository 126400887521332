<template>
  <section class="container-fluid-lg mb-14">
    <header class="mb-8 flex justify-between space-x-3">
      <WebccHeading :title="title" :level="2" :space="'mt-0'" class="text-txt-stronger font-medium" />
      <WebccLink target="_blank" anchor :to="link.url" @click="onReadMoreClick">
        <WebccButton outline variant="theme">
          <span>{{ $t(`${trPrefix}showMore`) }}</span>
        </WebccButton>
      </WebccLink>
    </header>
    <div class="grid grid-cols-1 gap-6 md:grid-cols-2">
      <SearchSightsAndContentItem v-for="(sight, index) in sightsToShow" :key="index" :model-value="sight" class="block rounded-lg shadow sm:flex" />
    </div>
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.search.content.SightsAndContent.'

const props = defineProps<{
  modelValue: SightsInfo
}>()

const { title, link, sights } = props.modelValue

const maxSights = computed(() => (useConfdata().destination.place ? 4 : 3))
const sightsToShow = computed(() => sights.slice(0, maxSights.value))

function onReadMoreClick() {
  useTracking().handlers?.search.requestTravelguide()
}
</script>
