<template>
  <WebccDateRangepicker
    :key="useFlexTrip().updateKey"
    v-model="useFlexTrip().range"
    v-on-click-outside.bubble="onClickOutside"
    class="border border-bgr-300 rounded-t-[.5rem] rounded-b-[.75rem] shadow-lg"
    :locale="useL10N().locale"
    :min-length="DURATION_MIN"
    :max-length="DURATION_MAX"
    @select-start="trackArrival"
    @update:model-value="trackDeparture"
  />
</template>

<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components'

const emit = defineEmits<{ close: [] }>()

const tracking = useTracking()

function trackArrival(arrival?: Date) {
  if (!arrival) return

  tracking.handlers?.search.datesArrivalSelect(arrival)
  tracking.handlers?.homepage.datesArrivalSelect(arrival)
}

function trackDeparture({ start, end }: DateRange) {
  if (!start || !end) return

  tracking.handlers?.search.datesDurationSelect(calculateDuration(start, end))
  tracking.handlers?.homepage.datesDurationSelect(calculateDuration(start, end))
}

function onClickOutside() {
  emit('close')
}
</script>
