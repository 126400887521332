<template>
  <Breadcrumb :lead="lead" :items="items" :trail="trail" />
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    accom: TranslatedAccommodation
    languagePrefix?: string
  }>(),
  {
    languagePrefix: '',
  },
)

const lead = computed<BreadcrumbItem>(() => {
  return {
    target: `/`,
    title: 'Home',
    tr: 'www.components.views.details.Breadcrumbs.home',
    content: 'Home',
  }
})

const items = computed<BreadcrumbItem[]>(() => {
  return [
    {
      target: `${props.languagePrefix}/${country.value.slug}`,
      title: country.value.name,
      text: country.value.name,
      content: country.value.name,
    },
    {
      target: `${props.languagePrefix}/${region.value.slug}`,
      title: region.value.name,
      text: region.value.name,
      content: region.value.name,
    },
    {
      target: `${props.languagePrefix}/${place.value.slug}`,
      title: place.value.name,
      text: place.value.name,
      content: place.value.name,
    },
  ]
})

const trail = computed<BreadcrumbItem>(() => {
  return {
    target: `${props.languagePrefix}/${props.accom.slug}`,
    title: props.accom.code,
    text: props.accom.code,
    content: props.accom.code,
  }
})

const country = computed(() => {
  return props.accom.country
})

const region = computed(() => {
  return props.accom.region
})

const place = computed(() => {
  return props.accom.place
})
</script>
