export function getAccommodationDestination({ country, region, place }: TranslatedAccommodation): Destination {
  return {
    countryCode: country.code,
    country: country.name,
    regionCode: region.code,
    region: region.name,
    placeCode: place.code,
    place: place.name,
    // TODO: Add `regionCodeFull` and `placeCodeFull`
  } as Destination
}
