const TYPES_ORDER = ['C', 'R', 'P', 'H', 'A', 'B', 'G']

export function getAutosuggestItems(items: Suggestion[]): AutosuggestItem[] {
  return items
    .filter(({ type }) => TYPES_ORDER.includes(type))
    .toSorted((a, b) => TYPES_ORDER.indexOf(a.type) - TYPES_ORDER.indexOf(b.type))
    .flatMap((item) => [item, ...(item.type === 'C' ? (item.topRegions ?? []) : [])])
    .map(({ country, slug, ...item }) => ({
      ...item,
      flag: country.toLowerCase(),
      path: slug,
      selected: false,
    }))
}
