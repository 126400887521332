<template>
  <div v-if="show" class="flex flex-col rounded border-2 border-thm shadow-lg">
    <div class="flex flex-row bg-thm py-2 pl-2">
      <WebccIcon name="site/info" class="h-6 w-6 text-bgr" />
    </div>
    <div class="flex flex-col bg-bgr p-2">
      <span class="text-thm">{{ $t(`${trPrefix}heading`) }}</span>
      <span>{{ $t(`${trPrefix}info`) }}</span>
    </div>
    <div class="flex flex-row justify-center bg-bgr p-2">
      <WebccButton @click="$emit('hide')">
        <span>{{ $t(`${trPrefix}ok`) }}</span>
      </WebccButton>
    </div>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.favorites.Infobox.'

defineProps<{
  show?: boolean
}>()

defineEmits<{ (e: 'hide'): void }>()
</script>
