<template>
  <div class="relative items-center">
    <!-- User Button -->
    <div class="flex items-center cursor-pointer transition-all hover:opacity-90 active:scale-95 duration-300" @click.stop="toggleMenu">
      <div class="size-10 rounded-full overflow-hidden bg-thm flex items-center justify-center">
        <img v-if="profileImg" :src="'data:image/png;base64,' + profileImg" :alt="title" />
        <WebccIcon v-else name="account/user" class="size-6 text-bgr" />
      </div>
    </div>

    <!-- Desktop Menu -->
    <Transition name="bounce">
      <div
        v-if="!mobile && open"
        v-on-click-outside.bubble="closeMenu"
        class="absolute z-5 right-0 bottom-[-0.5rem] bg-bgr border rounded shadow-md translate-y-full flex flex-col overflow-hidden"
      >
        <div v-if="signedIn" class="flex gap-4 px-3 py-4">
          <div class="size-11 rounded-full overflow-hidden bg-thm flex items-center justify-center">
            <img v-if="profileImg" :src="'data:image/png;base64,' + profileImg" :alt="title" />
            <span v-else-if="initials" class="text-lg lg:text-2xl text-bgr">{{ initials }}</span>
            <WebccIcon v-else name="account/user" class="size-6 text-bgr" />
          </div>
          <div class="flex flex-col">
            <span class="font-medium">{{ title }}</span>
            <span class="text-sm text-txt-weak">{{ email }}</span>
          </div>
        </div>
        <div class="flex flex-col border-y">
          <button
            v-for="{ key, action, classes } in actions"
            :key="key"
            class="px-3 py-4 cursor-pointer hover:bg-bgr-100 text-left text-nowrap"
            :class="classes"
            @click="menuItemClick(action)"
          >
            <span>{{ $t(key) }}</span>
          </button>
        </div>
      </div>
    </Transition>

    <!-- Mobile Menu -->
    <ClientOnly>
      <DrawerRoot v-if="mobile" v-model:open="open">
        <DrawerPortal>
          <DrawerOverlay class="fixed bg-black/40 inset-0" />
          <DrawerContent aria-describedby="undefined" class="bg-bgr flex justify-center rounded-t-lg h-full max-h-[90%] fixed bottom-0 left-0 right-0">
            <DrawerTitle />
            <div class="max-w-xl w-full">
              <div class="w-full flex justify-center pt-2.5">
                <WebccIcon name="site/chevron-down" filled class="size-8 inline text-txt-weaker" />
              </div>
              <div class="p-4 flex flex-col">
                <UserCard v-if="signedIn" />
                <div class="flex flex-col border-b mt-4">
                  <button
                    v-for="{ key, action, classes } in actions"
                    :key="key"
                    class="px-3 py-4 cursor-pointer hover:bg-bgr-100 text-left text-nowrap"
                    :class="classes"
                    @click="menuItemClick(action)"
                  >
                    <span>{{ $t(key) }}</span>
                  </button>
                </div>
              </div>
            </div>
          </DrawerContent>
        </DrawerPortal>
      </DrawerRoot>
    </ClientOnly>

    <Teleport to="body">
      <WebccLoaderOverlay v-if="useAuth().pending" />
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components'
import { DrawerContent, DrawerOverlay, DrawerPortal, DrawerRoot, DrawerTitle } from 'vaul-vue'

const trPrefix = 'www.components.site.Header.user.'

const { t } = useI18n()

const open = ref(false)

const { signedIn, email, name, initials, profileImg } = storeToRefs(useUser())

const title = computed(() => name.value || t(`${trPrefix}name`))
const mobile = computed(() => useScreen().isSmall)

const actions = computed<{ key: TranslationKey; action: Function; classes?: string }[]>(() => [
  { key: `${trPrefix}account`, action: toProfile },
  { key: `${trPrefix}bookings`, action: toBookings },
  { key: `${trPrefix}logout`, action: logout, classes: 'text-err' },
])

// Registering a dummy hook to avoid the following warnings when resizing the screen:
// "[Vue warn]: onMounted is called when there is no active component instance to be associated with."
watch(mobile, () => null)

function toggleMenu() {
  open.value = !open.value
}

function closeMenu() {
  open.value = false
}

async function menuItemClick(fn: Function) {
  closeMenu()
  return await fn()
}

async function toProfile() {
  await navigateTo(useURLs().buildProfileUrl(), { external: true })
}

async function toBookings() {
  await navigateTo(useURLs().buildBookingsUrl(), { external: true })
}

async function logout() {
  await useAuth().logout()
}
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in 0.2s;
}

.bounce-leave-active {
  animation: bounce-in 0.2s reverse;
}

@keyframes bounce-in {
  0% {
    transform: translate(5%, 95%) scale(90%);
    opacity: 0;
  }
  100% {
    transform: translate(0, 100%) scale(100%);
    opacity: 1;
  }
}
</style>
