/**
 * A unique id that Interhome uses to identify partners.
 * Affects how partners pay commission.
 *
 * Format:
 * - starts with 2 letters followed by up to 8 numbers
 * - zeros after 2 letters can be omitted
 * - total length up to 10 characters
 *
 * @example ES50399, ES0050399, CH209999, IC1000099.
 *
 * @see {@link https://partners.interhome.group/wiki/:faq:general:names | Interhome codes, names, definitions}
 * @see {@link https://partners.interhome.group/wiki/faq:links:general | Linking to Interhome }
 */
export type PartnerId = string

const PARTNER_ID_REGEXP = /(?<partnerId>[A-Z]{2}[0-9]{1,8})([^0-9]|$)/

/**
 * Extracts a valid {@link PartnerId} from a string, if possible.
 *
 * Takes up to the first 10 characters.
 * If `partnerid` is longer than 10 characters, the `/webcc-api/v1/booking/checkPrice/final` request will fail.
 * On the details page this will result in the booking becoming impossible.
 *
 * @see https://jira.migros.net/browse/IHGWEBCC-643
 * @see https://jira.migros.net/browse/IHGWEBCC-1353
 */
export function getPartnerId(input: unknown): PartnerId | undefined {
  return input?.toString().match(PARTNER_ID_REGEXP)?.groups?.partnerId.toUpperCase()
}
