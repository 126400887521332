<template>
  <!-- To avoid warning "Component is missing template or render function." -->
  <div></div>
</template>

<script setup lang="ts">
type BrandingType = 'origBrand' | 'noBrand' | 'BOTfriendsBrand'

const trPrefix = 'www.components.common.Chatbot.'

const { t } = useI18n()

const Smooch = (await import('smooch')).default

const branding: BrandingType = 'noBrand'
const { locale } = useL10N()
const { company, domain, links } = useConfdata()
const name = getCompanyName(company)
const iconPublicUrl = getPublicUrl(domain, getCompanyIcon(company))
const privacyPolicyUrl = links?.privacyPolicy?.href ?? ''
const { colors } = useColors()
const [themeColor, ctaColor] = [colors.theme, colors.cta].map((color) => color.substring(1))

const isDetailPage = computed(() => useConfdata().pagetype === 'detail')

const bottom = computed(() => {
  if (!isDetailPage.value) return 20
  if (useScreen().isExtraSmall && window.innerWidth < 370) return 121
  if (useScreen().isExtraSmall) return 101
  if (useScreen().isSmall) return 91
  return 20
})

const right = computed(() => {
  if (!isDetailPage.value) return 14
  if (useScreen().isSmall) return 0
  return 14
})

const maxHeight = computed(() => {
  return window.innerHeight - bottom.value
})

watch(isDetailPage, updatePosition)
watch(() => useScreen().current, updatePosition)

onMounted(async () => {
  /**
   * @see {@link https://github.com/zendesk/sunshine-conversations-web?tab=readme-ov-file#initoptions | Smooch init options}
   *
   * Unfortunately there is probably no equivalent singular/plural for `messageRelativeTimeMinute`
   * conversationListRelativeTimeMinute: 'a minute ago X',
   * conversationListRelativeTimeMinutes: '{value} minutes ago Y',
   */
  await Smooch.init({
    businessName: name,
    businessIconUrl: iconPublicUrl,
    integrationId: '633424105e90dc00f3506eab',
    region: 'eu-1',
    locale,
    customText: {
      headerText: t(`${trPrefix}header`),
      introductionText: t(`${trPrefix}introduction`, { link: privacyPolicyUrl }),
      conversationTimestampHeaderFormat: 'DD. MMMM YYYY',
      inputPlaceholder: t(`${trPrefix}placeholder`),
      messageSending: t(`${trPrefix}sending`),
      messageDelivered: t(`${trPrefix}delivered`),
      messageRelativeTimeDay: t(`${trPrefix}days`),
      messageRelativeTimeHour: t(`${trPrefix}hours`),
      messageRelativeTimeMinute: t(`${trPrefix}minutes`),
      messageRelativeTimeJustNow: t(`${trPrefix}now`),
      sendButtonText: t(`${trPrefix}send`),
    },
    customColors: {
      brandColor: themeColor,
      actionColor: ctaColor,
      conversationColor: themeColor,
    },
    delegate: {
      beforeDisplay(message: Smooch.Message) {
        if (message.role === 'appMaker') {
          message.shouldShowAvatar = true
          message.avatarUrl = iconPublicUrl
        }
        return message
      },
      beforeSend(message: Smooch.Message) {
        message.metadata = { brand: name }
        return message
      },
    },
  })

  Smooch.on('widget:opened', () => {
    if (Smooch.getConversation()?.messages?.length) return
    Smooch.startConversation()
  })

  setBranding()
  updatePosition()
})

onBeforeUnmount(() => {
  Smooch.destroy()
})

function updatePosition(): void {
  const { style } = getMessenger() ?? {}
  style?.setProperty('max-height', `${maxHeight.value}px`)
  style?.setProperty('bottom', `${bottom.value}px`)
  style?.setProperty('right', `${right.value}px`)
}

function setBranding() {
  if (branding !== 'origBrand') removeBranding()
  if (branding === 'BOTfriendsBrand') addBOTfriendsBranding()
}

function removeBranding() {
  const [logo] = getMessageContainer()?.getElementsByClassName('logo') ?? []
  logo?.parentNode?.removeChild(logo)
}

function addBOTfriendsBranding() {
  const image = document.createElement('img')
  image.src = 'https://www.cdn.botfriendsx.com/botfriends_logo.png'
  image.style.cssText = 'display: block; margin-left: auto; margin-right: auto; width: 164px; height: 20px'

  const div = document.createElement('div')
  div.classList.add('logo')
  div.style.cssText = 'padding-bottom: 10px'
  div.appendChild(image)

  getMessageContainer()?.appendChild(div)
}

function getMessenger(): HTMLIFrameElement | undefined {
  return document.querySelectorAll<HTMLIFrameElement>('#web-messenger-container')[0]
}

function getMessageContainer(): Element | undefined {
  return getMessenger()?.contentWindow?.document.getElementsByClassName('messages-container')[0]
}
</script>
