<template>
  <div
    v-on-click-outside="reset"
    class="flex flex-col outline-none"
    :class="displayCentered ? 'items-center' : ''"
    tabindex="-1"
    @mouseleave="hoveredDayLabel = ''"
  >
    <v-date-picker
      ref="calendar"
      :key="currentRangeKey"
      v-model.range="range"
      :initial-page="initialPage"
      :initial-page-position="initialPagePosition"
      :class="useAB().isActive('dpTooltip') && showTooltip && vacancies ? '' : '[&_.vc-day-popover-container]:!hidden'"
      :rows="1"
      :columns="$screen.isExtraSmall ? 1 : 2"
      :step="1"
      transition="slide-h"
      :min-page="yearStart"
      :max-page="yearEnd"
      :min-date="minDate"
      :max-date="maxDate"
      :attributes="vcAttributes"
      :locale="locale"
      @dayclick="onDayClick"
      @daymouseenter="onDayHover"
      @daymouseleave="hoveredDayLabel = ''"
      @dayfocusin="onFocusDate"
      @keydown.space.capture="cancelDayPress"
      @keydown.enter="onDayPress"
      @update:pages="handlePageUpdate"
    >
      <template #day-popover="{ day }">
        <div v-if="useAB().isActive('dpTooltip') && vacancies && showTooltip && day.attributes[0].customData">
          {{ day.attributes[0].customData.label }}
        </div>
      </template>
    </v-date-picker>
    <div
      v-if="!useAB().isActive('dpTooltip') && vacancies?.dateFrom"
      :class="hoveredDayLabel ? 'visible' : 'invisible'"
      class="bg-thm text-txt-contrast mx-1 min-w-full py-2 px-4 font-semibold"
    >
      {{ hoveredDayLabel }} &nbsp;
    </div>
  </div>
</template>

<script setup lang="ts">
import type { AttributeConfig } from 'v-calendar/dist/types/src/utils/attribute.d.ts'
import type { CalendarDay, Page } from 'v-calendar/dist/types/src/utils/page.d.ts'
import { vOnClickOutside } from '@vueuse/components'

const trPrefix = 'www.components.views.details.Calendar.'

const { t } = useI18n()

const calendar: Ref<any | null> = ref(null)

const props = withDefaults(
  defineProps<{
    modelValue?: DateRange
    vacancies?: Trips
    minLength?: number
    maxLength?: number
    locale?: string
    columns?: number
    displayCentered?: boolean
    flow?: ('month' | 'year' | 'calendar' | 'time' | 'minutes' | 'hours' | 'seconds')[]
  }>(),
  {
    modelValue: () => ({}),
    vacancies: () => ({ dateTo: null, dateFrom: null, days: [] }) as unknown as Trips,
    locale: () => 'en',
    minLength: () => 0,
    maxLength: () => 28,
    columns: () => 0,
    displayCentered: () => true,
    flow: () => ['year', 'month', 'calendar'],
  },
)

const emit = defineEmits<{
  'update:modelValue': [DateRange]
  selectStart: [Date?]
}>()

const firstPossibleArrival = daysAdd(new Date(), 1)
const selectedDay = ref(null as unknown as VacancyDay)
const selected = ref(null as unknown as CalendarDay)
const hoveredDay = ref(null as unknown as Date)
const hoveredDayLabel = ref('')
const focusedDate = ref(null as unknown as CalendarDay)
const min = ref(null as unknown as Date)
const max = ref(null as unknown as Date)
const showTooltip = ref(true)

const initialPage = props.modelValue.start ? new Date(props.modelValue.start) : new Date()
let initialPagePosition = 1
let rightColumnMonth = 1
let lastMonthClicked = 0
const fromPage = ref(toDate(initialPage))
const toPage = ref(toDate(monthsAdd(fromPage.value, 1)))
const lastPage = ref('')

const currentYear = new Date().getFullYear()
const yearStart = new Date(currentYear, 0, 1)
const yearEnd = new Date(currentYear, 11, 31)

const range = ref(isDateRangeValid(props.modelValue) ? props.modelValue : null)

watch(
  () => props.modelValue,
  (newValue) => (range.value = isDateRangeValid(newValue) ? newValue : null),
)

/**
 * Define min date limit
 */
const minDate = computed({
  get() {
    return tryParseDate(props.vacancies?.dateFrom) ?? min.value ?? firstPossibleArrival
  },
  set(minDate) {
    min.value = minDate
  },
})

/**
 * Define max date limit
 */
const maxDate = computed({
  get() {
    return tryParseDate(props.vacancies?.dateTo) ?? max.value ?? undefined
  },
  set(maxDate) {
    max.value = maxDate
  },
})

/**
 * Unique key for current range, an update re-renders calendar
 */
const currentRangeKey = computed(() => {
  return JSON.stringify(range?.value)
})

/**
 * Compile list of day attributes based on whether day selected or not
 * When selected - departure and range days attributes provided, otherwise - all of them
 */
const vcAttributes = computed(() => {
  if (!selectedDay.value) return attributesNotSelected.value
  return [...attributeDeparture.value, ...attributesRange.value]
})

/**
 * Define day attributes, for case when no day selected
 */
const attributesNotSelected = computed(() => {
  if (selectedDay.value) return []

  const days = props.vacancies.days || []

  const rangeDates = range.value ? getDateRangeEachDay(range.value) : []
  const startDay = rangeDates.length ? days.find(({ date }) => datesEqual(date, rangeDates[0])) : undefined

  const attributes: AttributeConfig[] = days
    .filter(({ date }) => diff(date, fromPage.value) >= 0 && diff(date, toPage.value) <= 0)
    .map((day) => {
      let label = t(`${trPrefix}notAvailable`)
      let classes = '!h-14 !w-14 !border-0 !cursor-default !bg-transparent ns-not-available'
      let contentClasses = 'opacity-30 !cursor-default !line-through'
      if (day.isTripStart && day.isTripEnd) {
        label = t(`${trPrefix}arrivalDeparture_s`, day.minStay)
        classes = '!h-14 !w-14 !border-0 !cursor-pointer !bg-suc ns-arrival-departure'
        contentClasses = '!hover:border !hover:border-thm'
      } else if (day.isTripStart) {
        label = t(`${trPrefix}arrival_s`, day.minStay)
        classes = '!h-14 !w-14 !border-0 !cursor-pointer !bg-suc ns-arrival'
        contentClasses = '!hover:border !hover:border-thm'
      } else if (day.isTripInner) {
        label = t(`${trPrefix}noArrival`)
        classes = '!h-14 !w-14 !border-0 !cursor-default !bg-suc-reduced ns-no-arrival'
        contentClasses = '!cursor-default !opacity-30 !pointer-events-none'
      } else if (day.isTripEnd) {
        label = t(`${trPrefix}departureOnly`)
        classes = '!h-14 !w-14 !border-0 !cursor-default !bg-suc-reduced ns-departure-only'
        contentClasses = '!cursor-default !opacity-30 !pointer-events-none'
      }
      day.label = label

      const dateWithOffset = toLocaldate(day.date) // was add time zone offset. @todo: clarify

      if (dateBefore(dateWithOffset, new Date()) || sameDay(dateWithOffset, new Date())) {
        classes += ' !is-disabled'
      }

      return {
        popover: {
          visibility: useAB().isActive('dpTooltip') ? 'hover' : 'click',
          isInteractive: false,
          hideIndicator: true,
          label,
        },
        key: dateWithOffset.toISOString(),
        dates: [dateWithOffset],
        customData: day,
        dot: getDotValueForOverallAttributes(day, startDay, rangeDates),
        highlight: {
          contentClass: contentClasses,
          class: classes,
        },
      }
    })

  if (props.modelValue.start && props.modelValue.end) {
    const start = parse(props.modelValue.start)
    const end = parse(props.modelValue.end)
    attributes.push({
      key: 'range',
      highlight: {
        start: { fillMode: 'outline' },
        base: { fillMode: 'light' },
        end: { fillMode: 'outline' },
      },
      dates: [{ start, end }],
    })
  }

  return attributes
})

/**
 * Define day attributes where range is already selected
 */
const attributesRange = computed(() => {
  if (!props.vacancies) {
    const attributes = [] as AttributeConfig[]
    if (!selectedDay.value) {
      if (props.modelValue.start && props.modelValue.end) {
        const start = parse(props.modelValue.start)
        const end = parse(props.modelValue.end)
        attributes.push({
          key: 'range',
          highlight: {
            start: { fillMode: 'outline' },
            base: { fillMode: 'light' },
            end: { fillMode: 'outline' },
          },
          dates: [{ start: `${start}`, end: `${end}` }],
        })
      }
    } else {
      const parsedDate = parse(selectedDay.value.date)
      attributes.push({
        key: 'selection',
        highlight: {
          startEnd: { fillMode: 'outline' },
          base: { fillMode: 'light' },
        },
        popover: {
          visibility: 'click',
          hideIndicator: true,
          isInteractive: false,
        },
        dates: hoveredDay.value ? [{ start: parsedDate, end: hoveredDay.value }] : [`${parsedDate}`],
      })
    }
    return attributes
  }
  if (!selectedDay.value) {
    return []
  }

  const attributes = []
  const parsedDate = parse(selectedDay.value.date)

  attributes.push({
    key: 'selection',
    highlight: {
      startEnd: { fillMode: 'outline' },
      base: { fillMode: 'light' },
    },
    popover: {
      visibility: useAB().isActive('dpTooltip') ? 'hover' : 'click',
      hideIndicator: true,
      isInteractive: false,
    },
    dates: hoveredDay.value ? [{ start: `${parsedDate}`, end: hoveredDay.value }] : [`${parsedDate}`],
  })

  return attributes as AttributeConfig[]
})

const getDotValueForOverallAttributes = (day: VacancyDay, startDay: VacancyDay | undefined, rangeDates: Date[]): string | undefined => {
  const isSpecial = day.specials?.length
  const isInPast = inPast(day.date)
  const isInRange = rangeDates.some((date) => datesEqual(day.date, date))
  const isRangeEndsWithSpecial = !!startDay?.specials?.includes(rangeDates.length - 1)
  return (isInRange && isRangeEndsWithSpecial) || (!isInRange && isSpecial && !isInPast) ? 'red' : undefined
}

/**
 * Define day attributes for departure dates (when arrival day already selected)
 */
const attributeDeparture = computed(() => {
  if (!selectedDay.value) return []

  const attributes = (props.vacancies.days || [])
    .filter(({ date }) => diff(date, fromPage.value) >= 0 && diff(date, toPage.value) <= 0)
    .map((day) => {
      const dateDiff = diff(day.date, selectedDay.value.date)
      let label = t(`${trPrefix}departureAvailable`)
      let classes = '!h-14 !w-14 !border-0 !cursor-pointer !bg-suc'
      let contentClasses = 'departure-available'
      switch (true) {
        // Same day as arrival
        case dateDiff === 0:
          label = t(`${trPrefix}selectedArrival_s`, selectedDay.value.minStay)
          classes = '!h-14 !w-14 !border-0 !cursor-default !bg-transparent'
          contentClasses = '!cursor-default !opacity-30 selected-arrival'
          break
        // Day is in the past
        case parse(day.date).toISOString() < new Date().toISOString():
          label = t(`${trPrefix}dateInPast`)
          classes = '!h-14 !w-14 !border-0 !cursor-default !bg-transparent'
          contentClasses = '!pointer-events-none !is-unavailable !opacity-30 !line-through date-in-past'
          break
        // Day is before arrival date
        case parse(day.date).toISOString() < parse(selectedDay.value.date).toISOString():
          label = t(`${trPrefix}arrivalBeforeDeparture`)
          classes = '!h-14 !w-14 !border-0 !cursor-default !bg-transparent'
          contentClasses = '!cursor-default !opacity-30 !line-through arrival-before-departure'
          break
        // Day is before allowed minimal stay limit
        case selectedDay.value.minStay > dateDiff:
          label = t(`${trPrefix}noDepartureMinStay_s`, selectedDay.value.minStay)
          classes = '!h-14 !w-14 !border-0 !cursor-default !bg-transparent'
          contentClasses = '!cursor-default !opacity-30 !line-through no-departure-minstay'
          break
        // Day is ahead of allowed maximal stay limit
        case selectedDay.value.maxStay < dateDiff:
          label = t(`${trPrefix}noDepartureMaxStay_s`, selectedDay.value.maxStay)
          classes = '!h-14 !w-14 !border-0 !cursor-default !bg-transparent'
          contentClasses = `!cursor-default !opacity-30 !line-through no-departure-maxstay-${day.date}-${dateDiff}`
          break
        // Day isn't marked as possible departure date
        case !day.isTripEnd:
          label = t(`${trPrefix}noDeparture`)
          classes = '!h-14 !w-14 !border-0 !cursor-default !bg-transparent'
          contentClasses = '!cursor-default !opacity-30 no-departure-date'
          break
        // Stay duration isn't within allowed ones?
        case selectedDay.value.durations?.indexOf(dateDiff) === -1:
          label = t(`${trPrefix}noDeparture`)
          classes = '!h-14 !w-14 !border-0 !cursor-default !bg-transparent'
          contentClasses = '!cursor-default !opacity-30 no-matching-duration'
          break
      }
      day.label = label

      const dateWithOffset = toLocaldate(day.date) // $dates.addTimezoneOffset(day.date)
      if (dateBefore(dateWithOffset, new Date()) || sameDay(dateWithOffset, new Date())) {
        classes += ' is-disabled'
      }

      return {
        popover: {
          visibility: useAB().isActive('dpTooltip') ? 'hover' : 'click',
          isInteractive: false,
          hideIndicator: true,
          label,
        },
        key: dateWithOffset.toISOString(),
        dates: [dateWithOffset],
        customData: day,
        dot: selectedDay.value.specials?.includes(dateDiff) ? 'red' : undefined,
        highlight: {
          contentClass: contentClasses,
          class: classes,
        },
      }
    })

  return attributes as AttributeConfig[]
})

onMounted(() => {
  if (props.modelValue.start && dateBefore(props.modelValue.start, maxDate.value) && dateAfter(props.modelValue.start, minDate.value)) {
    calendar.value?.move(props.modelValue.start)
  }
})

/**
 * Resets day selection and min-max range limitation
 */
function reset(): void {
  selectedDay.value = null as unknown as VacancyDay
  maxDate.value = null as unknown as Date
  minDate.value = firstPossibleArrival
}

/**
 * Event handler for page (month) change to set boundaries for day coloring
 *
 * @param pages Page[]
 */
function handlePageUpdate(pages: Page[]) {
  rightColumnMonth = pages.length === 2 ? pages[1].month : pages[0].month

  fromPage.value = toDate(new Date(pages[0].year, pages[0].month - 1, 1))

  if (pages.length === 2) {
    if (lastPage.value === pages[1].id) return
    toPage.value = toDate(new Date(pages[1].year, pages[1].month, 0))
    lastPage.value = pages[1].id
  } else {
    toPage.value = toDate(new Date(pages[0].year, pages[0].month, 0))
    lastPage.value = pages[0].id
  }
}

/**
 * Handle day highlight with keyboard
 * @param vcDay CalendarDay
 */
function onFocusDate(vcDay: CalendarDay) {
  focusedDate.value = vcDay
  onDayHover(vcDay)
}

/**
 * Handle keypress on exact day
 * @param event PointerEvent
 */
function onDayPress(event: PointerEvent) {
  onDayClick(focusedDate.value, event)
}

function cancelDayPress(e: Event) {
  e.preventDefault()
  e.stopImmediatePropagation()
}

/**
 * Handle mouse hover on exact day
 * @param vcDay CalendarDay
 */
function onDayHover(vcDay: CalendarDay) {
  showTooltip.value = true
  if (!props.vacancies && vcDay) {
    hoveredDay.value = vcDay.date
  }

  if (!vcDay || !isSelectable(vcDay)) {
    return
  }

  hoveredDay.value = vcDay.date
  hoveredDayLabel.value = getCustomData(vcDay)?.label || ''
}

/**
 * Handle user click on exact day
 * @param vcDay CalendarDay
 * @param event PointerEvent
 */
function onDayClick(vcDay: CalendarDay, event?: PointerEvent): void {
  initialPagePosition = vcDay.month === rightColumnMonth && vcDay.month === lastMonthClicked && !useScreen().isExtraSmall ? 2 : 1
  lastMonthClicked = vcDay.month
  hoveredDayLabel.value = ''
  showTooltip.value = false
  if (!props.vacancies.days.length) {
    if (selectedDay.value) {
      if (dateBefore(vcDay.id, selectedDay.value.date)) {
        range.value = { start: tryParseDate(vcDay.id) as unknown as Date, end: tryParseDate(selectedDay.value.date) as unknown as Date }
      } else {
        range.value = { start: tryParseDate(selectedDay.value.date) as unknown as Date, end: tryParseDate(vcDay.id) as unknown as Date }
      }
      emit('update:modelValue', range.value)

      selectedDay.value = null as unknown as VacancyDay
      determineBoundaries(vcDay)
      ;(document.querySelector('.vc-highlight-content-outline') as HTMLInputElement)?.focus()
      return
    }
    selectedDay.value = { date: vcDay.id } as VacancyDay
    determineBoundaries(vcDay)
    emit('selectStart', tryParseDate(selectedDay.value.date))
    return
  }

  if (!isSelectable(vcDay)) {
    // prüfe, ob ein valider Tag geklickt wurde
    event?.stopPropagation()
    event?.preventDefault()
    if (selectedDay.value?.date === vcDay.id) {
      selectedDay.value = null as unknown as VacancyDay
    }
    return
  }

  if (selectedDay.value) {
    if (selectedDay.value.date !== vcDay.id) {
      range.value = { start: parse(selected.value.id), end: parse(vcDay.id) }
      emit('update:modelValue', range.value)
    }
    selectedDay.value = null as unknown as VacancyDay
  } else {
    selected.value = vcDay
    selectedDay.value = getCustomData(vcDay)
  }
}

/**
 * Checks whether calendar day allows interaction
 * @param vcDay CalendarDay
 */
function isSelectable(vcDay: CalendarDay): boolean {
  const day = getCustomData(vcDay)
  if (!day) {
    return false
  }
  if (selectedDay.value) {
    const dateDiff = diff(day.date, selectedDay.value.date)
    return day.isTripEnd && selectedDay.value.minStay <= dateDiff && selectedDay.value.durations?.includes(dateDiff)
  }
  return day.isTripStart
}

/**
 * Try to find customData among day attributes
 * @param vcDay CalendarDay
 */
function getCustomData(vcDay: CalendarDay & { attributes?: AttributeConfig[] }): AttributeConfig['customData'] | undefined {
  return vcDay?.attributes?.find((attr) => attr.customData)?.customData
}

function determineBoundaries(date: CalendarDay): void {
  if (maxDate.value) {
    maxDate.value = null as unknown as Date
    minDate.value = firstPossibleArrival
  } else {
    maxDate.value = daysAdd(date.id, props.maxLength)
    minDate.value = props.minLength ? daysAdd(date.id, props.minLength) : daysSubWithLimit(date.id, props.maxLength, firstPossibleArrival)
  }
}
</script>

<style lang="postcss" scoped>
:deep(.vc-container) {
  /* // v-calendar root element */
  @apply !border-0;
}

:deep(.vc-grid-container),
:deep(.vc-weeks) {
  /* monats-panel */
  @apply !gap-px;
}

:deep(.is-disabled) {
  @apply pointer-events-none !cursor-default line-through;
}

:deep(.vc-day) {
  @apply !z-[0];
  border: 1px transparent solid !important;
}

:deep(.vc-highlight) {
  pointer-events: none;
}

/* // Hack für Specials: biege dots um */
:deep(.vc-day-box-center-bottom) {
  @apply !items-start !justify-end;
}

:deep(.vc-dot:before) {
  @apply bg-hlt-hover text-hlt-contrast absolute -right-px -top-px flex h-4 w-4 items-center justify-center rounded-[50%] p-1 text-xs content-['%'];
}

:deep(.is-disabled),
:deep(.vc-disabled) {
  pointer-events: none;
}

:deep(.\!line-through),
:deep([class^='no-departure']),
:deep([class*=' no-departure']) {
  pointer-events: none;
}

:deep(.vc-day-popover-header) {
  @apply hidden;
}

:deep(.vc-highlight-bg-solid.bg-suc),
:deep(.vc-highlight-bg-light.bg-suc) {
  @apply !bg-suc;
  pointer-events: initial;
}

:deep(.vc-highlight-bg-solid.bg-transparent),
:deep(.vc-highlight-bg-light.bg-transparent) {
  @apply !bg-transparent;
}

:deep(.vc-highlight-bg-solid),
:deep(.vc-highlight-content-solid) {
  @apply !text-txt-strong;
}

:deep(.vc-monthly .is-not-in-month *) {
  @apply !opacity-0;
}

:deep(.vc-popover-content) {
  @apply !hidden;
}

:deep(.vc-day-box-center-center) {
  border: 1px solid white;
}

:deep([data-popper-placement='bottom'] > div) {
  display: block !important;
}
</style>
