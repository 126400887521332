<template>
  <span class="flex" :class="colorClasses" :title="text">
    <WebccIcon
      v-for="(icon, index) in icons"
      :key="index"
      :class="iconClasses"
      :name="`reviews/${icon}`"
      role="img"
      aria-hidden="true"
      focusable="false"
      :alt="text"
      :filled="false"
    />
  </span>
</template>

<script setup lang="ts">
type RatingColor = 'theme' | 'highlight' | 'grey' | 'white'
type RatingSize = '3' | '5'

const COLOR_CLASSES = {
  theme: 'text-thm',
  highlight: 'text-hlt',
  grey: 'text-txt-weak',
  white: 'text-txt-contrast',
} as const

const props = withDefaults(
  defineProps<{
    rating: number
    color?: RatingColor
    size?: RatingSize
  }>(),
  {
    color: 'theme',
    size: '5',
  },
)

const { text } = useRating(() => props.rating)

const icons = computed(() => getRatingHearts(props.rating).map((heart) => (heart === 1 ? 'heart' : heart === 0.5 ? 'heart-half' : 'heart-empty')))

const iconClasses = computed(() => `mr-1 last:mr-0 h-${props.size} w-${props.size}`)

const colorClasses = computed(() => (props.rating ? COLOR_CLASSES[props.color] : COLOR_CLASSES.grey))
</script>
