<template>
  <div class="group">
    <div class="relative h-full" :class="containerClasses">
      <div ref="container" class="keen-slider absolute flex bg-bgr h-full" @click.stop="onClick">
        <div v-for="({ id, loaded, alt, title, src }, index) in sliderImages" :key="id" class="keen-slider__slide lazy__slide relative h-full min-w-full">
          <img
            :src="loaded ? src : ''"
            :alt="alt"
            :title="title"
            :class="[coverFull ? 'w-full' : 'w-[inherit]', imageHeight ? `h-[${imageHeight}]` : 'h-full']"
            class="m-auto object-cover"
          />
          <div class="absolute inset-0 pointer-events-none *:pointer-events-auto">
            <slot name="overlay" :index="index" />
          </div>
        </div>
      </div>
      <div v-for="direction in SLIDER_BUTTONS" :key="direction" class="absolute top-0 h-full" :class="`${direction}-0`">
        <slot v-if="buttonsVisible" :name="`${direction}-button`" :click="() => onSlideButtonClick(direction)">
          <button
            class="h-full"
            :class="direction === 'left' ? 'pl-4' : 'pr-4'"
            @click="onSlideButtonClick(direction)"
            @mouseover="onSlideChangeIntent(direction)"
          >
            <span
              class="block p-1 rounded-lg bg-bgr bg-opacity-80 text-txt backdrop-blur-md transition-all opacity-0 duration-200 hover:scale-105 hover:bg-opacity-90 hover:shadow-md active:scale-100 group-hover:opacity-100"
            >
              <WebccIcon :name="`site/chevron-${direction}`" class="h-6 w-6 text-txt" filled />
            </span>
          </button>
        </slot>
      </div>
      <div class="absolute inset-0 pointer-events-none *:pointer-events-auto">
        <slot name="container-overlay" />
      </div>
    </div>
    <div v-if="showDots" class="flex flex-row align-middle justify-center gap-2 p-4">
      <span
        v-for="(_, index) in sliderImages"
        :key="index"
        class="w-2 h-2 rounded-full transition-colors duration-300"
        :class="current === index ? 'bg-gray-700' : 'bg-white'"
        tabindex="0"
        role="button"
        @click="slideTo(index)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/vue.es'

export interface SliderImage {
  id: string
  src: string
  alt: string
  title: string
  loaded?: boolean
}

interface Props {
  containerClasses?: string
  images: SliderImage[]
  coverFull: boolean
  initial?: number
  showDots?: boolean
  imageHeight?: string
}

const props = withDefaults(defineProps<Props>(), {
  containerClasses: undefined,
  coverFull: false,
  showDots: false,
  initial: 0,
  imageHeight: undefined,
})

const SLIDER_BUTTONS = ['left', 'right'] as const

const emit = defineEmits<{
  click: [index: number]
  'slide-change': [index: number]
  'slide-change-intent': [direction: ScrollDirectionHorizontal]
  'slide-button-click': [direction: ScrollDirectionHorizontal]
}>()

defineSlots<{
  'overlay'(props: { index: number }): unknown
  'left-button'(props: { click(): void }): unknown
  'right-button'(props: { click(): void }): unknown
  'container-overlay'(): unknown
}>()

const current = ref(props.initial)
const sliderImages = ref<SliderImage[]>([])

const buttonsVisible = computed(() => sliderImages.value.length > 1)

setImages(props.images)

watch(() => props.images, setImages)

const [container, slider] = useKeenSlider({
  loop: true,
  initial: current.value,
  defaultAnimation: { duration: 800 },
  dragSpeed: 0.6,
  dragStarted({ track }) {
    onSlideChangeIntent(track.details.abs - current.value > 0 ? 'right' : 'left')
  },
  slideChanged({ track }) {
    current.value = track.details.abs % sliderImages.value.length
    loadImagesAround(current.value)
    emit('slide-change', track.details.rel)
  },
})

function setImages(images: SliderImage[]) {
  sliderImages.value = images.map((image) => ({ ...image }))
  loadImagesAround(current.value)
  nextTick(() => slider.value?.update())
}

/**
 * Loads the given and surrounding images for smooth sliding.
 */
function loadImagesAround(index: number) {
  ;[index - 1, index, index + 1].forEach(loadImageAt)
}

/**
 * Marks the image at the given index as `loaded` to trigger its actual load and rendering.
 */
function loadImageAt(index: number) {
  const relativeIndex = index % sliderImages.value.length
  const image = sliderImages.value.at(relativeIndex)
  if (image) image.loaded = true
}

onBeforeUnmount(() => {
  slider.value?.destroy()
})

function slideTo(index: number) {
  slider.value?.moveToIdx(index)
}

function onSlideButtonClick(direction: ScrollDirectionHorizontal) {
  const slideFn = direction === 'left' ? slider.value?.prev : slider.value?.next
  slideFn?.()
  emit('slide-button-click', direction)
}

function onSlideChangeIntent(direction: ScrollDirectionHorizontal) {
  emit('slide-change-intent', direction)
}

function onClick() {
  emit('click', current.value)
}
</script>
