<template>
  <ol :class="textsize" class="flex flex-wrap items-center">
    <li class="flex items-center">
      <WebccLink v-if="links" anchor class="cursor-pointer no-underline hover:text-thm" :to="toSearchURL(breadcrumbs.country)">{{ accom.country }}</WebccLink>
      <span v-else>{{ accom.country }}</span>
    </li>
    <li class="flex items-center">
      <WebccIcon name="site/chevron-right" class="h-4 w-4 mx-1 text-txt" filled />
      <WebccLink v-if="links" anchor class="cursor-pointer no-underline hover:text-thm" :to="toSearchURL(breadcrumbs.region)">{{ accom.region }}</WebccLink>
      <span v-else>{{ accom.region }}</span>
    </li>

    <li class="flex items-center">
      <WebccIcon name="site/chevron-right" class="h-4 w-4 mx-1 text-txt" filled />
      <WebccLink v-if="links" anchor class="cursor-pointer no-underline hover:text-thm" :to="toSearchURL(breadcrumbs.place)">{{ accom.place }}</WebccLink>
      <span v-else>{{ accom.place }}</span>
    </li>

    <li v-if="!hideCode" class="flex items-center font-semibold">
      <WebccIcon name="site/chevron-right" class="h-4 w-4 mx-1 text-txt" filled />
      <span :class="{ 'cursor-pointer no-underline hover:text-thm': links }" @click="emit('open')">{{ accom.code }}</span>
    </li>
  </ol>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    targetUrl: URL
    accom: ResultDocument
    hideCode?: boolean
    size?: string
    links?: boolean
  }>(),
  {
    size: 'sm',
    links: true,
  },
)

const emit = defineEmits<{ (e: 'open'): void }>()

const breadcrumbs = computed(() => {
  const slugparts = props.accom.slug?.split('/') || ''
  const result = { country: '', region: '', place: '' }
  result.country = `/${slugparts[0]}/`
  result.region = `${result.country + slugparts[1]}/`
  result.place = `${result.region + slugparts[2]}/`
  return result
})

const textsize = computed(() => {
  switch (props.size) {
    case 'xs':
      return 'text-xs'
    case 'sm':
      return 'text-sm'
    case 'md':
      return 'text-md'
    case 'lg':
      return 'text-lg'
    case 'xl':
      return 'text-xl'
    default:
      return ''
  }
})

function toSearchURL(breadcrumbPlace: string) {
  const params = new URLSearchParams(useRequestURL().search)
  params.delete('page')
  const modifiedSearch = params.toString() ? `?${params.toString()}` : ''
  return breadcrumbPlace + modifiedSearch
}
</script>
