<template>
  <form
    v-if="confData.siteSettings?.includes('newsletter') && confData.signupEnabled && !$params.iframe"
    :action="newsletterAction"
    method="POST"
    class="bg-thm"
  >
    <div class="mx-auto h-auto max-w-[1920px] justify-center md:flex md:h-72 md:flex-row">
      <div class="h-24 md:h-full md:w-1/2">
        <NuxtImg loading="lazy" format="webp" src="/images/footer-newsletter-signup.png" alt="" class="h-full w-full object-cover" />
      </div>
      <div class="mx-auto flex max-w-xl flex-col px-2 py-4 text-center md:ml-0 md:w-1/2 md:px-4 md:py-8">
        <div class="mb-6 flex w-full flex-row items-center">
          <div class="hidden h-[1/2] w-full flex-1 border-b border-thm-contrast sm:block md:hidden lg:block"></div>
          <div class="h-auto w-max flex-auto bg-thm text-xl text-thm-contrast sm:mx-2 md:text-2xl">{{ $t(`${trPrefix}title`) }}</div>
          <div class="hidden h-[1/2] w-full flex-1 border-b border-thm-contrast sm:block md:hidden lg:block"></div>
        </div>
        <span class="mb-6 text-thm-contrast">{{ $t(`${trPrefix}text`) }}</span>
        <div class="flex flex-col gap-2 md:flex-row">
          <WebccInput v-model="emailaddress" :label="`${trPrefix}emailPlaceholder`" name="email" type="email" classes="md:w-2/3" />
          <WebccButton variant="cta" size="lg" class="md:w-1/3">
            <span>{{ $t(`${trPrefix}cta`) }}</span>
          </WebccButton>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.site.FooterNewsletterSignup.'

const params = useParams()
const confData = useConfdata()

const emailaddress = ref('')

const newsletterAction = computed(() => {
  // Host does not matter
  const url = new URL('http://0.0.0.0')
  url.pathname = `${confData.languagePrefix}/newsletter`
  url.searchParams.set('type', 'Bar')

  url.search = new URLSearchParams({
    ...(Object.fromEntries(url.searchParams) as Record<string, string>),
    ...(params.persistent as Record<string, string>),
  }).toString()
  return url.pathname + url.search
})
</script>
