<template>
  <div
    class="flex w-full items-center justify-between rounded border-0 bg-bgr text-sm text-txt shadow focus:border-bgr-700 focus:bg-bgr-100 focus:text-txt-stronger focus:outline-none"
  >
    <button class="rounded-l px-4 py-2 text-lg hover:bg-bgr-300" @click="decreaseMaxAmount">
      <WebccIcon class="h-5 w-5" name="searchpage/minus" />
    </button>
    <span class="flex-grow cursor-default text-center">{{ formatCurrency(maxPrice, undefined, useConfdata().currency) }}</span>
    <button class="rounded-r px-4 py-2 text-lg hover:bg-bgr-300" @click="increaseMaxAmount">
      <WebccIcon class="h-5 w-5" name="searchpage/plus" />
    </button>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  filter: string
}>()

const model = computed({
  get() {
    return useSearch().filters[props.filter as keyof Params] as number | undefined
  },
  set(value) {
    useSearch().setFilter({ [props.filter]: value })
  },
})

const maxPrice = computed(() => {
  return useSearch().filters.maxPrice as number
})

function increaseMaxAmount() {
  model.value = (model.value || 0) + 500
}

function decreaseMaxAmount() {
  if (model?.value && model.value > 500) {
    model.value -= 500
  } else {
    model.value = undefined
  }
}
</script>
