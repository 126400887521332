import { parseURL, withQuery } from 'ufo'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const result = await useConfdata().load(to)

  if (!result) return

  if (result instanceof Error) {
    console.error(`[conf] load failed ${useRequestURL()}:`, result.message || result)
    throw result
  }

  if ('location' in result) {
    const { source, location, status } = result as RedirectOut
    const accommodationRegexp = /([a-zA-Z]{2}\d{4}\.\d{1,4}\.\d{1,3})/

    // In cases where we redirect from a detail page, we want the user to be able to try to access that page again in the future.
    // See https://jira.migros.net/browse/IHGWEBCC-36
    if (import.meta.server && to.path.match(accommodationRegexp) && !from.path.match(accommodationRegexp)) {
      setHeader(useNuxtApp().ssrContext!.event, 'Cache-Control', 'max-age=86400')
    }

    const { host } = parseURL(location)
    const url = useRequestURL()
    const redirectCode = status || 301
    const external = redirectCode === 301 || (!!host && host !== url.host) // all 301 redirects should be `external`, otherwise Nuxt will always set 302
    const explicit = isInterchaletUrl(source)
    const target = explicit ? withQuery(location, { redirectedFrom: url.href }) : location

    return navigateTo(target, { redirectCode, external })
  }
})
