<template>
  <ul>
    <li v-for="(overall, key) in details" :key="key">
      <div v-if="overall" class="flex items-center py-2 gap-2">
        <RatingHearts class="text-thm" :rating="overall" />
        <span>{{ $t(`${trPrefix}${key}`) }}</span>
      </div>
    </li>
  </ul>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.reviews.Summary.'

const props = defineProps<{
  condition: number
  cleanliness: number
  service: number
}>()

const details = computed(() => ({
  overallCondition: props.condition,
  overallCleanliness: props.cleanliness,
  overallService: props.service,
}))
</script>
