<template>
  <section v-if="showFreeCancellationInfo">
    <div :class="fullParentWidth ? 'flex justify-center' : 'inline-flex'" class="border-suc items-center gap-2 rounded-lg border p-1 md:p-2">
      <WebccIcon name="site/tick" class="text-suc h-4 w-4 md:h-5 md:w-5" />
      <div class="grow-0 text-xs md:text-sm">
        <span class="font-semibold">{{ $t(`${trPrefix}title`) }}</span
        >: <span>{{ $t(`${trPrefix}text`) }}</span> <span class="font-semibold">{{ lastFreeCancellationDateShort }}</span>
      </div>
      <span v-if="showTooltip" v-tooltip="{ content: tooltip, html: true }"><WebccIcon name="site/info-new" class="text-txt-weak h-5 w-5" /></span>
    </div>
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'plugins.components.FreeCancellationInfo.'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    checkinDate: Date
    freeCancellationRange?: number
    showTooltip?: boolean
    fullParentWidth?: boolean
    locale: string
  }>(),
  {
    freeCancellationRange: 0,
    showTooltip: false,
    fullParentWidth: false,
  },
)

const showFreeCancellationInfo = computed(() => {
  return props.freeCancellationRange > 0 && lastFreeCancellationDate.value > new Date()
})
const lastFreeCancellationDate = computed(() => {
  return daysSub(props.checkinDate, props.freeCancellationRange)
})
const lastFreeCancellationDateShort = computed(() => {
  return lastFreeCancellationDate.value.toLocaleDateString(props.locale, { day: 'numeric', month: 'short' })
})
const lastFreeCancellationDateFull = computed(() => {
  return lastFreeCancellationDate.value.toLocaleDateString(props.locale, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  })
})
const tooltip = computed(() => {
  const title = t(`${trPrefix}tooltipTitle`)
  const text = t(`${trPrefix}tooltipText`, { date: lastFreeCancellationDateFull.value })

  return `<div class='max-w-[24rem] px-6 py-3'><p class='font-semibold mb-4'>${title}</p> <p>${text}</p></div>`
})
</script>
