<template>
  <form
    v-if="useConfdata().siteSettings?.includes('newsletter') && useConfdata().signupEnabled"
    v-track:impression="{ event: `homepage:newsletterBannerImpression` }"
    :action="newsletterAction"
    method="POST"
    class="bg-thm"
  >
    <div class="container-fluid-lg flex h-auto max-w-[95vw] flex-col items-center justify-start gap-x-16 py-8 lg:flex-row">
      <div class="max-w-3xl lg:max-w-[50%]">
        <div class="flex h-full w-fit flex-col items-center justify-start gap-4">
          <div class="flex w-full flex-row items-center">
            <WebccIcon name="site/mail-open" class="h-8 w-8 shrink-0 text-txt-contrast md:h-10 md:w-10" />
            <div class="flex h-auto flex-auto flex-row justify-start bg-thm pl-2 text-start text-2xl font-medium text-txt-contrast sm:mx-2">
              {{ $t(`${trPrefix}title`) }}
            </div>
          </div>
          <span class="mb-6 text-xl text-txt-contrast">{{ $t(`${trPrefix}text`) }}</span>
        </div>
      </div>
      <div class="flex w-full max-w-3xl flex-col items-center justify-end gap-4 md:flex-row md:gap-0">
        <WebccInput v-model="emailaddress" :label="`${trPrefix}emailPlaceholder`" name="email" type="email" class="md:rounded-r-none" />
        <WebccButton variant="cta" size="lg" class="h-14 w-full md:w-64 md:rounded-l-none" @click="subscribeTracking">
          <span>{{ $t(`${trPrefix}cta`) }}</span>
        </WebccButton>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.site.FooterNewsletterSignup.'

const emailaddress = ref('')

const subscribeTracking = () => {
  useTracking().handlers?.homepage.newsletterBannerSubscribe(emailaddress.value)
}

const newsletterAction = computed(() => {
  // Domain does not matter
  const url = new URL('https://www.interhome.com')
  url.pathname = `${useConfdata().languagePrefix}/newsletter`
  url.searchParams.set('type', 'Bar')

  url.search = new URLSearchParams({ ...Object.fromEntries(url.searchParams), ...(useParams().persistent as Record<string, string>) }).toString()
  return url.pathname + url.search
})
</script>
