export function getQueryTripParams(query: Record<string, unknown>): Params {
  const checkin = (query.checkin || query.arrivalDate || query.arrivaldate)?.toString()
  const checkout = (query.checkout || query.departureDate || query.departuredate)?.toString()
  const duration = tryParseInt(query.duration?.toString())

  if (!checkin || (!checkout && !duration)) return { checkin: undefined, checkout: undefined, duration: undefined }

  const params: Params = {}
  params.checkin = checkin
  params.checkout = checkout ?? toDate(calculateCheckoutDate(checkin, duration!))
  params.duration = duration && duration <= calculateDuration(checkin, params.checkout) ? duration : undefined
  return params
}
