export default function useGuestWorldApi() {
  async function user() {
    return await useFetch<UserData>('/guestworld-api/v1/users', {
      headers: useRequestHeaders(['cookie']),
      credentials: 'include',
    })
  }

  return { user }
}
