<template>
  <section
    v-if="useConfdata().signupEnabled"
    class="flex w-full flex-wrap items-center justify-center gap-2 rounded bg-thm p-2 text-txt-weakest shadow-md sm:col-span-2 sm:gap-4 md:gap-6 md:text-lg"
  >
    <div class="flex flex-row items-center justify-center gap-2">
      <WebccIcon name="searchpage/newsletter" filled class="h-12 w-12 md:h-16 md:w-16" />
      <span class="text-center"
        ><span>{{ $t(`${trPrefix}text`) }}</span></span
      >
    </div>
    <WebccButton size="md" class="w-52" variant="theme" framed @click="toNewsletter">
      <span
        ><span>{{ $t(`${trPrefix}cta`) }}</span></span
      >
    </WebccButton>
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.search.NewsletterSignup.'

function toNewsletter() {
  navigateTo(`${useConfdata().languagePrefix}/newsletter?type=Disruptor`)
}
</script>
