const seasons: Record<string, Season> = {
  s: 's',
  summer: 's',
  w: 'w',
  winter: 'w',
}

export const useSeason = (date?: Date | string): Season => {
  return seasons[useParams().all.season as keyof typeof seasons] ?? getSeason(date)
}
