export const useAutosuggestStore = defineStore('autosuggest', () => {
  async function suggest(params: SuggestParams) {
    try {
      const { data, error } = await useFetch<AutosuggestResponse>('/search-api/v1/suggest', {
        params,
      })

      if (error.value) {
        throw error.value
      }

      return data.value
    } catch (error) {
      console.error(error)
      return null
    }
  }

  return { suggest }
})
