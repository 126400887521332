import { addSeconds } from 'date-fns'

const REDIRECTED_FROM_COOKIE_LIFETIME_S = 10

export function useRedirection() {
  const from = useCookie('redirectedFrom', { expires: addSeconds(new Date(), REDIRECTED_FROM_COOKIE_LIFETIME_S) })

  const fromInterchalet = computed(() => isInterchaletUrl(from.value ?? ''))

  function save(source: string | undefined) {
    from.value = source
  }

  return { from, fromInterchalet, save }
}
