<template>
  <section class="bg-bgr py-6">
    <div class="container-fluid-lg mb-5">
      <slot />
    </div>

    <div class="container-fluid-lg mb-12">
      <slot name="searchteaser" />
    </div>

    <div class="container-fluid-lg mb-12">
      <SearchTeaserFilters />
      <TeaserSubDestinations :destination="confData.destination" />
      <slot name="contentassets" />
    </div>

    <div v-if="additionalBody.seotext.content_html" class="container-fluid-lg">
      <WebccExpandable :key="additionalBody.seotext.content_html">
        <div v-impression:search:seoTextImpression.100="'start'"></div>
        <article
          class="prose prose-richtext-searchpage mt-4 max-w-none prose-img:w-full"
          @click.prevent="onSeoTextClick"
          v-html="additionalBody.seotext.content_html"
        ></article>
        <div v-impression:search:seoTextImpression.100="'end'"></div>
      </WebccExpandable>
    </div>
  </section>
</template>

<script setup lang="ts">
const { buildValidUrl } = useURLs()
const confData = useConfdata()

const additionalBody = computed(() => useConfdata().additionalBody as SearchPageBody)

function onSeoTextClick(e: MouseEvent) {
  const href = findTargetA((e.target || e.srcElement) as Element)?.getAttribute('href')

  if (!href) return

  if (href.startsWith('#')) {
    document.getElementById(href.substring(1))?.scrollIntoView()
  } else {
    navigateTo(buildValidUrl(href).toString(), { external: true })
  }
}
</script>
