import { withQuery } from 'ufo'

export default defineNuxtRouteMiddleware((to) => {
  if (!import.meta.server) return

  if (to.query.redirectedFrom) {
    useRedirection().save(to.query.redirectedFrom?.toString())

    // Redirecting with `external: true` to make sure the `redirectCode` is `301`.
    // Otherwise, Nuxt always sets the `redirectCode` to 302, regardless of what was passed.
    return navigateTo(withQuery(to.fullPath, { redirectedFrom: undefined }), { redirectCode: 301, external: true })
  }
})
