export function getQueryLegacyParams(query: Record<string, unknown>): Params {
  const distanceParams = {
    sea: true,
    lake: true,
    ski: true,
    center: true,
  }
  const legacyParams: Record<string, string | boolean> = {
    golf: 'golfing',
    skiarea: 'skiing',
    biking_plains: 'cycling',
    crosscountry_skiing: 'crosscountryskiing',
    balcony_or_terrace: 'balcony-or-terrace',
    whirlpool: 'hottub',
    sorting: true,
    ...distanceParams,
  }
  const sortingMap: Record<string, SortingOptions> = {
    ascAge: 'age',
    descLastBookDay: '-lastbookday',
    descAverageRating: '-averagerating',
    ascPrice: 'price',
    descPrice: '-price',
    ascPerson: 'pax',
    descPerson: '-pax',
  }
  const distanceMap: Record<string, string> = {
    '0-100m': '100',
    '0-500m': '500',
    '0-1km': '1000',
    '0-2km': '2000',
    '0-5km': '5000',
    '0-10km': '10000',
  }

  return Object.fromEntries(
    Object.entries(query)
      .filter(([param]) => legacyParams[param])
      .map(([param, value]) => {
        if (typeof legacyParams[param] === 'string') return [legacyParams[param], true]
        if (param in distanceParams && isNaN(Number(value))) return [param, distanceMap[value?.toString() ?? '']]
        if (param === 'sorting') return ['sorting', sortingMap[value?.toString() ?? ''] ?? value?.toString()]
        return [param, value?.toString()]
      }),
  )
}
