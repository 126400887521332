export default function useRating(rating: MaybeRefOrGetter<number>) {
  const i18n = useI18n()

  const value = computed(() => roundRatingToDecimal(toValue(rating)))
  const rounded = computed(() => roundRating(toValue(rating)))
  const formatted = computed(() => formatRating(value.value, i18n.locale))
  const delim = computed(() => RATING_DELIM)
  const max = computed(() => RATING_MAX)
  const text = computed(() => getRatingText(value.value, i18n.locale))

  return { value, formatted, rounded, delim, max, text }
}
