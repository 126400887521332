/**
 * Encapsulates requests to Nova API.
 */
export default function useNovaApi() {
  async function getContentAssets(params: GetContentAssetsParams) {
    return await useFetch<ContentAssets>('/nova/content-assets', { params })
  }

  return { getContentAssets }
}
