<template>
  <div />
</template>

<script setup lang="ts">
const trPrefix = 'www.components.notifications.'

const { t } = useI18n()
const ab = useAB()
const params = useParams()
const partner = usePartner()
const user = useUser()
const toaster = useToaster()
const displayedPopups = usePopups()
const redirection = useRedirection()

onMounted(() => {
  notifySignedIn()
  notifyInterchaletMigration()
})

function notifySignedIn() {
  if (!ab.isActive('guestWorld')) return
  if (!params.all.signedIn || user.signedIn) return

  toaster.success(`${t(`${trPrefix}signedIn.text`)} <strong>${user.email}</strong>`, { delay: 300 })
}

function notifyInterchaletMigration() {
  if (!useFlag('interchalet-is-now-interhome-information').value) return
  if (!redirection.fromInterchalet.value && partner.id !== 'IH1000021') return
  if (displayedPopups.popups.interhomePopup) return

  toaster.untyped(h(resolveComponent('InterchaletMigrationPopup')), { delay: 300, duration: false, closeButton: 'top' })
}
</script>
