import type { ActiveHeadEntry, MergeHead } from '@unhead/schema'
import type { UseHeadInput } from '@unhead/vue'

export const usePageHeadStore = defineStore('head', () => {
  const pageHeader = ref<ActiveHeadEntry<UseHeadInput<MergeHead>>>()

  function update(input: UseHeadInput<MergeHead> | undefined) {
    if (!input) return

    // To avoid unwanted behavior of the Unheard API, we first remove the old header
    pageHeader.value?.dispose()
    pageHeader.value = useHead(input!) ?? undefined
  }

  return { update }
})
