export default async function useContentAssets(params: { filter?: string }) {
  const { company, destination } = useConfdata()

  return await useNovaApi().getContentAssets({
    destination: getDestinationCode(destination),
    company: getCompanySlug(company),
    filter: params.filter || '',
    locale: useL10N().locale?.toLowerCase(),
  })
}
