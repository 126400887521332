<template>
  <div class="w-full">
    <div
      class="flex"
      :class="{
        'flex-col': labelPosition === 'top',
        'flex-col-reverse': labelPosition === 'bottom',
        'sm:flex-row sm:items-center sm:gap-4': labelPosition === 'left',
        'sm:flex-row-reverse sm:items-center sm:gap-4': labelPosition === 'right',
      }"
    >
      <component
        :is="resolvedComponent"
        :id="name"
        ref="component"
        v-model="model"
        :valid="fieldErrors?.[name] && fieldErrors[name].length === 0"
        :required="!!required"
        :label="label"
        :name="name"
        :type="type"
        :options="normalizedOptions"
        v-bind="$attrs"
        @change="
          ($event: Event) => {
            validate(($event.target as HTMLInputElement).value)
          }
        "
      />
    </div>
    <div v-for="e in fieldErrors?.[name]" :key="e" class="text-sm text-err">
      <span v-if="trDirect">{{ e }}</span>
      <span v-else>{{ $t(`plugins.form.Formfield.${e}` as TranslationKey) }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { fieldErrorsKey, formdataKey, registerKey, setFieldErrorsKey, setKey } from './InjectionKeys'

const set = inject(setKey)
const formdata = inject(formdataKey)
const fieldErrors = inject(fieldErrorsKey)
const setFieldErrors = inject(setFieldErrorsKey)
const register = inject(registerKey)

interface Props {
  trPrefix?: string
  labelPosition?: 'top' | 'bottom' | 'left' | 'right'
  options?: Array<any>
  trDirect?: boolean
  type?: string
  label?: string
  name: string
  required?: boolean | string
  modelValue?: string | number | boolean | Array<any> | object
  pattern?: RegExp
  // Has to be a globally registered component name that supports basic input functions like "onchange" and "value".
  component: string
  validators?: Function[]
}

const props = withDefaults(defineProps<Props>(), {
  trPrefix: 'plugins.form.Formfield.',
  labelPosition: 'top',
  options: () => [],
  type: 'text',
  label: '',
  required: false,
  modelValue: undefined,
  pattern: undefined,
  validators: () => [],
})

defineExpose({ validate })

const emit = defineEmits<{
  'update:modelValue': [value: string | number | boolean | object | any[] | undefined]
  input: [value: string | number | boolean | object | any[] | undefined]
  submit: [value: string | number | boolean | object | any[] | undefined]
}>()

const validationState: Ref<{ errors: string[]; isValid: boolean | undefined; value: string | number | boolean | object | any[] | undefined }> = ref({
  errors: [],
  isValid: undefined,
  value: undefined,
})

const resolvedComponent = computed(() => {
  return resolveComponent(props.component)
})

const model = computed({
  get() {
    return formdata ? formdata[props.name] : props.modelValue
  },
  set(value) {
    if (set) {
      set(props.name, value)
    }
    validationState.value.errors = []
    validationState.value.isValid = undefined
    if (setFieldErrors) {
      setFieldErrors(props.name, [])
    } else if (fieldErrors) {
      fieldErrors[props.name] = []
    }
    emit('input', value)
    emit('update:modelValue', value)
  },
})

const normalizedOptions = computed(() => {
  if (!props.options) {
    return []
  }
  const normalizedOptions: { value: string | number; label: string }[] = []
  props.options.forEach((option) => {
    if (typeof option === 'object') {
      for (const key in option) {
        normalizedOptions.push({ value: key, label: option[key] })
      }
    } else {
      normalizedOptions.push({ value: option, label: option })
    }
  })
  return normalizedOptions
})
const rules = computed(() => {
  const rules = props.validators || []
  if (props.pattern) {
    rules.push((v: string) => props.pattern!.test(v) || 'unmatchedPattern')
  }
  if (props.type === 'daterange' && props.required) {
    rules.push((v: { start: string; end: string }) => v.start !== v.end || `${typeof props.required === 'string' ? props.required : 'required'}=>stop`)
  }
  if (props.required) {
    rules.unshift((v: string) => !!v || `${typeof props.required === 'string' ? props.required : 'required'}=>stop`)
  }
  return rules
})

if (formdata && !Object.keys(formdata).includes(props.name)) {
  console.error('Unknown Form element:', props.name)
}

onMounted(async () => {
  if (register) {
    register(props.name, getCurrentInstance()!)
  }

  if (typeof model.value !== 'object' && model.value) {
    await validate(model.value)
    // Special handling for daterange objects
  } else if (typeof model.value === 'object' && Object.values(model.value).some((v) => v)) {
    await validate(model.value)
  }
})

async function errors(v: string | number | boolean | object | undefined) {
  const errors = []
  for (const i in rules.value) {
    const validOrMessage = await rules.value[i](v)
    if (validOrMessage !== true) {
      const [message, stop] = validOrMessage.split('=>')
      errors.push(message)
      if (stop === 'stop') {
        return errors
      }
    }
  }
  return errors
}

async function validate(v: string | number | boolean | object | undefined) {
  if (v === undefined) {
    v = model.value
  }

  const e = await errors(v)
  const validation = {
    value: v,
    isValid: e.length === 0,
    errors: e,
  }

  if (!arraysEqual(validationState.value.errors, validation.errors)) {
    if (setFieldErrors) {
      setFieldErrors(props.name, validation.errors)
    } else if (fieldErrors) {
      fieldErrors[props.name] = validation.errors
    }
  }
  validationState.value = validation
  return validationState
}
</script>
