<template>
  <main class="container-fluid-md my-8">
    <WebccHeading :level="1" :title="$t(`${trPrefix}title`)" space="mb-4" class="text-center" />

    <section class="relative min-h-1/4-screen rounded bg-bgr p-4 text-txt shadow">
      <div v-if="success === true" class="">
        <WebccHeading :level="2" :title="$t(`${trPrefix}success.heading`)" space="my-2" />
        <p>{{ $t(`${trPrefix}success.text`) }}</p>
      </div>

      <div v-else-if="success === false" class="">
        <WebccHeading :level="2" :title="$t(`${trPrefix}error.heading`)" />
        <p>{{ $t(`${trPrefix}error.text`) }}</p>
      </div>

      <WebccLoaderOverlay v-else absolute rounded="md" />
    </section>
  </main>
</template>

<script setup lang="ts">
const trPrefix = 'www.pages.emails._hash.optin.'

const success = ref<boolean>()

onMounted(async () => {
  const { error } = await useEmails().optIn()

  success.value = !error.value

  if (!success.value) return

  useTracking().handlers?.newsletter.optIn()
  useTracking().handlers?.newsletter.optInHeID(useEmails().hash.value)
})
</script>
