<template>
  <button class="rounded focus:outline-none focus:ring-2 focus:ring-offset-2" :class="classes" :disabled="disabled || loading">
    <div class="flex items-center justify-center" :class="loading ? 'opacity-0' : 'opacity-100'">
      <WebccIcon v-if="icon" :class="iconClasses" :name="icon" :filled="iconFilled" />
      <slot>
        <span v-if="tr">{{ $t(tr) }}</span>
        <span v-else>{{ label }}</span>
      </slot>
    </div>
    <div v-if="loading" class="absolute inset-0 flex items-center justify-center">
      <WebccLoader :cta="variant === 'cta'" type="circle" size="sm" />
    </div>
  </button>
</template>

<script setup lang="ts">
type Variant = 'theme' | 'cta' | 'white' | 'grey' | ''

const VARIANT_BASED_CLASSES: Record<Variant, { outline: string; framed: string; default: string }> = {
  theme: {
    outline: 'bg-bgr text-thm border border-thm transition hover:bg-thm hover:text-thm-contrast focus:bg-thm focus:text-thm-contrast focus:ring-thm',
    framed: 'bg-thm text-thm-contrast border border-thm-contrast transition hover:bg-thm-hover focus:bg-thm-hover focus:ring-thm-hover',
    default: 'bg-thm text-thm-contrast hover:bg-thm-hover transition focus:bg-thm-hover disabled:grayscale-50 focus:ring-thm-hover',
  },
  cta: {
    outline: 'bg-bgr text-cta border border-cta hover:bg-cta transition hover:text-cta-contrast focus:bg-cta focus:text-cta-contrast focus:ring-cta',
    framed: 'bg-cta text-cta-contrast border border-cta-contrast transition hover:bg-cta-hover focus:bg-cta-hover focus:ring-cta-hover',
    default: 'bg-cta text-cta-contrast hover:bg-cta-hover transition focus:bg-cta-hover focus:ring-cta-hover',
  },
  white: {
    outline:
      'bg-bgr text-txt border border-bgr-300 transition hover:bg-bgr-100 hover:text-txt-stronger hover:border-bgr-700 focus:bg-bgr-100 focus:text-txt-stronger focus:border-bgr-700 focus:ring-bgr-100',
    framed:
      'bg-bgr text-txt border border-txt hover:bg-bgr-100 transition hover:text-txt-stronger hover:border-bgr-700 focus:bg-bgr-100 focus:text-txt-stronger focus:border-bgr-700 focus:ring-bgr-100',
    default: 'bg-bgr text-txt hover:bg-bgr-100  transition focus:bg-bgr-100 focus:text-txt-stronger focus:ring-bgr-100',
  },
  grey: {
    outline:
      'bg-bgr-200 text-txt border border-bgr-300 transition hover:bg-bgr-100 hover:text-txt-stronger hover:border-bgr-700 focus:bg-bgr-100 focus:text-txt-stronger focus:border-bgr-700 focus:ring-bgr-100',
    framed:
      'text-txt transition focus:text-txt-stronger focus:ring-bgr-100 border border-bgr-300 bg-bgr-100 hover:border-bgr-400 hover:bg-bgr-300 focus:bg-bgr-300',
    default:
      'bg-bgr-200 text-txt transition hover:bg-bgr-400 focus:bg-bgr-400 focus:text-txt-weak focus:ring-bgr-100 border border-bgr-500 disabled:border-transparent disabled:bg-bgr-100 disabled:hover:bg-bgr-100',
  },
  '': {
    outline:
      'bg-bgr-200 text-txt border border-bgr-300 transition hover:bg-bgr-100 hover:text-txt-stronger hover:border-bgr-700 focus:bg-bgr-100 focus:text-txt-stronger focus:border-bgr-700 focus:ring-bgr-100',
    framed:
      'bg-bgr-200 text-txt border border-txt transition hover:bg-bgr-100 hover:text-txt-stronger hover:border-bgr-700 focus:bg-bgr-100 focus:text-txt-stronger focus:border-bgr-700 focus:ring-bgr-100',
    default: 'bg-bgr-200 text-txt transition hover:bg-bgr-100  focus:bg-bgr-100 focus:text-txt-stronger focus:ring-bgr-100',
  },
}

const SIZE_BASED_CLASSES = {
  sm: { size: 'py-1 px-2 md:px-3 md:py-1.5 text-sm', icon: '-ml-1 h-4 w-4 mr-1' },
  md: { size: 'px-3 md:px-4 py-1.5 text-sm md:text-base', icon: '-ml-1.5 h-5 w-5 mr-2' },
  lg: { size: 'px-4 py-2 text-lg', icon: '-ml-1.5 h-6 w-6 mr-2' },
}

interface Props {
  label?: string
  variant?: Variant
  size?: Size
  icon?: string
  iconFilled?: boolean
  disabled?: boolean
  loading?: boolean
  tr?: TranslationKey
  outline?: boolean
  framed?: boolean
  round?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  label: '',
  variant: '',
  size: 'md',
  icon: undefined,
  iconFilled: false,
  tr: undefined,
})

const noText = computed(() => props.icon && !props.label && !Object.keys(useSlots()).length)

const iconClasses = computed(() => (noText.value ? 'h-5 w-5' : SIZE_BASED_CLASSES[props.size].icon))

const classes = computed(() => {
  const fontClasses = ['cta', 'theme'].includes(props.variant)
    ? // To improve website accessibility, all cta and theme buttons should always have font weight 700.
      // see https://jira.migros.net/browse/IHGWEBCC-1553?focusedId=67593897&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-67593897
      'font-bold'
    : props.size !== 'sm'
      ? 'font-light'
      : ''
  const { outline, framed, default: _default } = VARIANT_BASED_CLASSES[props.variant]

  return [
    'disabled:cursor-not-allowed disabled:shadow-none disabled:opacity-70',
    noText.value ? 'px-2 py-2' : [SIZE_BASED_CLASSES[props.size].size, fontClasses],
    props.outline ? outline : props.framed ? framed : _default,
    props.icon || props.loading ? 'flex items-center' : '',
    props.loading ? 'opacity-70 relative' : '',
    props.round ? 'rounded-full' : '',
    props.disabled ? 'disabled' : '',
  ].flat()
})
</script>
