<template>
  <div class="w-7/12 min-w-[80px] max-w-[220px]">
    <WebccLink v-if="href" :target="target" :anchor="anchor" :to="href">
      <NuxtImg class="h-14 w-full" :src="`logos/${logoName}.svg`" preload />
    </WebccLink>
    <svg v-else class="h-14 w-full">
      <use :href="`#i-logo`" />
    </svg>
    <span class="relative hidden sm:block text-xs md:text-sm" :class="captionClasses">{{ caption }}</span>
  </div>
</template>

<script setup lang="ts">
interface Props {
  company: Company
  href?: string
  target?: string
  anchor?: boolean
  caption?: string
}

const props = withDefaults(defineProps<Props>(), {
  href: '/',
  target: '_blank',
  anchor: true,
  caption: '',
})

const logoName = computed(() => getCompanySlug(props.company))

const captionClasses = computed(() => {
  return {
    ic: '-top-3 left-[49px] text-thm invisible',
    ih: '-top-2 left-[53px] text-txt-weak',
  }[props.company]
})
</script>
