<template>
  <div class="flex flex-col align-middle p-3 w-full text-txt md:flex-row">
    <template v-for="({ type, title, isActive }, index) in items" :key="index">
      <div v-if="type === 'dot'" class="relative inline-flex items-center content-start justify-start flex-none h-10 md:flex-col md:h-auto">
        <span class="md:h-11"></span>
        <WebccIcon name="site/stepper-point" filled class="justify-center w-5 h-5 p-0.5" :class="isActive ? 'text-thm' : 'text-txt-weaker'" />
        <span class="ml-2 text-sm font-semibold md:ml-0 md:pt-2 md:max-w-20 md:text-center">{{ title }}</span>
      </div>
      <div v-else class="flex-row-reverse md:flex-col inline-flex md:w-full md:-mx-10 self-start items-center">
        <span
          class="ml-2 text-sm text-left md:h-[3.25rem] md:pb-3 md:text-center md:content-end"
          :class="isActive ? 'font-medium md:font-semibold' : 'font-normal'"
          >{{ title }}</span
        >
        <span class="mx-2 -my-4 w-0.5 h-14 md:mx-0 md:my-0 md:w-full md:h-1" :class="isActive ? 'bg-thm' : 'bg-bgr-100'" />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.details.CancelConditions.'

const { t, locale } = useI18n()

const props = defineProps<{
  conditions: CancelCondition[]
  checkin: Date
}>()

const items = computed(() => {
  const today = new Date()

  return props.conditions
    .toSorted((a, b) => b.daysBefore - a.daysBefore)
    .map(({ from, to, percent }) => {
      const start = daysSub(props.checkin, from ?? 999)
      const end = daysSub(props.checkin, to ?? 0)
      const refund = 100 - percent
      const isActive = dateBetween(today, start, end) || datesEqual(today, start) || datesEqual(today, end)
      return { start: from ? start : undefined, end, isActive, refund }
    })
    .map(({ start, end, isActive, refund }, index, steps) => [
      {
        type: 'dot',
        title: start ? `${t(`${trPrefix}from`)} ${formatDate(start)}` : `${t(`${trPrefix}until`)} ${formatDate(end)}`,
        isActive: isActive || steps[index - 1]?.isActive,
      },
      {
        type: 'connector',
        title: refund ? `${refund}% ${t(`${trPrefix}refund`)}` : t(`${trPrefix}noRefund`),
        isActive,
      },
    ])
    .concat([{ type: 'dot', title: `${formatDate(props.checkin)}`, isActive: false }])
    .flat()
})

function formatDate(date: Date): string {
  return date.toLocaleDateString(locale, { day: '2-digit', month: 'short', year: 'numeric' })
}
</script>
