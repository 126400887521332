import { withQuery } from 'ufo'

export default function useURLs() {
  const params = useParams()
  const confData = useConfdata()

  function buildUrl(path: string, query: Params, options?: { languagePrefix?: boolean }): URL {
    const url = useRequestURL()
    url.pathname = buildPath(options?.languagePrefix ? confData.languagePrefix : '', path)
    url.search = new URLSearchParams(asQueryParams(unpack(query))).toString()

    return url
  }

  function buildSearchUrl(filters: Params, geo?: { country?: DestFacet; region?: DestFacet; place?: DestFacet }): URL {
    const { searchfilterconfs } = confData.additionalBody as SearchPageBody
    const { prefixSearchpage } = confData
    const maxPathLength = filters.place || filters.region ? 3 : 2

    const geoConfs = [
      { filter: 'country', slug: { value: filters.country, path: geo?.country?.slug || filters.country } },
      { filter: 'region', slug: { value: filters.region, path: geo?.region?.slug || filters.region } },
      { filter: 'place', slug: { value: filters.place, path: geo?.place?.slug || filters.place } },
    ]

    const slugs = new Map(
      [...geoConfs, ...(searchfilterconfs ?? [])]
        .filter(({ slug, filter }) => filters[filter] && slug.value && slug.path)
        .map(({ slug, filter }) => {
          if (['sea', 'lake', 'ski', 'center'].includes(filter)) {
            return slug.value === filters[filter] ? [filter, slug.path] : undefined
          }
          if (filter === 'pax') {
            return slug.value && parseInt(slug.value) === tryParseInt(filters[filter]?.toString()) ? [filter, slug.path] : undefined
          }
          return [filter, slug.path]
        })
        .filter(Boolean)
        .slice(0, maxPathLength) as [string, string][],
    )

    const path = slugs.size ? buildPath(...slugs.values()) : buildPath(prefixSearchpage)
    const query = {
      ...Object.fromEntries(Object.entries(filters).filter(([key]) => !slugs.has(key))),
      partnerid: usePartner().id,
      ...params.persistent,
    }

    return buildUrl(path, query, { languagePrefix: true })
  }

  function buildAccommodationUrl(accom: { slug: string; code: string; trip?: Trip }): URL {
    const { filters } = useSearch()
    const { checkin, checkout } = filters.checkin && filters.checkout ? (accom.trip ?? filters) : ({} as Trip)
    const searchParams = { checkin, checkout, partnerid: usePartner().id, ...params.persistent }

    return buildUrl([accom.slug || accom.code, ''].join('/'), searchParams, { languagePrefix: true })
  }

  function buildBookingUrl(accom: { code: string }, { checkin, checkout, pax }: TripParams, options?: BookingOptions): URL {
    return buildUrl('booking', {
      accommodation: accom.code,
      checkin: toDate(checkin),
      checkout: toDate(checkout),
      pax: pax.toString(),
      language: confData.languagePrefix ? confData.language : undefined,
      isOption: options?.isOption ? 'true' : undefined,
      partnerid: usePartner().id,
      ...params.persistent,
    })
  }

  function buildImageUrl(imageId: string, transformation: string): string {
    return `${useRuntimeConfig().public.imagekitBase}/${imageId}/${transformation}`
  }

  function buildReviewsUrl(): URL {
    // TODO: slugs are not the best base for reviews-link, sometimes with leading slash, sometimes without
    // conf-api should deliver link to review-page
    const { prefixSearchpage, prefixReviewspage, languagePrefix } = confData

    const url = useRequestURL()
    url.pathname = buildPath(languagePrefix, prefixReviewspage, deleteFromPath(url.pathname, prefixSearchpage, languagePrefix))
    url.searchParams.delete('page')

    return url
  }

  function buildValidUrl(href: string): URL {
    const url = new URL(href, useRequestURL())
    url.search = new URLSearchParams({ ...asQueryParams(params.persistent), ...Object.fromEntries(url.searchParams) }).toString()
    return url
  }

  function buildRedirectUrl(href: string): URL {
    return new URL(withQuery(href, useRoute().query), useRequestURL())
  }

  function buildGuestWorldUrl(path: string, searchParams?: object): string {
    return withQuery(path, {
      language: confData.languagePrefix ? confData.language : undefined,
      ...(searchParams ?? {}),
    })
  }

  function buildLoginUrl(): string {
    return buildGuestWorldUrl('/login', {
      returnTo: addUrlParams(useRequestURL(), { signedIn: true }).toString(),
      ...params.persistent,
    })
  }

  function buildBookmarksUrl(): string {
    const { href } = confData.links?.bookmarks ?? {}

    return href ? withQuery(href, { partnerid: usePartner().id || undefined }) : ''
  }

  function buildProfileUrl(): string {
    return buildGuestWorldUrl('/myaccount/profile', useRoute().query)
  }

  function buildBookingsUrl(): string {
    return buildGuestWorldUrl('/myaccount/bookings', useRoute().query)
  }

  return {
    buildUrl,
    buildSearchUrl,
    buildImageUrl,
    buildAccommodationUrl,
    buildBookingUrl,
    buildReviewsUrl,
    buildValidUrl,
    buildRedirectUrl,
    buildLoginUrl,
    buildProfileUrl,
    buildBookingsUrl,
    buildBookmarksUrl,
  }
}
