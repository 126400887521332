<template>
  <select
    v-model="model"
    class="w-full rounded border-0 bg-bgr px-4 py-1.5 text-sm text-txt shadow focus:bg-bgr-100 focus:text-txt-stronger focus:outline-none focus:ring-2 focus:ring-thm active:bg-bgr-50"
    :placeholder="filter"
  >
    <option :value="undefined">&#8212;</option>
    <option v-for="{ value, count, label } in options" :key="value" :value="value">
      {{ label }}
      {{ !hideCount && count ? (strict || parseInt(value) >= parseInt(model) || !model || isNaN(parseInt(value)) ? ` (${count})` : ` (${count}+)`) : '' }}
    </option>
  </select>
</template>

<script setup lang="ts">
type Option = { value: string; count: number; label: string }

const props = defineProps<{
  filter: string
  options: Option[]
  hideCount?: boolean
  strict?: boolean
}>()

const search = useSearch()

const model = computed({
  get() {
    return search.filters[props.filter as keyof Params] as string
  },
  set(value: string) {
    search.setFilter({ [props.filter]: value })
  },
})
</script>
