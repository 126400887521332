import { nanoid } from 'nanoid'

export const useIDsStore = defineStore('ids', () => {
  const cookies = useUserSessionCookies()

  const user = ref('')
  const session = ref('')
  const cycle = ref('')
  const client = ref<string>()

  function init() {
    user.value = cookies.user.value || nanoid()
    session.value = cookies.session.value || nanoid()
    cycle.value = nanoid()

    cookies.user.value = user.value
    cookies.session.value = session.value
  }

  return { user, session, cycle, client, init }
})

function useUserSessionCookies() {
  const options = { secure: true, sameSite: 'none' as const, path: '/', httpOnly: true }

  const user = useCookie('User-ID', { ...options, expires: monthsAdd(new Date(), 3) })
  const session = useCookie('Session-ID', options)

  return { user, session }
}
