import revive_payload_client_4sVQNw7RlN from "/myapp/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/myapp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CaKIoANnI2 from "/myapp/node_modules/nuxt/dist/app/plugins/router.js";
import params_plugin_dpdsDh23tL from "/myapp/modules/params/params.plugin.ts";
import runtime_screen_plugin_533699b7_Tw2FoQhacS from "/myapp/.nuxt/runtime.screen.plugin.533699b7.ts";
import i18n_VfGcjrvSkj from "/myapp/plugins/i18n.ts";
import stores_BlraoXqTo0 from "/myapp/plugins/stores.ts";
import payload_client_yVLowv6hDl from "/myapp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/myapp/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/myapp/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/myapp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/myapp/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/myapp/.nuxt/components.plugin.mjs";
import plugin_client_RZVNejKJBe from "/myapp/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import unleash_plugin_client_FsacaZbo7x from "/myapp/modules/unleash/runtime/unleash.plugin.client.ts";
import plugin_client_Fdvg3o8568 from "/myapp/node_modules/@nuxtjs/web-vitals/dist/runtime/plugin.client.mjs";
import floating_vue_EIcJ7xiw0h from "/myapp/.nuxt/floating-vue.mjs";
import colors_SVBUrxEU7r from "/myapp/plugins/colors.ts";
import sentry_client_shVUlIjFLk from "/myapp/plugins/sentry.client.ts";
import tracking_RJtqiU4YuV from "/myapp/plugins/tracking.ts";
import vue3_toastify_client_GgudbfYtjc from "/myapp/plugins/vue3-toastify.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_CaKIoANnI2,
  params_plugin_dpdsDh23tL,
  runtime_screen_plugin_533699b7_Tw2FoQhacS,
  i18n_VfGcjrvSkj,
  stores_BlraoXqTo0,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  plugin_client_RZVNejKJBe,
  unleash_plugin_client_FsacaZbo7x,
  plugin_client_Fdvg3o8568,
  floating_vue_EIcJ7xiw0h,
  colors_SVBUrxEU7r,
  sentry_client_shVUlIjFLk,
  tracking_RJtqiU4YuV,
  vue3_toastify_client_GgudbfYtjc
]