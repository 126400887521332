<template>
  <div
    :key="componentKey"
    :class="[{ 'border-txt-weak': onFocus }, sizeclasses, classes]"
    class="relative w-full cursor-text rounded border border-txt-weaker bg-bgr px-3 transition-all duration-200"
    @click="input?.focus()"
  >
    <input
      v-bind="$attrs"
      :id="id"
      ref="input"
      :placeholder="placeholder"
      :name="name"
      :type="type"
      :value="modelValue"
      class="h-full w-full border-none p-0 text-base leading-5 outline-none ring-0 focus:outline-none focus:ring-0"
      :class="{ valid: valid === true, invalid: valid === false, 'mt-2': size !== 'sm', 'pl-6': size === 'sm' && icon && !label }"
      @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)"
      @change="$emit('update:modelValue', ($event.target as HTMLInputElement).value)"
      @focus="delayedResize(true)"
      @blur="delayedResize(false)"
    />

    <WebccIcon
      v-if="!label && icon && size === 'sm'"
      :name="icon"
      class="pointer-events-none absolute left-2 top-0 h-full w-4 text-txt opacity-60"
      aria-hidden="true"
    />

    <label
      v-if="label"
      class="absolute left-0 top-1/2 w-full -translate-y-1/2 cursor-text overflow-hidden text-ellipsis whitespace-nowrap px-3 text-left text-sm font-medium text-txt-weak transition-all duration-300"
      :for="id"
      :class="{ 'top-2 translate-y-0 text-xs': onFocus || hasValue || placeholder }"
    >
      <WebccIcon v-if="icon" :name="icon" class="pointer-events-none absolute h-full w-4 text-txt opacity-60" aria-hidden="true" />
      <span
        ref="labelText"
        v-tooltip="{ content: $t(label as TranslationKey), triggers: [], shown: showTooltip && labelHover }"
        :class="{ 'pl-6': icon }"
        @mouseover="labelHover = true"
        @mouseleave="labelHover = false"
      >
        <span>{{ $t(label as TranslationKey) }}</span>
        <sup v-if="required">*</sup>
      </span>
    </label>
    <transition name="slide">
      <WebccIcon v-if="valid === true" name="site/tick" class="absolute right-5 top-1/2 h-4 w-4 -translate-y-1/2 text-suc" :filled="false" />
    </transition>
    <transition name="slide">
      <WebccIcon v-if="valid === false" name="site/cross" class="absolute right-5 top-1/2 h-4 w-4 -translate-y-1/2 text-err" :filled="false" />
    </transition>
  </div>
</template>

<script setup lang="ts">
type Type = 'text' | 'password' | 'date' | 'email' | 'number' | 'url' | 'tel' | 'search' | 'color'

interface Props {
  required?: boolean | string
  name: string
  label?: string
  placeholder?: string
  icon?: string
  modelValue?: string | number
  size?: 'md' | 'sm'
  type?: Type
  valid?: boolean | undefined
  classes?: string
}

const props = withDefaults(defineProps<Props>(), {
  required: false,
  label: '',
  placeholder: '',
  icon: '',
  size: 'md',
  type: 'text',
  valid: undefined,
  modelValue: '',
  classes: '',
})

defineEmits<{ (e: 'update:modelValue', value: string): void }>()

const componentKey = ref(props.name)
const onFocus = ref(false)
const id = useId()
const labelWidth = ref('')
const inputWidth = ref('')
const labelHover = ref(false)

const input: Ref<HTMLInputElement | null> = ref(null)
const labelText: Ref<HTMLSpanElement | null> = ref(null)

const hasValue = computed(() => {
  return !!props.modelValue
})

const sizeclasses = computed(() => {
  switch (props.size) {
    case 'md':
      return props.label === '' ? 'h-11 text-base py-4' : 'h-14 text-base py-4'
    case 'sm':
      return 'h-10'
    default:
      return ''
  }
})

const showTooltip = computed(() => {
  return labelWidth.value > inputWidth.value
})

onMounted(() => {
  handleResize()
  window.addEventListener('resize', handleResize)
  componentKey.value += props.modelValue.toString() // force rerender to compute label classes, etc.
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})

function handleResize() {
  if (labelText.value && input) {
    labelWidth.value = labelText.value!.offsetWidth.toString()
    inputWidth.value = input.value!.offsetWidth.toString()
  }
}

function delayedResize(focus: boolean) {
  onFocus.value = focus
  setTimeout(handleResize, 310)
}
</script>

<style scoped>
.slide-enter-active {
  animation: slide 0.3s ease-out;
}
.slide-leave-active {
  animation: slide 0.3s ease-out reverse;
}
@keyframes slide {
  0% {
    opacity: 0;
    transform: translate(50%, -50%) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: translate(0, -50%) scale(1);
  }
}
</style>
