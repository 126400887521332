<template>
  <section class="container-fluid-lg pt-4 md:pt-6">
    <span v-if="title" class="m-0 text-3xl font-normal text-txt md:text-4xl">{{ title }}</span>
    <span v-else class="m-0 text-3xl font-normal text-txt md:text-4xl">{{ $t(`${trPrefix}heading`) }}</span>
    <WebccHeading v-if="subTitles.length" :level="1" color="txt" size="xl" space="mt-3" class="font-normal">
      <div class="flex flex-col items-start justify-center">
        {{ subTitles[0] }}
        <br />
        <span v-if="subTitles.length > 1" class="text-lg uppercase">{{ subTitles[1] }}</span>
        <br />
        <span v-if="subTitles.length > 2" class="text-base">{{ subTitles[2] }}</span>
      </div>
    </WebccHeading>
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.search.area.'

const params = useParams()
const search = useSearch()
const confData = useConfdata()

const destinationName = computed(() => getDestinationName(confData.destination))
const filterName = computed(() => confData.destination.filters?.[0].name)
const heading = computed(() => params.all.heading)
const name = computed(() => params.all.name)
const h1 = computed(() => (confData.additionalBody as SearchPageBody).h1.map((line) => replacePlaceholders(line, { amount: search.total })))
const title = computed(() => name.value || destinationName.value || filterName.value)
const subTitles = computed(() => (heading.value ? [heading.value] : h1.value))
</script>
