<template>
  <div
    v-if="objects?.totalHits && objects.totalHits >= 3"
    v-impression:detail:linkedObjectsTeaserViewed.100
    class="flex flex-col gap-4 rounded-lg border border-bgr-200 bg-bgr p-6 shadow"
  >
    <h2 class="text-2xl font-medium">{{ $t('www.components.views.details.LinkedObjects.title') }}</h2>
    <div class="flex flex-col gap-2 sm:grid sm:grid-cols-2 md:grid-cols-3 md:gap-10">
      <HitboxCard
        v-for="(accom, index) in objects.docs.slice(0, 3)"
        :key="index"
        :breadcrumb-links="false"
        class="w-full"
        :language-prefix="languagePrefix"
        :accom="accom"
        :locale="locale"
        :domain="domain"
        :currency="currency"
        :transformations="transformations"
        @to-detailpage="onPropertyClick()"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const transformations = { xl: 'tr:n-srp_hitbox_l', lg: 'tr:n-srp_hitbox_l', md: 'tr:n-srp_hitbox_l' }
const { locale, languagePrefix, currency, domain } = storeToRefs(useConfdata())
defineProps<{ objects: Result }>()

function onPropertyClick() {
  useTracking().handlers?.detail.linkedObjectsTeaserClicked()
}
</script>
