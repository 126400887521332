<template>
  <!-- Used to access the bookmarks page in iframe -->
  <client-only v-if="show">
    <div class="flex items-baseline justify-end gap-2">
      <WebccLink
        class="focus:text-txt-900 relative flex items-center rounded border-bgr-300 bg-bgr-200 px-3 py-1.5 text-sm font-light text-txt hover:bg-bgr-100 focus:border-bgr-700 focus:bg-bgr-100 focus:outline-none disabled:cursor-not-allowed disabled:shadow-none md:px-4 md:text-base"
        :to="conf.links?.bookmarks.href"
      >
        <WebccIcon class="-ml-1.5 mr-2 h-5 w-5" name="site/bookmark" filled />
        <span class="block">{{ $t(`${trPrefix}favorites`) }}</span>
        <div v-show="bookmarks.count > 0" class="absolute -right-2 -top-2 flex h-4 w-4 items-center justify-center rounded-full bg-thm text-xs text-bgr">
          {{ bookmarks.count }}
        </div>
      </WebccLink>
    </div>
  </client-only>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.site.BookmarkLink.'

const bookmarks = useBookmarks()
const conf = useConfdata()

const show = computed(() => {
  const { iframe } = useParams()
  return useStorageReady() && iframe?.layout && !['header', 'full'].includes(iframe.layout.toString())
})
</script>
