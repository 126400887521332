<template>
  <header>
    <!-- Logo and contact info -->
    <div class="container-fluid-lg flex items-center justify-center pt-4 text-sm font-light text-txt-strong md:justify-between">
      <WebccCompanyLogo
        target="_self"
        :anchor="false"
        :caption="logoCaption"
        :company="company"
        class="hidden md:block"
        :href="logoHref"
        @click="trackingLogo()"
      />
      <aside v-if="siteSettings?.includes('contactinfo')" class="pointer-events-none flex items-center">
        <span class="pointer-events-auto">{{ $t(`${trPrefix}hereForYou`) }}</span>
        <WebccLink
          v-if="showContactUs && links?.contact?.href"
          :to="links.contact.href"
          class="pointer-events-auto ml-3 underline underline-offset-1"
          @click="trackingContactUs()"
        >
          <span>{{ $t(`${trPrefix}contactUs`) }}</span>
        </WebccLink>
      </aside>
    </div>

    <!-- Navigation, Bookmarks, Lang and Currency switcher -->
    <div class="container-fluid-lg flex items-center py-2 pb-4" :class="showMenu ? 'justify-between' : 'justify-end'">
      <WebccCompanyLogo
        target="_self"
        :anchor="false"
        :caption="logoCaption"
        :company="company"
        class="mr-auto md:hidden"
        :href="logoHref"
        @click="tracking.handlers?.header.headerItemClick('homepage')"
      />
      <Navigation v-if="showMenu && header?.menu" :menu="header?.menu" :links="links" />
      <div class="flex items-center md:items-end">
        <!-- Bookmarks -->
        <client-only>
          <WebccLink
            v-if="useStorageReady()"
            class="relative ml-4 flex rounded border border-bgr-300 p-2 text-sm text-txt-strongest duration-200 hover:bg-bgr-100 focus:outline-none md:ml-3"
            :to="bookmarksURL"
            @click="trackingWishlist()"
          >
            <WebccIcon class="mr-0 h-5 w-5 text-bgr-600 md:mr-1" name="site/bookmark" filled />
            <span class="hidden md:block">{{ $t(`${trPrefix}labels.favorites`) }}</span>
            <div v-show="bookmarks.count > 0" class="absolute -right-2 -top-2 flex h-5 w-5 items-center justify-center rounded-full bg-thm text-xs text-bgr">
              {{ bookmarks.count }}
            </div>
          </WebccLink>
        </client-only>
        <!-- Language Switcher -->
        <!-- <select
          v-if="sites && sites.length > 1"
          class="ml-2 flex w-fit items-center rounded border border-bgr-300 p-2 pr-8 text-sm font-semibold text-txt-strongest duration-200 hover:cursor-pointer hover:bg-bgr-100 focus:outline-none md:ml-3"
          @input="changeLanguage(($event.target as HTMLSelectElement).value)"
        >
          <option v-for="site in sites" :key="site.language" :selected="site.language === language" :value="site.language">
            {{ site.language.toUpperCase() }}
          </option>
        </select> -->
        <HeaderSelect
          v-if="sites?.length && sites?.length > 1"
          :selected-value="language!"
          :all-options="sites!"
          :has-key="true"
          object-key="language"
          :to-upper-case="true"
          @on-change="changeLanguage"
        />
        <!-- Currency Switcher -->
        <!-- <select
          v-if="currencies && currencies.length > 1"
          class="ml-2 flex w-fit items-center rounded border border-bgr-300 p-2 pr-8 text-sm font-semibold text-txt-strongest duration-200 hover:cursor-pointer hover:bg-bgr-100 focus:outline-none md:ml-3"
          @input="changeCurrency(($event.target as HTMLSelectElement).value)"
        >
          <option v-for="currency in currencies" :key="currency" :selected="currency === confCurrency" :value="currency">
            {{ currency.toUpperCase() }}
          </option>
        </select> -->
        <HeaderSelect
          v-if="currencies && currencies.length > 1"
          :selected-value="confCurrency!"
          :all-options="currencies!"
          :has-key="false"
          :to-upper-case="true"
          @on-change="changeCurrency"
        />

        <template v-if="useAB().isActive('guestWorld')">
          <HeaderUserButton v-if="auth.isAuthorized" class="ml-4" />
          <WebccButton
            v-else-if="auth.pending"
            class="invisible relative ml-3 py-2 !px-3 border border-bgr-300 text-txt-strongest duration-200 hover:bg-bgr-100 focus:outline-none md:ml-4"
          >
            <span class="text-nowrap text-sm font-medium">{{ $t(`${trPrefix}user.login`) }}</span>
          </WebccButton>
          <WebccButton
            v-else-if="!auth.pending"
            variant="white"
            round
            class="relative ml-3 py-2 !px-3 border border-bgr-300 text-txt-strongest duration-200 hover:bg-bgr-100 focus:outline-none md:ml-4"
            @click="signIn"
          >
            <span class="text-nowrap text-sm font-medium">{{ $t(`${trPrefix}user.login`) }}</span>
          </WebccButton>
        </template>

        <!-- Mobile Menu Button -->
        <button class="ml-1 rounded p-1 hover:bg-bgr-100 focus:outline-none active:bg-bgr-100 sm:ml-2 sm:p-2 md:hidden" @click.stop="toggling.toggle('menu')">
          <WebccIcon class="h-7 w-7 text-txt-weak" name="site/menu" />
        </button>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.site.Header.'

withDefaults(
  defineProps<{
    showMenu?: boolean
    showContactUs?: boolean
  }>(),
  {
    showMenu: true,
    showContactUs: true,
  },
)

const logoHref = ref('/')
const { company, header, links, currencies, language, siteSettings, sites, logoCaption, currency: confCurrency } = storeToRefs(useConfdata())
const tracking = useTracking()
const toggling = useToggler()
const bookmarks = useBookmarks()
const auth = useAuth()

const bookmarksURL = computed(() => useURLs().buildBookmarksUrl())

function changeLanguage(lang: string) {
  const localeUrl = useConfdata().getLocalizedUrl(lang)
  if (!localeUrl) return

  tracking.handlers?.header.languageSwitch(lang)
  tracking.handlers?.homepage.headerSelectLanguage(lang)
  window.location.href = useURLs().buildRedirectUrl(localeUrl).toString()
}

function changeCurrency(currency: string) {
  console.log('change currency: ', currency)

  tracking.handlers?.header.currencySwitch(currency)
  const url = new URL(window.location.href)
  url.searchParams.set('currency', currency)
  window.location.href = url.href
}

function signIn() {
  tracking.handlers?.header.enterGuestWorld()
  nextTick(() => {
    navigateTo(useURLs().buildLoginUrl().toString(), { external: true })
  })
}

function trackingLogo() {
  tracking.handlers?.header.headerItemClick('homepage')
  tracking.handlers?.homepage.headerLogoClick()
}

function trackingContactUs() {
  tracking.handlers?.header.headerItemClick('contactus')
  tracking.handlers?.homepage.headerContactUsClick()
}

function trackingWishlist() {
  tracking.handlers?.header.headerItemClick('favourites')
  tracking.handlers?.homepage.headerWishlistClick()
}
</script>
