<template>
  <div
    class="group flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-[#bbb] p-3 shadow-sm hover:shadow-lg md:h-12 md:w-12 hover:bg-thm"
    @click="handleClick"
  >
    <WebccIcon :filled="true" :name="'site/' + target" class="h-full w-full rounded-full text-bgr-300" />
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{ target: string; link: string }>()
const emit = defineEmits<{ (e: 'clicked'): void }>()

function handleClick() {
  if (props.link) {
    useTracking().handlers?.homepage.footerSocialMediaLinkElementClick(props.target, useRoute().path)
    window.open(props.link, '_blank')
  } else {
    emit('clicked')
  }
}
</script>
