<!--
# Headings

Level: numerisch: 1-7 bestimmt das HTML-Tag, das für die Überschrift verwendet wird
wenn, dann wird level default 4

Size: kann numerisch oder über Tailwind-Größe gesetzt werden:
1 / 3xl 1.875rem z.B. Startseite
2 / 2xl 1.5rem   z.B. Why2 oder USPCorona
3 / xl  1.25rem  z.B. Überschriften im Footer
4 / lg  1.125rem z.B.
5 / md (base) 1rem
6 / sm  0.875rem
7 / xs  0.75rem

Wenn size undefined -> size wird aus level (1-7) übernommen
-->

<template>
  <component :is="component" :id="id" :class="classes">
    <WebccIcon v-if="icon" :name="icon" class="mr-1 h-6 w-6 fill-current" filled />
    <slot>{{ title }}</slot>
  </component>
</template>

<script setup lang="ts">
export type Size = '3xl' | '2xl' | 'xl' | 'lg' | 'base' | 'sm' | 'xs' | ''

interface Props {
  bold?: boolean
  title?: string
  level?: number
  size?: Size
  tag?: string
  space?: string
  color?: string
  icon?: string
  id?: string
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  level: 4,
  size: '',
  tag: '',
  space: '',
  color: 'thm',
  icon: '',
  id: '',
})

const component = computed(() => {
  if (!props.tag) {
    return `h${props.level}`
  }
  const resolvedComponent = resolveComponent(props.tag)
  return typeof resolvedComponent === 'string' ? `h${props.level}` : resolvedComponent
})

const sizing = computed(() => {
  const { size } = props
  if (!size) {
    return ['3xl', '2xl', 'xl', 'lg', 'base', 'sm', 'xs', ''][props.level - 1]
  }
  return size
})

const spacing = computed(() => {
  if (props.space) {
    return props.space
  }
  switch (sizing.value) {
    case '3xl':
      return 'mt-8 mb-4 md:mt-16 md:mb-8'
    case '2xl':
      return 'mt-6 mb-3 md:mt-12 md:mb-6'
    case 'xl':
      return 'mt-4 mb-2 md:mt-8 md:mb-4'
    case 'lg':
      return 'mt-2 mb-1 md:mt-4 md:mb-2'
    case 'base':
      return 'mt-1 mb-0.5 md:mt-2 md:mb-1'
    case 'sm':
      return 'mt-0.5 mb-0 md:mt-1 md:mb-0.5'
    case 'xs':
      return ''
  }
  return ''
})

const fonts = computed(() => {
  switch (sizing.value) {
    case '3xl':
      return 'text-3xl'
    case '2xl':
      return 'text-2xl'
    case 'xl':
      return 'text-xl '
    case 'lg':
      return 'text-lg '
    case 'base':
      return 'text-base '
    case 'sm':
      return 'text-sm'
    case 'xs':
      return 'text-xs'
    default:
      return ''
  }
})

const classes = computed(() => {
  let color = 'text-thm'
  switch (props.color) {
    case 'cta':
      color = 'text-cta'
      break
    case 'hlt':
      color = 'text-hlt'
      break
    case 'txt':
      color = 'text-txt'
      break
    case 'white':
      color = 'text-txt-contrast'
      break
  }
  let classes = `flex flex-row items-center ${fonts.value} ${color} ${spacing.value}`

  if (props.bold) {
    classes += ' font-semibold'
  }
  return classes
})
</script>
