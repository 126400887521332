export const formatNumber = (number: number, format: keyof typeof formats = 'default', options: Intl.NumberFormatOptions = {}) => {
  if (typeof number !== 'number') {
    number = parseFloat(number)
  }
  if (isNaN(number)) {
    return ''
  }

  if (typeof format === 'object') {
    // no named format, options is 2nd param, 3rd param discarded
    options = { ...format }
  }

  options = { ...(formats[format] || formats.default), ...options }

  return new Intl.NumberFormat(useL10N().locale, options).format(number)
}

const formats = {
  default: {
    style: 'decimal',
  },
  '1-digit': {
    style: 'decimal',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  },
  '2-digit': {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
}
