<template>
  <section class="mt-2 py-1">
    <!-- <span class="mb-1 mt-2 block h-[2px] w-[10%] bg-thm"></span> -->
    <p class="mb-1 mt-2 text-sm font-medium" @click="toggle">{{ title }}</p>

    <!-- <WebccButton class="w-full" @click="attributesOpen = !attributesOpen">Ausstattung</WebccButton> -->

    <!-- <h5 class="px-1 pt-2 pb-1 text-xs font-semibold text-txt">{{ title }}</h5> -->
    <!-- <div v-if="open === true"> -->
    <!-- <div v-if="open !== false"> -->
    <section v-if="isOpen !== false" :class="frame ? 'w-full rounded bg-bgr px-1 py-1.5 shadow' : ''">
      <slot />
    </section>
    <!-- </div> -->
    <!-- <section class="mt-2 p-1"> -->
    <!-- <WebccButton class="w-full" @click="distancesOpen = !distancesOpen">Distanzen</WebccButton> -->
    <!-- </section> -->
  </section>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    title: string
    open?: boolean
    frame?: boolean
    // places: any[]
  }>(),
  {
    open: undefined,
    frame: false,
  },
)

let isOpen = props.open

function toggle() {
  if (props.open === undefined) return
  isOpen = !isOpen
}
</script>
