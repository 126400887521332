export default { popupDisplayed, popupReadMore }

function popupDisplayed() {
  trackPopup('displayed', 'Notification is displayed')
}

function popupReadMore() {
  trackPopup('read more', 'Read more')
}

function trackPopup(action: string, label: string) {
  useTracking().send({
    event: 'interchalet_migration_notification',
    action,
    label,
    category: undefined,
    component: 'notification',
    position: undefined,
    language: useL10N().language,
    _clear: true,
  })
}
