// This composable is used to toggle a boolean value to handle the state of a component or variable, etc.
export const useTogglerStore = defineStore('toggler', () => {
  const toggler: Ref<ToggleState[]> = ref([])

  // Get the state of a toggle.
  function toggles(name: string) {
    return computed(() => {
      return toggler.value.find((toggle) => toggle.name === name)?.state
    })
  }

  // Register a new toggle.
  function register(name: string, options?: { state?: boolean; ignoreDocumentClick?: boolean }) {
    toggler.value.push({ name, state: options?.state ?? false, ignoreDocumentClick: options?.ignoreDocumentClick ?? false })
  }

  // Toggle the state of a toggle and disable all other toggles.
  function toggle(name: string, options?: ToggleOptions) {
    toggler.value.forEach((toggleElement) => {
      if (toggleElement.name === name) {
        toggleElement.state = options?.state ?? !toggleElement.state
      } else if (options && options.closeOthers !== false) {
        toggleElement.state = false
      }
    })
  }

  if (import.meta.client) {
    document.addEventListener('click', () => {
      toggler.value
        .filter((t) => !t.ignoreDocumentClick)
        .forEach((t) => {
          t.state = false
        })
    })
  }

  return {
    register,
    toggle,
    toggles,
    toggler,
  }
})
