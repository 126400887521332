<template>
  <div
    role="radiogroup"
    :name="name"
    class="flex content-center"
    :class="border ? 'flex-col gap-2 sm:flex-row sm:flex-wrap' : 'flex-row flex-wrap gap-x-4 gap-y-4 sm:gap-x-8'"
    @click.stop
  >
    <label
      v-for="option in options"
      :key="option.value"
      class="inline-flex cursor-pointer items-center justify-center gap-2 transition-all duration-200"
      :class="border ? `${sizeClasses} border-txt-weaker mb-1 rounded border sm:mb-0` : 'h-5'"
    >
      <input
        :value="option.value"
        type="radio"
        :name="name"
        :checked="option.value === value ? true : false"
        class="border-txt-weak text-thm focus:ring-thm border transition-all duration-200"
        @click.stop
        @change.stop="$emit('change', ($event.target as HTMLInputElement).value)"
      />
      <span class="text-sm leading-4"> {{ option.label }}</span>
    </label>
  </div>
</template>

<script setup lang="ts">
type OptionValue = string | number
type Option = { label: string; value: OptionValue }
const props = withDefaults(
  defineProps<{
    value: boolean | string | number
    options: Option[]
    label?: string
    name?: string
    size?: string
    border?: boolean
  }>(),
  {
    label: '',
    name: '',
    size: 'md',
    border: true,
  },
)

defineEmits<{ (e: 'change', value: boolean | string | number): void }>()

const sizeClasses = computed(() => {
  switch (props.size) {
    case 'md':
      return 'pl-4 pr-8 h-14'
    case 'sm':
      return 'pl-4 pr-4 h-10'
    default:
      return 'pl-4 pr-8 h-14'
  }
})
</script>
