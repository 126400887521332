<template>
  <span
    v-if="props.type === 'circle'"
    class="circle rounded-full ease-linear"
    :class="{
      'border-white border-t-cta': props.cta,
      'border-white border-t-thm': !props.cta,
      'h-32 w-32 border-8': props.size === 'md',
      'h-6 w-6 border-4': props.size === 'sm',
    }"
  />
  <div v-else-if="props.type === 'blocks'" class="bg-bgr-100 opacity-100">
    <div class="flex h-full flex-col items-center justify-center">
      <div class="blocks relative inline-block h-16 w-16 origin-bottom-left justify-center overflow-hidden border border-thm">
        <div class="absolute left-0 top-8 h-1/2 w-1/2 bg-thm" />
        <div class="block-2 absolute left-8 top-8 h-1/2 w-1/2 bg-thm" />
        <div class="block-3 absolute left-0 top-0 h-1/2 w-1/2 bg-thm" />
        <div class="block-4 absolute left-8 top-0 h-1/2 w-1/2 bg-thm" />
      </div>
      <div v-if="props.message" class="mt-4 border-y border-thm bg-bgr-100 p-4 text-base text-thm">
        {{ props.message }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  cta?: boolean
  type?: string
  message?: string
  size?: string
}

const props = withDefaults(defineProps<Props>(), {
  cta: false,
  type: 'circle',
  message: '',
  size: 'md',
})
</script>

<style scoped>
.circle {
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

.blocks {
  animation: shrink-rectangles 4s linear infinite;
}
.block-2 {
  animation: rectangle-2 2s linear infinite;
}
.block-3 {
  animation: rectangle-3 2s linear infinite;
}
.block-4 {
  animation: rectangle-4 2s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes shrink-rectangles {
  0%,
  90% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}
@keyframes rectangle-2 {
  0% {
    transform: translateY(-50px);
  }
  25%,
  100% {
    transform: translate(0);
  }
}
@keyframes rectangle-3 {
  0% {
    transform: translateY(-50px);
  }
  50%,
  100% {
    transform: translate(0);
  }
}
@keyframes rectangle-4 {
  0% {
    transform: translateY(-50px);
  }
  75%,
  100% {
    transform: translate(0);
  }
}
</style>
